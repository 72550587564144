import store from 'store'

async function getMenuData(country_code) {
  return [
    {
      title: 'Sales',
      key: 'invoice',
      icon: 'fa-solid fa-money-bill-wave',
      children: [
        {
          title: 'Invoices',
          key: 'sales',
          url: '/list/sales',
        },
        {
          title: 'Credit Notes',
          key: 'sales_returns',
          url: '/list/sales_returns',
        },
        {
          title: 'E-Invoices',
          key: 'einvoices',
          url: '/einvoices',
          show: country_code == 'IN',
        },
        {
          title: 'Subscriptions',
          key: 'subscriptions',
          url: '/list/subscriptions',
        },
      ],
      className: 't-3',
    },
    {
      title: 'Purchases',
      key: 'purchases',
      icon: 'fa-solid fa-cart-shopping',
      children: [
        {
          title: 'Purchase Invoices',
          key: 'purchases',
          url: '/list/purchases',
        },
        {
          title: 'Purchase Orders',
          key: 'purchase_orders',
          url: '/list/purchase_orders',
        },
        {
          title: 'Debit Note',
          key: 'purchase_returns',
          url: '/list/purchase_returns',
        },
      ],
      className: 't-4',
    },
    {
      title: 'Quotations',
      key: 'estimates',
      icon: 'fa-regular fa-pencil',
      children: [
        {
          title: 'Quotations',
          key: 'estimates',
          url: '/list/estimates',
        },
        {
          title: 'Pro Forma Invoices',
          key: 'pro_forma_invoices',
          url: '/list/pro_forma_invoices',
        },
        {
          title: 'Delivery Challans',
          key: 'delivery_challans',
          url: '/list/delivery_challans',
          show: country_code == 'IN',
        },
      ],
      className: 't-5',
    },
    {
      title: 'Expenses+',
      key: 'expenses_menu',
      icon: 'fa-solid fa-tags',
      children: [
        {
          title: 'Expenses',
          key: 'expenses',
          url: '/expenses',
        },
        {
          title: 'Indirect Income',
          key: 'indirect_income',
          url: '/indirect_income',
        },
      ],
      className: 't-5',
    },
    {
      category: true,
      title: '',
    },
    {
      title: 'Products & Services',
      key: 'products',
      icon: 'fa-solid fa-barcode-read',
      url: '/products',
      className: 't-8',
    },
    // {
    //   title: 'Inventory',
    //   key: 'inventory',
    //   icon: 'fa-solid fa-shelves',
    //   url: '/inventory',
    //   className: 't-7',
    // },
    {
      title: 'Inventory',
      key: 'inventory',
      icon: 'fa-solid fa-shelves',
      children: [
        {
          title: 'Warehouses',
          key: 'warehouses',
          url: '/inventory',
        },
        {
          title: 'Timeline',
          key: 'inventory_timeline',
          url: '/inventoryTimeline',
        },
      ],
      className: 't-7',
    },

    {
      category: true,
      title: '',
    },
    {
      title: 'Payments',
      key: 'payments',
      icon: 'fa-solid fa-indian-rupee-sign',
      children: [
        {
          title: 'Timeline',
          key: 'payments_timeline',
          url: '/paymentsTimeline',
        },
        {
          title: 'Settlements',
          key: 'settlements',
          url: '/settlements',
          show: country_code == 'IN',
        },
        {
          title: 'Payment Links',
          key: 'payment_links',
          url: '/paymentLinks',
          show: country_code == 'IN',
        },
        {
          title: 'Journals',
          key: 'journals',
          url: '/journals',
        },
        {
          title: 'Bank Reconciliations',
          key: 'bank_reconciliation',
          url: '/bankRecon',
        },
        // {
        //   title: 'Ledgers',
        //   key: 'ledgers',
        //   url: '/payments',
        // },
      ],
      className: 't-6',
    },

    {
      title: 'Customers',
      key: 'customers',
      icon: 'fa-solid fa-user',
      url: '/customer',
      className: 't-9',
    },
    {
      title: 'Vendors',
      key: 'vendors',
      icon: 'fa-solid fa-user-group',
      url: '/vendor',
      className: 't-10',
    },
    {
      category: true,
      title: '',
    },
    {
      title: 'Insights',
      key: 'analytics',
      icon: 'fa-solid fa-chart-line-up',
      url: '/analytics',
      className: 't-12',
      new: true,
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'fa-solid fa-file-waveform',
      // url: '/analytics/sales',
      // url: '/reports',
      className: 't-13',
    },
    {
      category: true,
      title: '',
    },
    {
      title: 'Packing Lists',
      key: 'packing_lists',
      icon: 'fa-solid fa-boxes-packing',
      url: '/packingLists',
      new: true,
      className: 't-322',
      show: country_code == 'IN' && store.get('is_export') == 1,
    },
    {
      title: 'E-way Bills',
      key: 'ewaybills',
      icon: 'fa-solid fa-truck-bolt',
      url: '/ewaybills',
      className: 't-14',
      show: country_code == 'IN',
    },
    {
      category: true,
      title: '',
    },
    store.get('is_live')
      ? {
          title: 'Online Orders',
          key: 'onlineorders',
          icon: 'fa-solid fa-cart-shopping-fast',
          url: '/onlineorders',
          className: 't-15',
          show: country_code == 'IN',
        }
      : {
          title: 'Online Orders',
          key: 'onlineorders',
          icon: 'fa-solid fa-cart-shopping-fast',
          url: '/onlineorders',
          className: 't-16',
          show: country_code == 'IN',
        },
    {
      title: 'Campaigns',
      key: 'campaigns',
      icon: 'fa-solid fa-bullhorn',
      url: '/campaigns',
      className: 't-19',
      show: country_code == 'IN',
    },
    {
      title: 'My Drive',
      key: 'mydrive',
      icon: 'fa-solid fa-cloud',
      url: '/drive',
      className: 't-20',
    },
    // {
    //   title: 'Coupons',
    //   key: 'coupons',
    //   icon: 'fa-solid fa-badge-percent',
    //   url: '/coupons',
    // },
    {
      category: true,
      title: '',
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'fa-solid fa-gear',
      url: '/user',
      className: 't-17',
    },
    {
      title: 'Tutorials',
      key: 'tutorials',
      icon: 'fa-brands fa-youtube',
      url: '/tutorials',
      className: 't-18',
      show: country_code == 'IN',
    },
  ]
}

export default getMenuData
