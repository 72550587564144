export const CONSTANTS = {
  // PRICING_BUTTON: 'Get 78 Days Extra! 🚀',
  PRICING_BUTTON: 'Subscribe Now! 🚀',
  // GSTIN_REGEX: '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$',
  GSTIN_REGEX: '^[0-9A-Z]{15}$',
  HELP_BUTTON_TEXT: 'Hello%20Swipe! I need help with',
  // HELP_BUTTON_TEXT: 'Hello, I am interested in Swipe Freedom Offer!',
}

export const ADMIN = 'admin'

export const STOCK_IN_STOCK_OUT_DOC_TYPES = [
  'invoice',
  'sales_return',
  'purchase_return',
  'purchase',
]
export const FLOATOPTIONS = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

export const LEADS_SHEET_DAILY_TARGET = 130000
export const INVOICE = 'invoice'
export const PURCHASE = 'purchase'
export const DELIVERY_CHALLAN = 'delivery_challan'
export const PRODUCT = 'Product'
export const SERVICE = 'Service'
