import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Modal, Input, Button, Tooltip, message } from 'antd'
import { getAPI } from 'services/jwt'
import SubscriptionPlanModal from '../subscription/subscriptionPlan'
import store, { set } from 'store'

const AIModal = forwardRef((props, ref) => {
  const subscriptionPlanRef = useRef()
  const { onContinue } = props
  const [isVisible, setVisible] = useState(false)
  const [activeResponse, setActiveResponse] = useState('')
  const [key, setKey] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [historyStack, setHistoryStack] = useState([])
  const [historyIndex, setHistoryIndex] = useState(-1)
  const [requestInProgress, setRequestInProgress] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [context, setContext] = useState('')
  const [textType, setTextType] = useState('')

  const textAreaRef = useRef(null)
  const queryRef = useRef(null)
  useImperativeHandle(ref, () => ({
    openModal: (key, defaultResponse, context, textType) => {
      setActiveResponse(defaultResponse)
      setHistoryStack([defaultResponse])
      setKey(key)
      setVisible(true)
      setHistoryStack([defaultResponse])
      setHistoryIndex(0)
      setContext(context)
      setInputValue('')
      focusQuery()
      setTextType(textType)
      if (defaultResponse == '') {
        handleAIRequest(context)
      }
    },
  }))

  const focusQuery = () => {
    if (queryRef.current) {
      const textAreaElement = queryRef.current?.resizableTextArea?.textArea
      if (textAreaElement) {
        textAreaElement.focus()
      }
    }
  }

  const showTypingEffect = responseText => {
    let index = -1
    setActiveResponse('')

    setGenerating(true)

    const typingInterval = setInterval(() => {
      if (index < responseText.length) {
        setActiveResponse(prevText => prevText + responseText.charAt(index++))
        // Access the actual textarea element inside the Ant Design component
        const textarea = textAreaRef.current?.resizableTextArea?.textArea
        if (textarea) {
          // Check if the scrollHeight is greater than the clientHeight
          if (textarea.scrollHeight > textarea.clientHeight) {
            // Scroll to the bottom to keep the cursor in view
            textarea.scrollTop = textarea.scrollHeight
          }
        }
      } else {
        clearInterval(typingInterval)
        setGenerating(false)
      }
    }, 0)
  }

  const handleAIRequest = async type => {
    if (requestInProgress) {
      message.warning('Please wait for the current request to complete.')
      return
    }

    setRequestInProgress(true)
    setInputValue('')
    const data = await getAPI('ai', 'chat', {
      feature: key,
      user_query: type,
      question: `${context}. Follow the instruction from the user which is given to you. ${type}. The text is:\n${activeResponse}`,
    })

    if (data.success) {
      if (data.warning) {
        message.warning(data.message)
        setRequestInProgress(false)
        return
      }
      const updatedText = data.message.choices[0].message.content
      setHistoryStack([...historyStack, updatedText])
      setHistoryIndex(historyStack.length)
      showTypingEffect(updatedText)
    } else {
      if (store.get('paid') == 0) {
        subscriptionPlanRef?.current?.openModal('swipe_ai')
      }
    }
    setRequestInProgress(false)
  }

  const markdownToHTML = markdown => {
    // Convert headers
    let html = markdown.replace(/^(#{1,6})\s*(.+)$/gm, (match, hashes, content) => {
      const level = hashes.length
      return `<h${level}>${content}</h${level}>`
    })

    // Convert bold (**text** or __text__)
    html = html.replace(/\*\*(.+?)\*\*|__(.+?)__/g, '<strong>$1$2</strong>')

    // Convert italic (*text* or _text_)
    html = html.replace(/\*(.+?)\*|_(.+?)_/g, '<em>$1$2</em>')

    // Convert links [text](url)
    html = html.replace(/\[(.+?)\]\((.+?)\)/g, '<a href="$2">$1</a>')

    // Convert line breaks
    html = html.replace(/\n/g, '<br>')

    return html
  }

  const markdownToPlainText = markdown => {
    // Convert headers to plain text with line breaks
    let plainText = markdown.replace(/^(#{1,6})\s*(.+)$/gm, (match, hashes, content) => {
      return `${content}\n`
    })

    // Convert bold (**text** or __text__) and italic (*text* or _text_) to plain text
    plainText = plainText.replace(/\*\*(.+?)\*\*|__(.+?)__/g, '$1')
    plainText = plainText.replace(/\*(.+?)\*|_(.+?)_/g, '$1')

    // Convert links [text](url) to just text
    plainText = plainText.replace(/\[(.+?)\]\((.+?)\)/g, '$1')

    // Convert unordered list items (-, *, +) to bullets
    plainText = plainText.replace(/^\s*[-*+]\s+/gm, '- ')

    // Convert ordered list items (1., 2., etc.) to bullets
    plainText = plainText.replace(/^\s*\d+\.\s+/gm, '- ')

    // Convert line breaks to actual line breaks
    plainText = plainText.replace(/\n/g, '\n')

    return plainText
  }

  const handleModalContinue = () => {
    setVisible(false)
    if (textType === 'HTML') {
      const html = markdownToHTML(activeResponse)
      onContinue(key, html)
    } else {
      onContinue(key, markdownToPlainText(activeResponse))
    }
  }

  const handleModalClose = () => {
    setVisible(false)
    setActiveResponse('')
    setInputValue('')
    setHistoryStack([])
    setContext('')
    setHistoryIndex(0)
  }

  const handleHistory = move => {
    const updateStack = () => {
      const stackLength = historyStack.length
      let newIndex = 0
      if (stackLength === 0) {
        return
      }

      if (move === 'back') {
        newIndex = historyIndex > 0 ? historyIndex - 1 : 0
        setHistoryIndex(newIndex)
      } else {
        newIndex = historyIndex < stackLength - 1 ? historyIndex + 1 : stackLength - 1
        setHistoryIndex(newIndex)
      }
      setActiveResponse(historyStack[newIndex])
    }
    updateStack()
  }

  return (
    <Modal
      title={
        <>
          Ask Swipe AI <i className="fa-sharp-duotone fa-sparkles ml-2 text-pink"></i>
        </>
      }
      visible={isVisible}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      onCancel={handleModalClose}
      maskClosable={false}
      width={1000}
      footer={[
        <div key="footer-content" className="flex justify-between flex-wrap">
          <div className="mt-4 mb-2">
            <Tooltip title="Go to Previous">
              <i
                className="fa-solid fa-lg fa-rotate-left mr-2"
                style={{
                  opacity: historyIndex <= 0 ? 0.5 : 1,
                  cursor: historyIndex <= 0 ? 'not-allowed' : 'pointer',
                }}
                onClick={e => {
                  e.stopPropagation()
                  handleHistory('back')
                }}
              ></i>
            </Tooltip>
            <Tooltip title="Go to Next">
              <i
                className="fa-solid fa-lg fa-rotate-right mr-2"
                style={{
                  opacity: historyIndex === historyStack.length - 1 ? 0.5 : 1,
                  cursor: historyIndex === historyStack.length - 1 ? 'not-allowed' : 'pointer',
                }}
                onClick={e => {
                  e.stopPropagation()
                  handleHistory('forward')
                }}
              ></i>
            </Tooltip>
            <span className="ml-3 font-size-12 text-gray-400">
              This is AI generated response. Please double check before using it.
            </span>
          </div>
          <div className="mt-4 mb-2">
            <Button key="close" onClick={handleModalClose} style={{ marginRight: '8px' }}>
              Close
            </Button>
            <Button
              key="continue"
              type="primary"
              onClick={handleModalContinue}
              disabled={generating || requestInProgress}
            >
              Use this
            </Button>
          </div>
        </div>,
      ]}
    >
      <Input.TextArea
        value={requestInProgress ? 'Generating...' : activeResponse}
        onChange={e => setActiveResponse(e.target.value)}
        rows={10}
        ref={textAreaRef}
        style={{
          lineHeight: '1.5',
          padding: '12px',
          overflowY: 'auto', // Ensure scroll bar is visible when needed
        }}
      />
      <div className="flex justify-between mt-2">
        <Input.TextArea
          className="w-100 mr-2 mt-4 mb-2 bg-gray-50"
          value={inputValue}
          ref={queryRef}
          size="large"
          onChange={e => setInputValue(e.target.value)}
          placeholder="Type your query here..."
          rows={4}
          autoSize={{ minRows: 1, maxRows: 3 }}
          autoFocus
        />
        <Button
          type="secondary"
          style={{ height: 44 }}
          disabled={!inputValue || requestInProgress}
          className="mt-4 mb-2"
          onClick={() => handleAIRequest(inputValue)}
        >
          <i className="fa-solid fa-paper-plane"></i>
        </Button>
      </div>

      {activeResponse && (
        <div className="flex flex-wrap mt-4">
          <Button
            size=""
            type="secondary"
            className="mr-2 font-weight-medium"
            onClick={() => handleAIRequest('improve the following text')}
            disabled={requestInProgress}
          >
            Improve It!
          </Button>
          <Button
            size=""
            type="secondary"
            className="mr-2 font-weight-medium"
            onClick={() => handleAIRequest('make the following text professional')}
            disabled={requestInProgress}
          >
            Make it Professional!
          </Button>
          <Button
            size=""
            type="secondary"
            className="mr-2 font-weight-medium"
            onClick={() => handleAIRequest('fix the grammar and spellings in the following text')}
            disabled={requestInProgress}
          >
            Fix Grammar & Spellings!
          </Button>
          <Button
            size=""
            type="secondary"
            className="mr-2 font-weight-medium"
            onClick={() => handleAIRequest('simplify the following text')}
            disabled={requestInProgress}
          >
            Shorten It!
          </Button>
        </div>
      )}
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </Modal>
  )
})

// AIButton Component
export const AIButton = ({ keyProp, initialText, aimodalRef, context, textType = '' }) => {
  const handleClick = e => {
    e.stopPropagation()
    if (aimodalRef && aimodalRef.current) {
      aimodalRef.current.openModal(keyProp, initialText, context, textType)
    }
  }

  return (
    <Tooltip title="Generate using AI">
      <Button type="secondary" className="" size="small" onClick={handleClick}>
        <span className="text-pink">
          <i class="fa-sharp-duotone fa-solid fa-sparkles"></i> AI
        </span>
      </Button>
    </Tooltip>
  )
}

export default AIModal
