import { Button, message } from 'antd'
import { history } from 'index'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Tour from 'reactour'
import store from 'store'
import style from './style.module.scss'

const TutorialComponent = ({ companyDetailsAdded, invoices_created, bankDetailsAdded, logo }) => {
  // prevent in iframe live peview
  const isInIframe = () => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  // prevent in visual-builder
  const isVb = process.env.REACT_APP_VB
  // prevent if touched
  const hidden = store.get(`app.settings.tutorialTouched`) || isInIframe() || isVb
  const [isTourOpen, setIsTourOpen] = useState(!hidden)

  const closeTour = () => {
    store.set(`app.settings.tutorialTouched`, true)
    setIsTourOpen(false)
  }
  const getCurrentStep = curr => {
    // switch(curr+1){
    //   case 2:
    //     store.set('app.menu.openedKeys', "sales");
    //     break;
    //   default:
    //     return
    // }
  }

  const steps = [
    {
      selector: '.t-1',
      content: (
        <div className="flex flex-wrap flex-col items-center">
          <h4 className="font-weight-bolder font-size-36 mb-2">
            Congratulations! You've made a great choice{' '}
          </h4>
          <h4 className="font-weight-bolder font-size-36 mb-2">Let's create your first invoice!</h4>

          <h6 className="font-size-24 text-center mb-5" style={{ color: '#888' }}>
            Boost your business 10x 🚀🚀🚀
          </h6>

          <span className="text-center mb-5">
            <img src={logo} className="mt-1 height-100" alt="Swipe" />
          </span>

          <span className="mb-4 text-center">
            <Button
              onClick={() => window.open('https://www.youtube.com/watch?v=m1DZRhkpf2U')}
              className="mr-2 mb-2 font-weight-bold font-size-12 text-gray"
              type="pos"
              size="large"
            >
              <i className="icmn-play mr-2"></i> Watch Video
            </Button>

            <Button
              onClick={() => {
                store.set(`app.settings.tutorialTouched`, true)
                if (!invoices_created) {
                  message.warning('Please add your company details')
                  history.push('/user?tab=company')
                } else {
                  history.push('/create/invoice')
                }
                setIsTourOpen(false)
              }}
              className="mr-2 font-weight-bold font-size-12 mb-4 t-2"
              type="action"
              size="large"
            >
              <i className="fa fa-file-text-o mr-2" />
              <FormattedMessage id="button.createInvoice" />
            </Button>
          </span>
        </div>
      ),
      style: {
        minWidth: '1000px',
      },
    },
    {
      selector: '.t-3',
      content: 'Manage your all sales data here, You Create, Edit, View Invoices here',
    },
    {
      selector: '.t-4',
      content:
        'Manage your purchases and purchase orders here.You Create, Edit, View Purchases and purchase orders',
    },
    {
      selector: '.t-5',
      content: 'Create and manage Quotations, Pro-Forma Invoices and Delivery challans',
    },
    {
      selector: '.t-6',
      content: 'View and Manage Paid and Pending payments of customers and vendors.',
    },
    {
      selector: '.t-8',
      content: 'Add your services and products here.',
    },
    {
      selector: '.t-17',
      content: 'Manage your Profile, Company details, Bank details, Invoice settings, etc.',
    },
    {
      selector: '.t-20',
      content: 'You can contact our customer support team here.',
    },
    {
      selector: '.t-1',
      content: 'Thank you so much and Enjoy our services.',
    },
  ]

  return (
    (!companyDetailsAdded || !bankDetailsAdded) &&
    !invoices_created && (
      <Tour
        rounded={10}
        className={style.helper}
        maskClassName={style.mask}
        steps={steps}
        isOpen={isTourOpen}
        nextButton={
          <Button>
            Next <i className="fa-duotone fa-angle-double-right pl-1"></i>
          </Button>
        }
        prevButton={
          <Button>
            <i className="fa-duotone fa-angle-double-left pr-1"></i>Prev{' '}
          </Button>
        }
        onRequestClose={() => closeTour()}
        getCurrentStep={curr => getCurrentStep(curr)}
        lastStepNextButton={
          <Button>
            Done<i className="fa-duotone fa-check-double pl-1"></i>
          </Button>
        }
      />
    )
  )
}

function mapStateToProps(state, ownProps) {
  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    invoices_created: state.welcome.invoices_created,
    logo: state.settings.logo,
  }
}

export default connect(mapStateToProps)(TutorialComponent)
