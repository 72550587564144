import axios from 'axios'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import utc from 'dayjs/plugin/utc'
import store from 'store'
dayjs.extend(utc)
dayjs.extend(quarterOfYear)

const kprod = {
  url: {
    API_URL: 'https://app2.getswipe.in/api',
    WEB_URL: 'https://app2.getswipe.in',
    short_URL: 'https://swipe.pe',
    RAZORPAY_ID: 'rzp_live_i4Oy6UsKbZeVd0',
    RAZORPAY_PARTNER_CLIENT_ID: 'Oa0XBSgrB3xmjV',
    BEACON_ID: 'KRHDS7',
  },
}

const prod = {
  url: {
    API_URL: 'https://app.getswipe.in/api',
    WEB_URL: 'https://app.getswipe.in',
    short_URL: 'https://swipe.pe',
    RAZORPAY_ID: 'rzp_live_i4Oy6UsKbZeVd0',
    RAZORPAY_PARTNER_CLIENT_ID: 'Oa0XBSgrB3xmjV',
    BEACON_ID: 'KRHDS7',
  },
}

const staging = {
  url: {
    API_URL: 'https://dev2.getswipe.in/api',
    WEB_URL: 'https://dev2.getswipe.in',
    short_URL: 'https://swipe.pe',
    RAZORPAY_ID: 'rzp_test_FvY4UT34aCPrNM',
    RAZORPAY_PARTNER_CLIENT_ID: 'Oa0XBAaSVMX82B',
    BEACON_ID: 'sandbox_KRHDS7',
  },
}

const dev = {
  url: {
    API_URL: 'https://dev.getswipe.in/api',
    // API_URL: 'https://app.getswipe.in/api',
    // API_URL: 'http://localhost:9091',
    short_URL: 'https://swipe.pe',
    // API_URL: 'http://localhost:9091',
    // API_URL: 'http://localhost:8081',
    WEB_URL: 'localhost:3000',
    RAZORPAY_ID: 'rzp_test_FvY4UT34aCPrNM',
    RAZORPAY_PARTNER_CLIENT_ID: 'Oa0XBAaSVMX82B',
    BEACON_ID: 'sandbox_KRHDS7',
  },
}

export const allowedHostnames = [
  'erp.vectorx.co',
  'getswipe.in',
  'localhost',
  '192.168',
  'swip.ee',
  '15.207.118.187',
  '20.197.42.139',
  'swipe.pe',
  'localhost',
  'ngrok-free.app',
  '20.197',
  '4.213.45.92',
  '20.235.222.165',
  '4.213.193.78',
  '4.213.195.212',
]

export const config =
  process.env.REACT_APP_STAGE === 'development'
    ? dev
    : process.env.REACT_APP_STAGE === 'staging'
    ? staging
    : process.env.REACT_APP_KPROD === 'true'
    ? kprod
    : prod

const apiClient = axios.create({
  baseURL: config.url.API_URL,
})

export const ranges: { [key: string]: dayjs.Dayjs[] } = {
  Today: [dayjs(), dayjs()],
  Yesterday: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
  'This Week': [dayjs().startOf('week'), dayjs().endOf('week')],
  'Last Week': [
    dayjs()
      .subtract(1, 'weeks')
      .startOf('week'),
    dayjs()
      .subtract(1, 'weeks')
      .endOf('week'),
  ],
  'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
  'Last Month': [
    dayjs()
      .subtract(1, 'months')
      .startOf('month'),
    dayjs()
      .subtract(1, 'months')
      .endOf('month'),
  ],
  'Last Year': [
    dayjs()
      .subtract(1, 'year')
      .startOf('year'),
    dayjs()
      .subtract(1, 'year')
      .endOf('year'),
  ],
  'This Year': [dayjs().startOf('year'), dayjs().endOf('year')],
  'FY 24-25': [
    dayjs()
      .year(2024)
      .month(3)
      .startOf('month'),
    dayjs()
      .year(2025)
      .month(2)
      .endOf('month'),
  ],
  'FY 23-24': [
    dayjs()
      .year(2023)
      .month(3)
      .startOf('month'),
    dayjs()
      .year(2024)
      .month(2)
      .endOf('month'),
  ],
  'FY 22-23': [
    dayjs()
      .year(2022)
      .month(3)
      .startOf('month'),
    dayjs()
      .year(2023)
      .month(2)
      .endOf('month'),
  ],
  'FY 21-22': [
    dayjs()
      .year(2021)
      .month(3)
      .startOf('month'),
    dayjs()
      .year(2022)
      .month(2)
      .endOf('month'),
  ],
  'Last Quarter': [
    dayjs()
      .quarter(dayjs().quarter() - 1)
      .startOf('quarter'),
    dayjs()
      .quarter(dayjs().quarter() - 1)
      .endOf('quarter'),
  ],
  'Last 7 days': [dayjs().subtract(6, 'days'), dayjs()],
  'Last 30 days': [dayjs().subtract(29, 'days'), dayjs()],
}

export const get_range_name = (date_string: string) => {
  let range_name = ''
  try {
    const date_array = date_string.split(' - ')
    const start = date_array[0]
    const end = date_array[1]
    let dateFormat = 'DD-MM-YYYY'
    Object.keys(ranges).map((key, index) => {
      if (
        ranges[key][0].format(dateFormat) === start &&
        ranges[key][1].format(dateFormat) === end
      ) {
        range_name = key
      }
    })
  } catch {
    return range_name
  }
  return range_name
}

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  const agentAccessToken = store.get('agentAccessKey')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  if (agentAccessToken) {
    request.headers.AgentAuthorization = `Bearer ${agentAccessToken}`
  }
  return request
})

export default apiClient
