import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Drawer, Form, Input, List, message, Switch, Tabs, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import CustomDeleteModal from 'components/modal/delete/customFieldDelete'
import MyEditor from 'components/other/editor'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import store from 'store'
import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { getAPI, getAPIData } from 'services/jwt'
const { TabPane } = Tabs

const CustomNotes = forwardRef((props, ref) => {
  const integrations = useSelector(state => state.permissions.integrations)
  const [key, setKey] = useState('1')
  const [showdocumentDrawer, setShowDocumentDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [noteObject, setNoteObject] = useState({})
  const [noteList, setNoteList] = useState([])
  const [doc_type, setDocType] = useState('')
  const [doc_name, setDocName] = useState('')
  const [is_notes, setIsNotes] = useState(false)
  const [showEditDrawer, setShowEditDrawer] = useState(false)
  const [selectedNotes, setSelectedNotes] = useState({})
  const [email_list, setEmailList] = useState([])
  const [selected_email, setSelectedEmail] = useState({})
  const [showEmailDrawer, setShowEmailDrawer] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [headerEditorState, setHeaderEditorState] = useState(EditorState.createEmpty())
  const [footerEditorState, setFooterEditorState] = useState(EditorState.createEmpty())
  const [label, setLabel] = useState('')
  const user = useSelector(state => state.user)
  const doc_types = [
    'invoice',
    'sales_return',
    'purchase',
    'purchase_return',
    'purchase_order',
    'estimate',
    'delivery_challan',
    'pro_forma_invoice',
    'email',
    'packing_list',
  ]
  const doc_names = [
    'Invoice',
    'Sales Return',
    'Purchase',
    'Purchase Return',
    'Purchase Order',
    'Quotation',
    'Delivery Challan',
    'Pro Forma Invoice',
    'Email',
    'Packing List',
  ]
  const formItemLayout = {
    // labelCol: {
    //   sm: { span: 8 },
    // },
    // wrapperCol: {
    //   sm: { span: 18 },
    // },
  }
  const notesRef = createRef()
  const emailRef = createRef()
  const deleteModalRef = createRef()
  const onTabChange = async key => {
    let key_1 = parseInt(key)
    const is_notes = key_1 % 2 == 1 ? 1 : 0
    let doc_type = ''
    let doc_name = ''

    doc_type = doc_types[Math.floor((key_1 - 1) / 2)]
    doc_name = doc_names[Math.floor((key_1 - 1) / 2)]
    if (key_1 == 17) {
      const data = await getAPIData('utils', 'email_templates')
      if (data.success) {
        setEmailList(data.email_templates)
      }
    } else {
      const req = {
        is_notes,
        document_type: doc_type,
      }
      const data = await getAPI('user', 'get_document_notes', req)
      if (data.success) {
        setNoteList(data.data)
      }
      setDocName(doc_name)
      setDocType(doc_type)
      setIsNotes(key_1 % 2 == 1)
    }

    setKey(key)
  }
  const showDrawer = (document_type = 'invoice', is_notes = 1) => {
    setShowDocumentDrawer(true)

    let index = doc_types.indexOf(document_type) * 2 + 1
    if (is_notes == 0) {
      index += 1
    }
    onTabChange(index.toString())
  }
  const editNotes = data => {
    setSelectedNotes(data)
    setShowEditDrawer(true)
  }
  const editEmail = data => {
    setSelectedEmail(data)
    setShowEmailDrawer(true)
  }
  const onChangeNote = async req => {
    const data = await getAPI('user', 'edit_document_notes', req)
    onTabChange(key)
    setShowEditDrawer(false)
  }
  const onChangeEmailText = async req => {
    if (req.label == '') {
      message.error('Label cannot be empty')
      return
    }
    if (req.email_text == '' || req.email_text == '<p><br></p>') {
      message.error('Email text cannot be empty')
      return
    }
    const data = await getAPI('utils', 'email_templates', req)
    onTabChange(key)
    setShowEmailDrawer(false)
  }

  useImperativeHandle(ref, () => ({
    showDrawer,
  }))
  const closeDrawer = () => {
    props.refreshNotesTerms()
    setShowDocumentDrawer(false)
  }
  const onDescriptionChange = data => {
    setSelectedEmail({
      ...selected_email,
      // email_text: stateToHTML(data.getCurrentContent()),
    })
  }

  return (
    <Drawer
      title={
        <>
          <div className="flex items-center">
            <div className="mr-auto">
              <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                Document Notes
              </span>
            </div>
          </div>
        </>
      }
      placement="right"
      width={isMobile ? '90%' : '50%'}
      closable={() => closeDrawer()}
      onClose={() => closeDrawer()}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      open={showdocumentDrawer}
      destroyOnClose={true}
      footer={
        <>
          <Button
            onClick={() => closeDrawer()}
            type="primary"
            className="mr-2 arrow-transition"
            loading={loading}
          >
            Save
            <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
          </Button>

          <Button onClick={() => closeDrawer()} type="danger">
            Close
          </Button>
        </>
      }
    >
      <Card>
        <Tabs
          defaultActiveKey={'1'}
          activeKey={key}
          onChange={onTabChange}
          destroyInactiveTabPane={true}
          //   key={this.state.defaultActiveKey}
        >
          <TabPane tab="Invoice Notes" key="1"></TabPane>
          <TabPane tab="Invoice Terms" key="2"></TabPane>
          <TabPane tab="Sales Return Notes" key="3"></TabPane>
          <TabPane tab="Sales Return Terms" key="4"></TabPane>
          <TabPane tab="Purchase Notes" key="5"></TabPane>
          <TabPane tab="Purchase Terms" key="6"></TabPane>
          <TabPane tab="Purchase Return Notes" key="7"></TabPane>
          <TabPane tab="Purchase Return Terms" key="8"></TabPane>
          <TabPane tab="Purchase Order Notes" key="9"></TabPane>
          <TabPane tab="Purchase Order Terms" key="10"></TabPane>
          <TabPane tab="Quotation Notes" key="11"></TabPane>
          <TabPane tab="Quotation Terms" key="12"></TabPane>
          {user?.selectedCompany?.country_code == 'IN' && (
            <TabPane tab="Delivery Challan Notes" key="13"></TabPane>
          )}
          {user?.selectedCompany?.country_code == 'IN' && (
            <TabPane tab="Delivery Challan Terms" key="14"></TabPane>
          )}
          <TabPane tab="Pro Forma Invoice Notes" key="15"></TabPane>
          <TabPane tab="Pro Forma Invoice Terms" key="16"></TabPane>
          <TabPane tab="Email Templates" key="17"></TabPane>

          {(store.get('is_export') == 1 || integrations?.packing_list == 1) && (
            <TabPane tab="Packing List Notes" key="19"></TabPane>
          )}
        </Tabs>
        {Array.from({ length: 16 }, (_, index) => (index + 1).toString())
          .concat('19')
          .includes(key) ? (
          <>
            <List
              header={
                <div>
                  <Typography.Text
                    style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                    className="p-1"
                  >
                    Note: Active fields will be shown in the {doc_name}{' '}
                    {is_notes ? 'Notes' : 'Terms'}.
                  </Typography.Text>
                </div>
              }
              footer={
                <div className="center mt-4">
                  <Button
                    type="dashed"
                    style={{ borderColor: 'blue' }}
                    onClick={() =>
                      //   store.get('paid') == 0
                      //     ? this.subscription.showModal()
                      // :
                      editNotes({
                        note_id: -1,
                        is_delete: 0,
                        is_active: 1,
                        is_notes,
                        document_type: doc_type,
                        notes: '',
                        is_default: 0,
                        label: '',
                      })
                    }
                    block
                    icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
                  >
                    <span className="font-weight-bold" style={{ color: 'blue' }}>
                      New {doc_name} {is_notes ? 'Notes' : 'Terms'}
                    </span>
                  </Button>
                </div>
              }
              dataSource={noteList}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Switch
                      checkedChildren="Default"
                      unCheckedChildren="Not Default"
                      checked={item.is_default}
                      style={{ color: 'blue' }}
                      onChange={(checked, e) => onChangeNote({ ...item, is_default: checked })}
                    />,
                    <Switch
                      checked={item.is_active}
                      style={{ color: 'blue' }}
                      onChange={(checked, e) => onChangeNote({ ...item, is_active: checked })}
                    />,
                    <a
                      onClick={e => editNotes(item)}
                      className="card-link font-weight-bold text-action font-size-14"
                    >
                      <span>Edit</span>
                      <i className="fa fa-pencil-o" />
                    </a>,
                    // <Popconfirm
                    //   title="Sure to delete?"
                    //   onConfirm={e => onChangeNote({ ...item, is_delete: 1 })}
                    // >
                    //   <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
                    //     Delete
                    //   </span>
                    // </Popconfirm>,
                    <span
                      className="text-danger font-weight-bold"
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation()
                        deleteModalRef?.current?.showDeleteModal(item, true)
                      }}
                    >
                      Delete
                    </span>,
                  ]}
                >
                  <span style={{ width: '60%' }}>
                    <span style={{ fontWeight: 'bold' }}>{item.label}</span>
                    <br />
                    {item.notes}
                  </span>
                </List.Item>
              )}
            />
            <CustomDeleteModal ref={deleteModalRef} onFinish={onChangeNote} />
          </>
        ) : (
          <>
            <List
              header={
                <div>
                  <Typography.Text
                    style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                    className="p-1"
                  >
                    Note: Active fields will be shown in the Email Templates.
                  </Typography.Text>
                </div>
              }
              footer={
                <div className="center mt-4">
                  <Button
                    type="dashed"
                    style={{ borderColor: 'blue' }}
                    onClick={() => {
                      setEditorState(EditorState.createEmpty())
                      setLabel('')
                      setHeaderEditorState(EditorState.createEmpty())
                      setFooterEditorState(EditorState.createEmpty())
                      editEmail({
                        template_id: -1,
                        is_delete: 0,
                        is_active: 1,
                        email_text: '',
                        label: '',
                        is_default: 0,
                      })
                    }}
                    block
                    icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
                  >
                    <span className="font-weight-bold" style={{ color: 'blue' }}>
                      New Email Template
                    </span>
                  </Button>
                </div>
              }
              dataSource={email_list}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Switch
                      checkedChildren="Default"
                      unCheckedChildren="Not Default"
                      checked={item.is_default}
                      style={{ color: 'blue' }}
                      onChange={(checked, e) => onChangeEmailText({ ...item, is_default: checked })}
                    />,
                    <Switch
                      checked={item.is_active}
                      style={{ color: 'blue' }}
                      onChange={(checked, e) => onChangeEmailText({ ...item, is_active: checked })}
                    />,
                    <a
                      onClick={e => {
                        setEditorState(
                          EditorState.createWithContent(stateFromHTML(item.email_text)),
                        )
                        setHeaderEditorState(
                          EditorState.createWithContent(
                            stateFromHTML(item.email_header ? item.email_header : ''),
                          ),
                        )
                        setFooterEditorState(
                          EditorState.createWithContent(
                            stateFromHTML(item.email_footer ? item.email_footer : ''),
                          ),
                        )
                        setLabel(item.label)
                        editEmail({
                          ...item,
                        })
                      }}
                      className="card-link font-weight-bold text-action font-size-14"
                    >
                      <span>Edit</span>
                      <i className="fa fa-pencil-o" />
                    </a>,
                    // <Popconfirm
                    //   title="Sure to delete?"
                    //   onConfirm={e => onChangeEmailText({ ...item, is_delete: 1 })}
                    // >
                    //   <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
                    //     Delete
                    //   </span>
                    // </Popconfirm>,
                    <span
                      className="text-danger font-weight-bold"
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation()
                        deleteModalRef?.current?.showDeleteModal(item, true)
                      }}
                    >
                      Delete
                    </span>,
                  ]}
                >
                  <span style={{ width: '60%' }}>
                    <span style={{ fontWeight: 'bold' }}>{item.label}</span>
                    <br />
                    {/* {item.email_text} */}
                    <p dangerouslySetInnerHTML={{ __html: item.email_text }}></p>
                  </span>
                </List.Item>
              )}
            />
            <CustomDeleteModal ref={deleteModalRef} onFinish={onChangeEmailText} />
          </>
        )}
      </Card>
      <Drawer
        title={
          <>
            <div className="flex items-center">
              <div className="mr-auto">
                <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                  Add/Edit {doc_name} {is_notes ? 'Notes' : 'Terms'}
                </span>
              </div>
            </div>
          </>
        }
        placement="right"
        width={isMobile ? '90%' : '40%'}
        closable={() => setShowEditDrawer(false)}
        onClose={() => setShowEditDrawer(false)}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        open={showEditDrawer}
        destroyOnClose={true}
        extra={
          <Button
            onClick={() => notesRef.current.submit()}
            type="primary"
            className="arrow-transition"
          >
            Save
            <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
          </Button>
        }
        footer={
          <>
            <Button
              onClick={() => notesRef.current.submit()}
              type="primary"
              className="mr-2 arrow-transition"
              loading={loading}
            >
              Save
              <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
            </Button>

            <Button onClick={() => setShowEditDrawer(false)} type="danger">
              Close
            </Button>
          </>
        }
      >
        <Card>
          <Form
            {...formItemLayout}
            labelAlign="left"
            ref={notesRef}
            name="NotesEditForm"
            onFinish={values => onChangeNote({ ...selectedNotes, ...values })}
            initialValues={selectedNotes}
            size="default"
          >
            <h6>{'Label'}:</h6>

            <Form.Item
              name={'label'}
              rules={[{ required: true, message: 'Please fill the Field Name' }]}
              className="w-full"
            >
              <Input placeholder={'Label'} className="w-full" />
            </Form.Item>
            <h6>{is_notes ? 'Notes' : 'Terms'}:</h6>

            <Form.Item
              name={'notes'}
              rules={[{ required: true, message: 'Please fill the Field Name' }]}
              className="w-full"
            >
              <TextArea
                rows={7}
                placeholder={doc_name + ' ' + is_notes ? 'Notes' : 'Terms'}
                className="w-full"
              />
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
      <Drawer
        title={
          <>
            <div className="flex items-center">
              <div className="mr-auto">
                <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                  Add/Edit Email Template
                </span>
              </div>
            </div>
          </>
        }
        placement="right"
        width={isMobile ? '90%' : '40%'}
        closable={() => setShowEmailDrawer(false)}
        onClose={() => setShowEmailDrawer(false)}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        open={showEmailDrawer}
        destroyOnClose={true}
        extra={
          <Button
            onClick={() =>
              onChangeEmailText({
                ...selected_email,
                label: label,
                email_text: stateToHTML(editorState.getCurrentContent()),
              })
            }
            type="primary"
            className="arrow-transition"
          >
            Save
            <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
          </Button>
        }
        footer={
          <>
            <Button
              onClick={() =>
                onChangeEmailText({
                  ...selected_email,
                  label: label,
                  email_text: stateToHTML(editorState.getCurrentContent()),
                  email_header: stateToHTML(headerEditorState.getCurrentContent()),
                  email_footer: stateToHTML(footerEditorState.getCurrentContent()),
                })
              }
              type="primary"
              className="mr-2 arrow-transition"
              loading={loading}
            >
              Save
              <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
            </Button>

            <Button onClick={() => setShowEmailDrawer(false)} type="danger">
              Close
            </Button>
          </>
        }
      >
        <Card>
          <h6>{'Template Name'}:</h6>
          <Input
            placeholder="Template Name"
            className="w-full"
            value={label}
            onChange={e => setLabel(e.target.value)}
          />
          <h6 className="mt-2">Email Header:</h6>
          <MyEditor
            placeholder={'Email Header'}
            editorState={headerEditorState}
            onChange={(text, headerEditorState) => setHeaderEditorState(headerEditorState)}
          />
          <h6 className="mt-2">Email Text:</h6>
          <MyEditor
            placeholder={'Email Text'}
            editorState={editorState}
            onChange={(text, editorState) => setEditorState(editorState)}
          />
          <h6 className="mt-2">Email Footer:</h6>
          <MyEditor
            placeholder={'Email Footer'}
            editorState={footerEditorState}
            onChange={(text, footerEditorState) => setFooterEditorState(footerEditorState)}
          />
        </Card>
      </Drawer>
    </Drawer>
  )
})
export default CustomNotes
