import { StyleSheet, Text, View } from '@react-pdf/renderer'

const getStyles = (FONT_FACTOR, PAPER_SIZE) => {
  return StyleSheet.create({
    // table: {
    //   display: 'table',
    //   width: '100%',
    //   borderStyle: 'solid',
    //   borderWidth: 10,
    //   borderColor: '#bfbfbf',
    //   marginBottom: 10,
    // },
    // tableRow: {
    //   width: '100%',
    //   flexDirection: 'row',
    // },
    // tableCol: {
    //   width: '25%',
    //   borderStyle: 'solid',
    //   borderWidth: 1,
    //   borderColor: '#bfbfbf',
    //   padding: 5,
    //   margin: 50,
    // },
    // tableCell: {
    //   fontSize: 7 * FONT_FACTOR,
    // },
    batch_details: {
      fontSize: 7 * FONT_FACTOR,
      color: '#212020',
      display: 'block',
    },
  })
}
const ItemBatchDetails = ({ invoice, item }) => {
  var styles = getStyles(
    invoice.invoice_settings.font_size,
    invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8,
  )

  return (
    invoice.batch_settings.show_as_separate_cols == 0 && (
      <View style={styles.batch_details}>
        {item.batch_no != '' && (
          <Text>
            {item.has_batches == 2
              ? invoice.batch_settings.serial_number_label
                ? invoice.batch_settings.serial_number_label + ': \n'
                : ''
              : 'Batch No: '}{' '}
            {item.has_batches == 2 ? (
              <>
                <View style={styles.table}>
                  {/* <View style={styles.tableRow}>
                    {item.batch_no.split(',').map((sno, colIndex) => (
                      <View style={styles.tableCol} key={colIndex}>
                        <Text style={styles.tableCell}>{sno}</Text>
                      </View>
                    ))}
                  </View> */}

                  {item.batch_no.split(',').map((sno, colIndex) => {
                    return (
                      <Text>
                        {sno}
                        {'\n'}
                      </Text>
                    )
                  })}
                </View>
              </>
            ) : (
              item.batch_no
            )}
          </Text>
        )}
        {item.has_batches == 1 && item.mfg_date != '' && <Text>{'Mfg Dt: ' + item.mfg_date}</Text>}
        {item.has_batches == 1 && item.expiry_date != '' && (
          <Text>{'Exp Dt: ' + item.expiry_date}</Text>
        )}
      </View>
    )
  )
}

export default ItemBatchDetails
