import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { setCompanyDetails } from 'redux/permissions/actions'
import { getAPI, getAPIData } from 'services/jwt'
import store from 'store'
import {
  setApiLoading,
  set_all_doc_counts,
  set_batch_settings,
  set_business_analytics,
  set_pos_settings,
  set_pricing_plans,
  set_product_options,
  set_top_custom_fields,
  set_units,
} from './actions'
import {
  GET_ALL_DOC_COUNTS,
  GET_BATCH_SETTINGS,
  GET_BUSINESS_ANALYTICS,
  GET_CATEGORIES,
  GET_COUNTRIES,
  GET_CUSTOM_COLUMNS,
  GET_CUSTOM_COLUMNS_SUGGESTION,
  GET_HSN_SUGGESTION,
  GET_INVOICE_SETTINGS,
  GET_NOTIFICATION_SETTINGS,
  GET_PAYMENT_MODES,
  GET_PORT_CODES,
  GET_POS_SETTINGS,
  GET_PRODUCT_OPTIONS,
  GET_STATES,
  GET_TCS_SECTIONS,
  GET_TDS_SECTIONS,
  GET_UNITS,
  SET_BANK_DETAILS,
  SET_CATEGORIES,
  SET_COUNTRIES,
  SET_CURRENCIES,
  SET_CUSTOM_COLUMNS,
  SET_CUSTOM_COLUMNS_SUGGESTION,
  SET_HSN_SUGGESTION,
  SET_INVOICE_SETTINGS,
  SET_NOTIFICATION_SETTINGS,
  SET_PAYMENT_MODES,
  SET_PORT_CODES,
  SET_STATES,
  SET_TCS_SECTIONS,
  SET_TDS_SECTIONS,
  GET_TOP_CUSTOM_FIELDS,
  GET_PRICING_PLANS,
} from './types'

export function* get_states() {
  let selectedCompany = yield select(state => state.user.selectedCompany)
  let country_code = ''
  let params_string = ''

  if (selectedCompany) {
    country_code = selectedCompany.country_code
  }
  if (country_code != '') {
    params_string = '?country_code=' + country_code
  }

  const stateLoading = yield select(state => state.document.stateLoading)
  if (stateLoading) return
  yield put(setApiLoading({ stateLoading: true }))
  const data = yield call(async () => await getAPIData('utils', 'get_states' + params_string))
  if (data.success) {
    yield put({ type: SET_STATES, payload: data.states })
  }
}
export function* get_countries() {
  const countryLoading = yield select(state => state.document.countryLoading)
  if (countryLoading) return
  yield put(setApiLoading({ countryLoading: true }))
  const data = yield call(async () => await getAPIData('utils', 'get_countries'))
  if (data.success) {
    yield put({ type: SET_COUNTRIES, payload: data.countries })
    let countries = data.countries
    let currencies = [...new Map(countries.map(item => [item['currency_code'], item])).values()]
    yield put({ type: SET_CURRENCIES, payload: currencies })
  }
}
export function* get_tds_sections() {
  const tdsLoading = yield select(state => state.document.tdsLoading)
  if (tdsLoading) return
  yield put(setApiLoading({ tdsLoading: true }))
  const data = yield call(async () => await getAPIData('doc', 'tds_sections'))
  if (data.success) {
    yield put({ type: SET_TDS_SECTIONS, payload: data.data })
  }
}
export function* get_tcs_sections() {
  const tcsLoading = yield select(state => state.document.tcsLoading)
  if (tcsLoading) return
  yield put(setApiLoading({ tcsLoading: true }))
  const data = yield call(async () => await getAPIData('doc', 'tcs_sections'))
  if (data.success) {
    yield put({ type: SET_TCS_SECTIONS, payload: data.data })
  }
}
export function* get_categories() {
  const data = yield call(async () => await getAPIData('category', 'get_categories'))
  if (data.success) {
    yield put({ type: SET_CATEGORIES, payload: data.categories })
  }
}
export function* get_custom_columns() {
  const data = yield call(async () => await getAPIData('v2/custom_fields', 'item'))
  if (data.success) {
    yield put({ type: SET_CUSTOM_COLUMNS, payload: data.custom_columns })
  }
}
export function* get_payment_modes() {
  const paymentModesLoading = yield select(state => state.document.paymentModesLoading)
  const selected_country_code = yield select(state => state.user?.selectedCompany?.country_code)

  if (paymentModesLoading) return
  yield put(setApiLoading({ paymentModesLoading: true }))
  const data = yield call(
    async () =>
      await getAPIData('utils', 'payment_modes' + `?country_code=${selected_country_code}`),
  )
  if (data.success) {
    yield put({ type: SET_PAYMENT_MODES, payload: data.payment_modes })
    yield put({ type: SET_BANK_DETAILS, payload: data.bank_details })
  }
}
export function* get_hsn_suggestion() {
  const hsnSuggestionLoading = yield select(state => state.document.hsnSuggestionLoading)
  if (hsnSuggestionLoading) return
  yield put(setApiLoading({ hsnSuggestionLoading: true }))
  const data = yield call(
    async () => await getAPI('utils', 'get_suggestions', { type: 'hsn_code' }),
  )
  if (data.success) {
    yield put({ type: SET_HSN_SUGGESTION, payload: data.suggestions.hsn_codes })
  }
}
export function* get_custom_columns_suggestion() {
  const data = yield call(
    async () =>
      await getAPI('utils', 'get_suggestions', {
        type: 'item_custom_fields',
      }),
  )
  if (data.success) {
    yield put({ type: SET_CUSTOM_COLUMNS_SUGGESTION, payload: data.suggestions })
  }
}
export function* get_invoice_settings() {
  const invoiceSettingsLoading = yield select(state => state.document.invoiceSettingsLoading)
  const selected_country_code = yield select(state => state.user?.selectedCompany?.country_code)
  if (invoiceSettingsLoading) return
  yield put(setApiLoading({ invoiceSettingsLoading: true }))
  const data = yield call(
    async () =>
      await getAPIData('utils', 'invoice_settings' + `?country_code=${selected_country_code}`),
  )
  if (data.success) {
    yield put({
      type: SET_INVOICE_SETTINGS,
      payload: {
        ...data.invoice_settings,
        invoice_templates: data.invoice_templates,
        num_invoices: data.num_invoices,
        paid: data.paid,
      },
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        document_settings: {
          ...data.invoice_settings,
          invoice_templates: data.invoice_templates,
          num_invoices: data.num_invoices,
          paid: data.paid,
        },
      },
    })
  }
}

export function* get_notification_settings() {
  const notificationSettingsLoading = yield select(
    state => state.document.notificationSettingsLoading,
  )
  if (notificationSettingsLoading) return
  yield put(setApiLoading({ notificationSettingsLoading: true }))
  const data = yield call(async () => await getAPIData('utils', 'notification_settings'))
  if (data.success) {
    yield put({
      type: SET_NOTIFICATION_SETTINGS,
      payload: {
        ...data.notification_settings,
      },
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        notification_settings: {
          ...data.notification_settings,
        },
      },
    })
  }
}

export function* get_port_codes() {
  const data = yield call(async () => await getAPIData('utils', 'port_codes'))
  if (data.success) {
    yield put({ type: SET_PORT_CODES, payload: data.port_codes })
  }
}

export function* get_product_options() {
  let selectedCompany = yield select(state => state.user.selectedCompany)
  let country_code = ''
  let params_string = ''

  if (selectedCompany) {
    country_code = selectedCompany.country_code
  }
  if (country_code != '') {
    params_string = '?country_code=' + country_code
  }

  const data = yield call(async () => await getAPIData('product', 'options' + params_string))
  if (data.success) {
    yield put(set_product_options(data))
    yield put(setCompanyDetails(data.company_details))
  }
}

export function* get_all_doc_counts() {
  const all_doc_counts_loading = yield select(state => state.document.all_doc_counts_loading)
  if (all_doc_counts_loading) return
  yield put(setApiLoading({ all_doc_counts_loading: true }))
  const data = yield call(async () => await getAPIData('utils', 'get_all_doc_counts'))
  if (data.success) {
    yield put(set_all_doc_counts(data.document_counts))
  }
}
export function* get_batch_settings() {
  const data = yield call(async () => await getAPIData('product', 'batch_settings'))
  if (data.success) {
    yield put(set_batch_settings(data.data))
  }
}
export function* get_pos_settings() {
  const posSettingsLoading = yield select(state => state.document.posSettingsLoading)
  if (posSettingsLoading) return
  yield put(setApiLoading({ posSettingsLoading: true }))
  const data = yield call(async () => await getAPIData('user', 'pos_settings'))
  if (data.success) {
    yield put(set_pos_settings(data))
  }
}
export function* get_business_analytics() {
  yield put(setApiLoading({ businessAnalyticsLoading: true }))
  const company_id = yield select(state => state.document.company_details.company_id)
  const country_info = yield select(state => state.countries.info)
  const user = yield select(state => state.user)

  let date_range = store.get('transactions_default_dates_' + company_id)
  if (date_range == null) {
    date_range =
      country_info?.[user?.selectedCompany?.country_code]?.date_ranges['This Year'][0].format(
        'DD-MM-YYYY',
      ) +
      ' - ' +
      country_info?.[user?.selectedCompany?.country_code]?.date_ranges['This Year'][1].format(
        'DD-MM-YYYY',
      )
    store.set('transactions_default_dates_' + company_id, date_range)
  }
  const data = yield call(
    async () =>
      await getAPI('utils', 'get_business_analytics', {
        date_range: date_range,
      }),
  )
  if (data.success) {
    yield put(set_business_analytics(data))
  }
}

export function* get_product_units() {
  const units = yield select(state => state.document.units)
  if (units.length > 0) return
  // console.log(units, units.length, 'units_lenght')
  const data = yield call(async () => await getAPI('utils', 'search_units', { query: '' }))
  if (data.success) {
    yield put(set_units(data.units))
  }
}

export function* get_top_custom_fields() {
  const top_custom_fields = yield select(state => state.document.top_custom_fields)
  if (top_custom_fields.length > 0) return

  const data = yield call(async () => await getAPIData('utils', 'top_custom_fields'))
  if (data.success) {
    yield put(set_top_custom_fields(data.data))
  }
}
export function* get_pricing_plans() {
  let selectedCompany = yield select(state => state.user.selectedCompany)
  let country_code = ''
  let params_string = ''

  if (selectedCompany) {
    country_code = selectedCompany.country_code
  }
  if (country_code != '') {
    params_string = '?country_code=' + country_code
  }

  if (selectedCompany) {
    const pricing_details_loading = yield select(state => state.document.pricing_details_loading)
    if (pricing_details_loading) return
    yield put(setApiLoading({ pricing_details_loading: true }))
    const data = yield call(
      async () => await getAPIData('pricing', 'get_pricing_plans' + params_string),
    )
    if (data.success) {
      yield put(set_pricing_plans(data))
    }
  }
}

export default function* document() {
  yield all([
    takeEvery(GET_STATES, get_states),
    takeEvery(GET_COUNTRIES, get_countries),
    takeEvery(GET_TDS_SECTIONS, get_tds_sections),
    takeLatest(GET_CATEGORIES, get_categories),
    takeLatest(GET_CUSTOM_COLUMNS, get_custom_columns),
    takeEvery(GET_PAYMENT_MODES, get_payment_modes),
    takeEvery(GET_INVOICE_SETTINGS, get_invoice_settings),
    takeEvery(GET_NOTIFICATION_SETTINGS, get_notification_settings),
    takeLatest(GET_PORT_CODES, get_port_codes),
    takeEvery(GET_TCS_SECTIONS, get_tcs_sections),
    takeLatest(GET_CUSTOM_COLUMNS_SUGGESTION, get_custom_columns_suggestion),
    takeEvery(GET_HSN_SUGGESTION, get_hsn_suggestion),
    takeLatest(GET_PRODUCT_OPTIONS, get_product_options),
    takeEvery(GET_ALL_DOC_COUNTS, get_all_doc_counts),
    takeLatest(GET_BATCH_SETTINGS, get_batch_settings),
    takeLatest(GET_POS_SETTINGS, get_pos_settings),
    takeLatest(GET_TOP_CUSTOM_FIELDS, get_top_custom_fields),
    takeEvery(GET_BUSINESS_ANALYTICS, get_business_analytics),
    takeEvery(GET_UNITS, get_product_units),
    takeEvery(GET_PRICING_PLANS, get_pricing_plans),
  ])
}
