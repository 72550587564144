import React, { Component } from 'react'

import { Button, Modal } from 'antd'
import PayInForm from 'components/forms/payForm'
import Ledger from 'pages/payment/ledger'
import { LedgerDocument } from 'pages/payment/ledgerPDF'
import EnhancedCustomerFrom from '../../forms/customerForm'
import EnhancedVendorFrom from '../../forms/vendorform'
import { EnhancedExportPDF } from '../exportPDF'
import { connect } from 'react-redux'

const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

class LedgerView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      data: {},
      utilts: {},
      pdfFlag: false,
      pdfData: '',
      search: '',
      searchType: 'Name',
      page: 0,
      num_records: 10,
    }

    this.closeModal = this.closeModal.bind(this)
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = data => {
    this.setState({ flag: true, data: data }, () => {
      this.ledger.callData(0, 10)
    })
  }
  closeModal() {
    this.setState({ flag: false, data: {} }, () => {
      this.props.onAPICalled()
      this.ledger.clearData()
    })
  }

  callData(page, records) {
    this.ledger.callData(this.state.page, this.state.num_records)
  }

  ledgerPDFView = async data => {
    this.setState({ pdfFlag: true, pdfData: data })
  }
  getStatusClass = data => {
    switch (data) {
      case 'pending':
        return 'warning'
      case 'paid':
        return 'success'
      case 'cancelled':
        return 'danger'
      case 'partially paid':
        return 'partial'
      default:
        return 'danger'
    }
  }

  render() {
    return (
      <>
        {this.state.pdfData != '' && this.state.pdfFlag && (
          <Modal
            title="Party Ledger"
            open={this.state.pdfFlag}
            style={{ top: 27 }}
            width={900}
            onOk={() => this.setState({ pdfFlag: false })}
            onCancel={() => this.setState({ pdfFlag: false })}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button key="back" type="default" onClick={() => this.setState({ pdfFlag: false })}>
                Cancel
              </Button>,
            ]}
          >
            <LedgerDocument data={this.state.pdfData} />
          </Modal>
        )}
        <Modal
          title={
            <div className="flex flex-wrap">
              <div className="flex flex-wrap  font-size-16">
                <div className="inline-flex items-center mr-2 pt-1">
                  <div className="text-nowrap inline-block">
                    <div className="kit__utils__heading">
                      <h4>
                        <span className="mr-3 font-weight-bold">Ledger</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          open={this.state.flag}
          onCancel={this.closeModal}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          width={'90vw'}
          style={{ top: 27 }}
          footer={
            <>
              <Button key="back" onClick={this.closeModal}>
                Close
              </Button>
            </>
          }
        >
          <div>
            <div className="row">
              <div className="col-12">
                {this.state.data.id != -1 && (
                  <Ledger
                    selectedUser={this.state.data}
                    onAPICalled={this.ledgerPDFView}
                    type={this.props.apiName || this.state.data.type}
                    onRef={ref => (this.ledger = ref)}
                    callData={(page, num_records) => this.props.callData(page, num_records)}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
        <PayInForm onRef={ref => (this.payin = ref)} onApicalled={() => this.callData()} />
        <EnhancedExportPDF
          onRef={ref => (this.exportPDF = ref)}
          menuName={`${this.props.apiName}/ledger`}
          id={this.state.data.id}
          onAPICalled={this.ledgerPDFView}
        />
        <EnhancedCustomerFrom
          onRef={ref => (this.customer = ref)}
          onAPICalled={this.props.onAPICalled}
        />
        <EnhancedVendorFrom
          onRef={ref => (this.vendor = ref)}
          onAPICalled={this.props.onAPICalled}
        />
      </>
    )
  }
}

export default connect(null, null, null, { forwardRef: true })(LedgerView)
