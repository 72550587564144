import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { getAPI } from 'services/jwt'

const GetItemSuggestions = forwardRef((_, ref) => {
  const [itemSuggestions, setItemSuggestions] = useState([])
  const [hitRate, setHitRate] = useState(0)
  const [algo, setAlgo] = useState('')

  useImperativeHandle(ref, () => ({
    updateHitRate,
    checkHitRate,
    fetchItemSuggestions,
    publishHitRate,
  }))

  const updateHitRate = value => {
    setHitRate(hitRate + value)
  }

  const publishHitRate = (document_type, doc_count, num_items) => {
    getAPI('utils', 'publish_hit_rate', {
      document_type,
      doc_count,
      hit_rate: hitRate,
      num_items,
      algo,
    })
      .then(data => {
        if (data.success) {
          console.log('Hit rate published successfully')
        }
      })
      .catch(error => {
        console.error('Error publishing hit rate:', error)
      })
  }

  const checkHitRate = product_id => {
    let hit_rate = 0
    for (const suggested_product_id of itemSuggestions || []) {
      if (suggested_product_id === product_id) {
        hit_rate = 1
        break // break from loop
      }
    }
    updateHitRate(hit_rate)
    return hit_rate
  }

  const fetchItemSuggestions = (current_items, party_id, document_type) => {
    // Call get_suggestions API in the background
    getAPI('utils', 'get_suggestions', {
      current_items,
      party_id,
      document_type,
      type: 'items',
    })
      .then(data => {
        if (data.success) {
          setItemSuggestions(data.suggestions)
          if (!algo) {
            setAlgo(data.algo)
          }
        }
      })
      .catch(error => {
        console.error('Error fetching item suggestions:', error)
      })
  }

  return null // This component does not render anything
})

export default GetItemSuggestions
