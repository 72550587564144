import { PDFViewer } from '@react-pdf/renderer'
import { Checkbox, message } from 'antd'
import Invoice1 from 'components/new_invoice/1/index'
import Invoice10 from 'components/new_invoice/10/index'
import Invoice11 from 'components/new_invoice/11/index'
import Invoice12 from 'components/new_invoice/12/index'
import Invoice13 from 'components/new_invoice/13/index'
import Invoice2 from 'components/new_invoice/2/index'
import Invoice3 from 'components/new_invoice/3/index'
import Invoice4 from 'components/new_invoice/4/index'
import Invoice5 from 'components/new_invoice/5/index'
import Invoice6 from 'components/new_invoice/6/index'
import Invoice7 from 'components/new_invoice/7/index'
import Invoice8 from 'components/new_invoice/8/index'
import Invoice9 from 'components/new_invoice/9/index'
import { history } from 'index'
import React, { Component, useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { getAPI, getAPIData } from 'services/jwt'

const Map = {
  Invoice1: Invoice1,
  Invoice2: Invoice2,
  Invoice3: Invoice3,
  Invoice4: Invoice4,
  Invoice5: Invoice5,
  Invoice6: Invoice6,
  Invoice7: Invoice7,
  Invoice8: Invoice8,
  Invoice9: Invoice9,
  Invoice10: Invoice10,
  Invoice11: Invoice11,
  Invoice12: Invoice12,
  Invoice13: Invoice13,
}

export default class InvoiceView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      menuName: 'sales',
      type: ['ORIGINAL FOR RECIPIENT'],
      key: 0,
      invoice_settings: {},
    }
  }

  async componentDidMount() {
    const pramsData = window.location.pathname.split('/')
    // console.log(pramsData)
    const req = {
      serial_number: pramsData[pramsData.length - 1],
    }
    var data = await getAPI(pramsData[pramsData.length - 2], 'get_invoice', req)

    if (data) {
      this.setState({
        data: data.invoice_details,
        menuName: pramsData[pramsData.length - 2],
        invoice_settings: data.invoice_settings,
      })
    } else {
      message.error("There isn't any invoice of this number")
      history.replace('/' + pramsData[pramsData.length - 2])
    }
  }

  onCheckedBoxChange = data => {
    this.setState({ type: [...data], myKey: Math.random() })
  }

  shouldComponentUpdate() {
    return true
  }

  render() {
    const options = [
      { label: 'Customer', value: 'ORIGINAL FOR RECIPIENT' },
      { label: 'Transport', value: 'DUPLICATE FOR TRANSPORTER' },
      { label: 'Supplier', value: 'TRIPLICATE FOR SUPPLIER' },
    ]

    return (
      <React.Fragment>
        <Link
          to={'/' + (this.state.menuName == 'sales_returns' ? 'sales' : this.state.menuName)}
          className="btn btn-sm btn-outline-light mb-2"
        >
          <i className="icmn-arrow-left2 mr-1"></i> Back
        </Link>
        {this.state.menuName == 'sales' && this.state.data != '' && (
          <Checkbox.Group
            onChange={checkedValues => {
              this.onCheckedBoxChange(checkedValues)
            }}
            options={options}
            defaultValue={this.state.type}
            className="mb-3 ml-5"
          ></Checkbox.Group>
        )}
        {this.state.data != '' && (
          <MyDocument
            data={this.state.data}
            menuName={this.state.menuName}
            type={this.state.type}
            myKey={this.state.myKey}
            flag={false}
            invoiceSettings={this.state.invoice_settings}
          />
        )}
      </React.Fragment>
    )
  }
}

export function MyDocument({ data, menuName, type, myKey, isPublic, companyDetails }) {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    isReady: false,
    invoiceSettings: {},
  })

  useEffect(() => {
    async function getInvoiceSettings() {
      var data1 = ''
      if (isPublic) {
        data1 = await getAPI('utils', 'public_invoice_settings', {
          company_id: companyDetails.company_id,
        })
      } else {
        var data1 = await getAPIData('utils', 'invoice_settings')
      }

      setState({ invoiceSettings: data1.invoice_settings, isReady: true })
    }
    getInvoiceSettings()
  }, [])

  var SelectedTemplate = Map['Invoice1']
  if (menuName == 'sales' || menuName == 'pos' || menuName == 'sales_returns') {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.invoice_template)]
  } else if (
    menuName == 'purchases' ||
    menuName == 'purchase_orders' ||
    menuName == 'purchase_returns'
  ) {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.purchase_template)]
  } else if (
    menuName == 'estimates' ||
    menuName == 'delivery_challans' ||
    menuName == 'pro_forma_invoices'
  ) {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.estimate_template)]
  } else {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.invoice_template)]
  }

  if (SelectedTemplate == undefined) {
    SelectedTemplate = Map['Invoice1']
  }

  return (
    <>
      {state.isReady ? (
        <PDFViewer className="w-full p-0" style={{ height: '85vh' }}>
          <SelectedTemplate
            invoice={{
              ...data,
              document_type: menuName,
              type: type,
              myKey: myKey == undefined ? 0 : myKey,
            }}
          />
        </PDFViewer>
      ) : (
        ''
      )}
    </>
  )
}

//custom PDF Viewer Component

// const PDF = ({ SelectedTemplate, menuName, type, myKey, data }) => {
//   const [instance, updateInstance] = usePDF({
//     document: (
//       <SelectedTemplate
//         invoice={{
//           ...data,
//           document_type: menuName,
//           type: type,
//           myKey: myKey == undefined ? 0 : myKey,
//         }}
//       />
//     ),
//   })

//   useEffect(updateInstance, [menuName, type, myKey])

//   useEffect( () => {
//     await fetch(instance.url)
//       .then(response => response.blob())
//       .then(data => {
//         var metadata = {
//           type: data.type,
//         }
//         console.log(data.type)
//         var file = new File([data], 'invoice.pdf', metadata)
//         console.log(file)
//         let url = file ? URL.createObjectURL(file) : ''
//         console.log(url)
//       })
//   }, [instance.url])

//   console.log(instance)
//   return (
//     <div className="col-12 p-0" style={{ height: '85vh' }}>
//       <iframe src={instance.url} title="Invoice" style={{ width: '100%', height: '100%' }}></iframe>
//     </div>
//   )
// }
