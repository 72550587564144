import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Layout, Menu, Modal } from 'antd'
import classNames from 'classnames'
import NewBadge from 'components/badges/new'
import Support from 'components/modal/feedback'
import { history } from 'index'
import { find } from 'lodash'
import Reports from 'pages/reports'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { currentAccount, getAPI } from 'services/jwt'
import store from 'store'
import { CONSTANTS } from 'utils/constants'
import style from './style.module.scss'

const { confirm } = Modal

const mapStateToProps = state => {
  const { menu, settings, user, permissions } = state

  return {
    menuData: menu.menuData,
    isMenuCollapsed: settings.isMenuCollapsed,
    isMobileView: settings.isMobileView,
    isMenuUnfixed: settings.isMenuUnfixed,
    isMenuShadow: settings.isMenuShadow,
    leftMenuWidth: settings.leftMenuWidth,
    menuColor: settings.menuColor,
    logo: settings.logo,
    role: user.role,
    companies: user.companies,
    user: user,
    permissions: permissions,
  }
}

function showConfirm({ url }) {
  confirm({
    title: 'Do you want to exit?',
    content: 'You will lose your unsaved data.',
    icon: <ExclamationCircleOutlined />,
    okText: 'Confirm',
    onOk() {
      history.push(url)
    },
    onCancel() {},
  })
}

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },
  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
  role,
  user,
  permissions,
  ...props
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])
  const [visible, setVisible] = useState(false)
  const [selectedCompany, setCompany] = useState(user.selectedCompany)
  const [stateCompanies, setStateCompanies] = useState(user)
  const [showReports, setShowReports] = useState(false)
  const supportRef = useRef()
  const reportsRef = useRef()
  useEffect(() => {
    applySelectedKeys()
    const pramsData = window?.location?.pathname.split('/')
    // try {
    //   if (pramsData[pramsData.length - 1] === 'reports') {
    //     reportsRef?.current?.showModal()
    //   }
    // } catch (e) {
    //   console.log(e)
    // }
  }, [pathname, menuData, user])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])

    menuData.map(item => {
      if (item.children) {
        item.children.map((sub, ind) => {
          if (sub.url === pathname) {
            setOpenedKeys(!isMenuCollapsed ? [item.key] : [])
          }
        })
      }
    })
    // setCompany(user.selectedCompany)
    setStateCompanies(user)
    // console.log(selectedCompany)
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    store.set('app.menu.openedKeys', [e.keyPath[1]])
    setOpenedKeys([e.keyPath[1]])
    setSelectedKeys([e.key])
    store.remove('page')
  }
  const filter_children = (item, permission) => {
    item.children = item.children.map(sub => {
      if (permission[sub.key] !== undefined ? permission[sub.key] == 1 : true) {
        return sub
      }
      return ''
    })
    item.children = item.children.filter(sub => sub != '')
    if (item.children.length > 0) {
      return item
    }
  }
  const generateMenu = menuData => {
    const permission = permissions.permission
    if (Object.keys(permission).length > 0) {
      menuData = menuData.map(item => {
        if (
          item.title == 'Sales' ||
          item.key == 'purchases' ||
          item.key == 'estimates' ||
          item.key == 'payments'
        ) {
          return filter_children(item, permission)
        } else if (
          item.key == 'settings' ||
          item.key == 'tutorials' ||
          item.key == 'expenses_menu' ||
          item.key == 'coupons' ||
          item.category == true ||
          permission[item.key] == 1
        ) {
          return item
        } else if (
          item.key == 'packing_lists' &&
          (selectedCompany?.is_export == 1 || permission.packing_list == 1)
        ) {
          return item
        } else if (item.key == 'mydrive' && role == 'admin') {
          return item
        }
      })
    }
    menuData = menuData.filter(item => item != undefined)
    if (menuData.length < 12) {
      menuData = menuData.filter(item => item.category != true)
    }

    return menuData
  }
  const generateMenuItems = () => {
    const menuChange = item => {
      const pramsData = window.location.pathname.split('/')

      if (
        pramsData[pramsData.length - 1] == 'pos' ||
        pramsData[pramsData.length - 1] == 'invoice' ||
        pramsData[pramsData.length - 1] == 'purchase' ||
        pramsData[pramsData.length - 1] == 'estimate'
      ) {
        showConfirm(item)
      } else if (pramsData[pramsData.length - 1] == 'reports') {
        setShowReports(true)
      } else {
        history.push(item.url + '')
      }
    }

    const generateItem = (item, index) => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }
      if (item.url) {
        return (
          <Menu.Item
            key={key}
            disabled={disabled}
            className={
              store.get('app.menu.selectedKeys') && store.get('app.menu.selectedKeys')[0] == key
                ? `no-children noselect ${item.className}`
                : `noselect ${item.className}`
            }
          >
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {/* {count && <span className="badge badge-success ml-2">{count}</span>} */}
                {icon && (
                  <span className={`${icon} fa-fw ${style.icon} icon-collapsed-hidden fa-fw`} />
                )}
                <span className={style.title}>
                  <FormattedMessage id={`menuLeft.${key}`} />
                </span>
              </a>
            )}
            {/* {(window.location.pathname.split('/'))[window.location.pathname.split('/').length - 1]=="pos" && console.log("pos menu")} */}
            {!item.target && (
              <a onClick={() => menuChange(item)}>
                {/* {count && <span className="badge badge-success ml-2">{count}</span>} */}
                {icon && <span className={`${icon} fa-fw ${style.icon} icon-collapsed-hidden`} />}
                <span className={style.title}>
                  <FormattedMessage id={`menuLeft.${key}`} /> {item.new && <NewBadge />}
                </span>
              </a>
            )}
          </Menu.Item>
        )
      }
      if (item.key == 'reports') {
        return (
          <Menu.Item
            className={`noselect`}
            onClick={() => {
              dispatch({
                type: 'settings/CHANGE_SETTING',
                payload: {
                  setting: 'isMenuCollapsed',
                  value: false,
                },
              })
              reportsRef.current.showModal()
            }}
          >
            {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
            <span className={style.title}>
              <FormattedMessage id={`menuLeft.${key}`} /> {item.new && <NewBadge />}
            </span>
          </Menu.Item>
        )
      }

      return (
        <Menu.Item key={index} disabled={disabled} className={`noselect ${item.className}`}>
          {/* {count && <span className="badge badge-success ml-2">{count}</span>} */}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
          <span className={style.title}>
            <FormattedMessage id={`menuLeft.${key}`} />
            {item.new && <NewBadge />}
          </span>
        </Menu.Item>
      )
    }

    const generateSubMenuItem = (item, index) => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled} className={`noselect ${item.className}`}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden fa-fw`} />}
                <span className={style.title}>
                  <FormattedMessage id={`menuLeft.${key}`} />
                  {item.new && <NewBadge />}
                </span>
              </a>
            )}

            {!item.target && (
              <a onClick={() => menuChange(item)}>
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden fa-fw`} />}
                <span className={`${style.title} font-size-14 ml-2`}>
                  <FormattedMessage id={`menuLeft.${key}`} />
                  {item.new && <NewBadge />}
                </span>
              </a>
            )}
          </Menu.Item>
        )
      }

      return (
        <Menu.Item key={index} disabled={disabled} className={`noselect ${item.className}`}>
          {/* {count && <span className="badge badge-success ml-2">{count}</span>} */}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden fa-fw`} />}
          <span className={style.title}>
            <FormattedMessage id={`menuLeft.${key}`} />
            {item.new && <NewBadge />}
          </span>
        </Menu.Item>
      )
    }

    const generateSubmenu = (items, index) =>
      items.map((menuItem, index) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <a
              href={url}
              target={menuItem.target}
              rel="noopener noreferrer"
              className={`${menuItem.className}`}
            >
              {/* {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>} */}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}

              <span className={style.title} style={{ fontSize: 10 }}>
                <FormattedMessage id={`menuLeft.${menuItem.key}`} />
              </span>
            </a>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children, index)}
            </Menu.SubMenu>
          )
        }
        return generateSubMenuItem(menuItem, index)
      })
    menuData = generateMenu(menuData)

    return menuData.map((menuItem, index) => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span
            key={menuItem.key}
            onClick={() => {
              store.set('app.menu.selectedKeys', [menuItem.children[0].key])
              history.push(menuItem.children[0].url + '')
            }}
          >
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}  ml-2`} />}
            <span className={style.title}>
              <FormattedMessage id={`menuLeft.${menuItem.key}`} />{' '}
            </span>
          </span>
        )
        return (
          <Menu.SubMenu
            title={subMenuTitle}
            key={menuItem.key}
            className={`noselect ${menuItem.className}`}
            onTitleClick={() => {
              store.set('app.menu.selectedKeys', [menuItem.children[0].key])
              history.push(menuItem.children[0].url + '')
            }}
          >
            {generateSubmenu(menuItem.children, index)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem, index)
    })
  }

  const handleMenuClick = e => {
    setVisible(false)
    stateCompanies.companies.every(async (element, index, array) => {
      if (element['company_id'] == e.key) {
        // console.log(element, e.key)
        let req = {
          company_id: element.company_id,
        }
        const data = await getAPI('user', 'get_token', req)
        if (data.success) {
          store.set('accessToken', data.token)
          store.set('selectedCompany', element)
        }
        const data2 = await currentAccount()
        await dispatch({
          type: 'user/SET_STATE',
          payload: {
            selectedCompany: element,
          },
        })
        store.set('page', 0)
        window.location.reload()
        return false
      }
      return true
    })
  }

  const updateCompanies = () => {
    return (
      <Menu onClick={handleMenuClick}>
        <Menu.ItemGroup>
          {stateCompanies.companies.map((item, index) => {
            return (
              <Menu.Item key={item.company_id}>
                {item.logo != '' && item.logo != null && item.logo != 'None' && (
                  <Avatar shape="square" size="large" className="mr-2 float-left" src={item.logo} />
                )}
                {(item.logo == '' || item.logo == null || item.logo == 'None') &&
                  item.organization_name.length > 0 && (
                    <Avatar
                      style={{ backgroundColor: '#ff3819' }}
                      shape="square"
                      size="large"
                      className="mr-2 float-left font-weight-bold"
                    >
                      {item.organization_name.charAt(0)}
                    </Avatar>
                  )}
                <span className="font-weight-medium ">
                  <span className="mb-0 pb-0 font-size-14">
                    {item.organization_name}{' '}
                    {item.company_name != item.organization_name && '(' + item.company_name + ')'}{' '}
                    {item.current_company == 1 ? (
                      <i className="fa fa-check-circle ml-2 font-size-21 text-green" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <span>
                    <p className="mt-0 mb-0 font-weight-medium font-size-12">
                      {item.current_company == 1 && (
                        <Link to="/user" className="text-green">
                          Edit Company Details
                        </Link>
                      )}
                    </p>
                  </span>
                </span>
              </Menu.Item>
            )
          })}

          <Button
            className="mt-1 mb-2 font-weight-bold"
            block
            onClick={() =>
              store.get('add_companies') <= 0
                ? window.open(
                    'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I want to add New Company. Please give me details for Business Plan.',
                    '_self',
                  )
                : history.push('/add_new_company')
            }
          >
            <i className="fa fa-plu mr-2" />
            Want to add a new business?
          </Button>
        </Menu.ItemGroup>
      </Menu>
    )
  }

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'md',
      }

  const handleVisibleChange = flag => {
    setVisible(flag)
  }

  const [collapsed, setCollapsed] = useState(false)

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`)}
      trigger={
        <span>
          <i className="fa-solid fa-bars text-gray-500 fa-lg"></i>
        </span>
      }
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 128px)',
        }}
      >
        <PerfectScrollbar className="mt-4 mb-4">
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="5"
          >
            {generateMenuItems()}
            <Menu.Item
              className={`noselect`}
              onClick={() => {
                supportRef.current.showModal()
              }}
            >
              {<span className={`fa-solid fa-message ${style.icon} icon-collapsed-hidden`} />}
              <span className={style.title}>Feedback</span>
            </Menu.Item>
          </Menu>

          {store.get('paid') == 0 && !window.location.hostname.includes('mac.getswipe.in') && (
            <div className={style.buyPro}>
              <p>
                <strong>
                  Enjoy customizable templates,{' '}
                  {user?.selectedCompany?.country_code == 'IN' ? 'GST' : ''} reports, and premium
                  support
                </strong>
              </p>
              <Button
                type="default"
                size="default"
                shape="round"
                onClick={() => history.push('/pricing')}
                className="font-weight-bolder w-full p-1"
              >
                {CONSTANTS.PRICING_BUTTON}
              </Button>
            </div>
          )}
        </PerfectScrollbar>

        {/* Reports Modal */}
        <Reports ref={reportsRef} />
        <Support ref={supportRef} onRef={() => {}} />
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
