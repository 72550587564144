import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Table, Button } from 'antd'

import { EnhancedDelete } from 'components/modal/delete'
import InventoryForm from 'components/forms/inventoryForm'

class InsufficientItemsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProduct: {},
    }
  }

  refreshStock = async () => {
    const { quantity } = this.form.getFieldValues()
    const { items } = this.props
    const { product_id } = this.state.selectedProduct

    const updatedItems = items
      .map(item => {
        if (item.product_id === product_id) {
          return {
            ...item,
            available_qty: item.available_qty + quantity,
          }
        }
        return item
      })
      .filter(item => item.available_qty < item.qty)

    this.props.updateInsufficientModalItems({ items: updatedItems })
    if (updatedItems.length === 0) {
      this.props.onClose()
    }
  }

  allowBatches = document_type => {
    return document_type == 'estimate' ||
      document_type == 'purchase_order' ||
      document_type == 'pro_forma_invoice'
      ? false
      : true
  }

  handleStockIn = (e, currentData) => {
    e.stopPropagation()
    this.setState({ selectedProduct: currentData })
    if (currentData.has_batches == 2) {
      this.handleAddBatches({
        ...currentData,
        variant_id: 0,
        name: '',
      })
      return
    }
    this.form.onFill(
      {
        ...currentData,
        // variant_id: 0,
        name: '',
        warehouse_id: this.props.items.warehouse_id,
      },
      true,
    )
  }

  showDeleteModal = currentData => {
    this.setState({ selectedProduct: currentData })
    this.delete.showModal(currentData)
  }

  render() {
    const { visible, onClose, items, message } = this.props

    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (text, record) => {
          return (
            <span>
              {record.product_name} {record.variant_name}
            </span>
          )
        },
      },
      {
        title: 'Requested Quantity',
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        title: 'Available Quantity',
        dataIndex: 'available_qty',
        key: 'available_qty',
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (text, currentData) => (
          <>
            <Button
              type="success"
              size="small"
              className="font-weight-bold mr-2"
              onClick={e => this.handleStockIn(e, currentData)}
            >
              <i className="fa-regular fa-circle-plus fa-lg mr-1" />
              <FormattedMessage id="button.stockIn" />
            </Button>
            <Button type="danger" size="small" onClick={() => this.showDeleteModal(currentData)}>
              <i className="fa-solid fa-trash-can" />
            </Button>
          </>
        ),
      },
    ]

    return (
      <>
        <Modal
          title={
            <>
              <i className="fad fa-info-circle mr-3 text-danger"></i>
              Insufficient Items
            </>
          }
          visible={visible}
          onCancel={onClose}
          footer={null}
          width={800}
        >
          {message}
          <Table dataSource={items} columns={columns} rowKey={'key'} />
        </Modal>
        <InventoryForm onRef={ref => (this.form = ref)} onAPICalled={this.refreshStock} />
        <EnhancedDelete
          onRef={ref => (this.delete = ref)}
          document_type={'product'}
          handleDelete={() =>
            this.props.deleteInsufficientItem({ key: this.state.selectedProduct.key })
          }
        />
      </>
    )
  }
}

export default InsufficientItemsModal
