// import 'antd/lib/style/index.less' // antd core styles
// import './components/kit/vendors/antd/themes/default.less' // default theme antd components
// import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import { createBrowserHistory } from 'history'
import './global.scss' // app & third-party component styles

import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import createSagaMiddleware from 'redux-saga'
import Localization from './localization'
import configureStore from './redux'
import sagas from './redux/sagas'
import AppRouter from './router'

import * as serviceWorker from './serviceWorker'
// import PrintProvider, { NoPrint} from "react-easy-print";
import { IntercomProvider } from 'react-use-intercom'
import firebase from './pages/firebasejs'
import IntercomComp from './pages/support/IntercomComp'
// mocking api
// import 'services/axios/fakeApi'

// AG GRID License
import { LicenseManager } from '@ag-grid-enterprise/core'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-058571}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Nextspeed_Technologies,_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Swipe}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Swipe}_need_to_be_licensed___{Swipe}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{17_April_2025}____[v3]_[01]_MTc0NDg0NDQwMDAwMA==de6cdc7fc9fb24a9f880a7a124e6c1fd',
)

// middlewares
const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
const INTERCOM_APP_ID = 'be01q1gj'

const { store, reduxHistory } = configureStore(history, sagaMiddleware)
sagaMiddleware.run(sagas)

window.addEventListener('error', function(event) {
  if (event?.error?.hasBeenCaught !== undefined) {
    return false
  }
  firebase.analytics().logEvent('exception', event.error)
  event.error.hasBeenCaught = true
})

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <Localization>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <IntercomComp />
          <Router history={reduxHistory}>
            <AppRouter />
          </Router>
        </IntercomProvider>
      </Localization>
    </Provider>,
  )
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.registerServiceWorker()

// serviceWorker.unregister()
export { history, store }
