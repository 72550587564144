import { Button } from 'antd'
import { Editor, RichUtils } from 'draft-js'
import { debounce } from 'lodash'
import React from 'react'
// import './index.css'

export default class RichEditorExample extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: this.props?.editorState || null,
    }
    this.onChangeParent = debounce(this.onChangeParent, 200)
  }

  componentDidUpdate(prevProps) {
    if (this.props.editorState !== prevProps.editorState) {
      this.setState({ editorState: this.props.editorState })
    }
  }

  onChange = editorState => {
    this.setState({ ...this.state, editorState })
    this.onChangeParent(editorState)
  }

  onChangeParent = editorState => {
    this.props.onChange('editorState', editorState)
  }

  updateEditorState = editorState => {
    this.setState({ editorState })
  }

  getEditorState = () => {
    if (this.props.showFromState) {
      return this.state.editorState
    }
    return this.props.editorState
  }

  focus = () => this.refs.editor.focus()

  handleKeyCommand = command => {
    const { editorState } = this.props
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return true
    }
    return false
  }

  // keyBindingFn = e => {
  //   if (this.props.downArrowEnter) {
  //     if (e.key == 'ArrowDown' && e.shiftKey) {
  //       this.onChange(RichUtils.insertSoftNewline(this.getEditorState()))
  //     } else {
  //       this.handleKeyCommand(e.key.toLowerCase())
  //     }
  //   } else {
  //     this.handleKeyCommand(e.key.toLowerCase())
  //   }
  // }

  onTab = e => {
    const maxDepth = 4
    this.onChange(RichUtils.onTab(e, this.getEditorState(), maxDepth))
  }
  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.getEditorState(), blockType))
  }
  toggleInlineStyle = inlineStyle => {
    this.onChange(RichUtils.toggleInlineStyle(this.getEditorState(), inlineStyle))
  }
  render() {
    const editorState = this.getEditorState()
    let className = 'RichEditor-editor'
    return (
      <div className="RichEditor-root">
        {/* <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} /> */}
        <div className="flex justify-between">
          <BlockStyleControls
            editorState={this.state.editorState}
            onToggleBlockType={this.toggleBlockType}
            onToggleInline={this.toggleInlineStyle}
          />
          {this.props.showSaveButton && (
            <Button
              size="small"
              type="transparent"
              onClick={() => {
                this.props.onSave(this.state.editorState)
              }}
            >
              Save
            </Button>
          )}
        </div>

        <div className={className} onClick={this.focus}>
          <Editor
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            placeholder={this.props.placeholder}
            ref="editor"
            spellCheck={true}
            // kseyBindingFn={this.keyBindingFn}
          />
        </div>
      </div>
    )
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
}
class StyleButton extends React.Component {
  constructor() {
    super()
    this.onToggle = e => {
      e.preventDefault()
      this.props.onToggle(this.props.style)
    }
  }
  render() {
    let className = 'RichEditor-styleButton'
    if (this.props.active) {
      className += ' RichEditor-activeButton'
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    )
  }
}
const BLOCK_TYPES = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
]

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
]
const BlockStyleControls = props => {
  const { editorState } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
  var currentStyle = props.editorState.getCurrentInlineStyle()
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          label={type.label}
          active={currentStyle.has(type.style)}
          onToggle={props.onToggleInline}
          style={type.style}
        />
      ))}
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          label={type.label}
          onToggle={props.onToggleBlockType}
          active={type.style === blockType}
          style={type.style}
        />
      ))}
    </div>
  )
}

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle()
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          label={type.label}
          active={currentStyle.has(type.style)}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

RichEditorExample.defaultProps = {
  placeholder: 'Add product description here...',
}
