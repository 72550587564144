import React, { Component, useMemo } from 'react'

import { AgGridReact } from '@ag-grid-community/react' // AG Grid Component
import '@ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the grid
import './styles/reportsTheme.css'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { connect } from 'react-redux'
import { ModuleRegistry } from '@ag-grid-community/core'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { Card, Col, Empty, Popover, Row } from 'antd'
import InfoComponent from 'components/badges/InfoComponent'
import InfoIcon from 'components/info/info_icon'
import analyticsLoading from 'lotties/analyticsLoading.json'
import Lottie from 'react-lottie'
import { Spin } from 'antd'

ModuleRegistry.registerModules([ColumnsToolPanelModule, FiltersToolPanelModule, StatusBarModule])

const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

// check if mobile device
const isMobile = window.innerWidth <= 500

const cellEditors = {
  taxEditor: 'taxEditor',
  agTextCellEditor: 'agTextCellEditor',
  agLargeTextCellEditor: 'agLargeTextCellEditor',
  agSelectCellEditor: 'agSelectCellEditor',
  agRichSelectCellEditor: 'agRichSelectCellEditor',
  agNumberCellEditor: 'agNumberCellEditor',
  agDateStringCellEditor: 'agDateStringCellEditor',
  agDateCellEditor: 'agDateCellEditor',
  agCheckboxCellEditor: 'agCheckboxCellEditor',
}

const filterTypes = {
  text: 'agTextColumnFilter',
  list: 'agSetColumnFilter',
  number: 'agNumberColumnFilter',
  date: 'agDateColumnFilter',
  multi: 'agMultiColumnFilter',
}
const getStatusBar = props => {
  const statusBar = props.statusBar
  const statusBarComponent = props => {
    return (
      <div>
        {Object.keys(props.statusBar ?? {}).length > 0 &&
          Object.keys(props.statusBar).map((key, index) => (
            <Row gutter={[24, 16]} style={{ width: 500 }}>
              <Col span={12}>
                <h6>
                  <span className="text-gray-500 font-size-16">{key}</span>
                </h6>
              </Col>
              <Col span={12} className="text-left">
                <h6>
                  <span className="text-gray-500 font-size-16">
                    <span className=" mr-1">{props?.user?.selectedCompany?.currency_symbol}</span>
                    {Math.round(props.statusBar[key]).toLocaleString('en-IN', dotOptions)}
                  </span>{' '}
                </h6>
              </Col>{' '}
            </Row>
          ))}
      </div>
    )
  }

  return {
    statusPanels: [
      {
        statusPanel: 'agAggregationComponent',
        statusPanelParams: {
          aggFuncs: ['count', 'sum', 'avg'],
        },
        align: 'right',
      },
      // {
      //   statusPanel: statusBarComponent,
      //   statusPanelParams: {
      //     statusBar: statusBar,
      //     user: props.user,
      //   },
      //   align: 'right',
      //   key: 'statusBar',
      // },
    ],
  }
}

class ReportTable extends Component {
  gridRef = React.createRef()
  footerGridRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      currentRowData: [],
      currentNode: {},
      reload: false,
      statusBar: {},
      hideFooter: false,
    }

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Manage Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            // suppressColumnFilter: true,
            // suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
            // suppressSyncLayoutWithGrid:true
            suppressPivotMode: true,
          },
        },
        // {
        //   id: 'filters',
        //   labelDefault: 'Filters',
        //   labelKey: 'filters',
        //   iconKey: 'filter',
        //   toolPanel: 'agFiltersToolPanel',
        // },
      ],
      defaultToolPanel: '',
      hiddenByDefault: false,
    }
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
  }

  componentWillUnmount() {
    this.gridRef.current.api.destroy()
  }

  api = () => {
    return this.gridRef.current.api
  }

  getRowData() {
    let data = []
    this.gridRef.current.api.forEachNode((rowNode, index) => {
      data.push(rowNode.data)
    })
    return data
  }

  highlightErrorRows = (rowIds, columnField, errorMsg) => {
    rowIds.map(rowId => {
      const rowNode = this.gridRef.current.api.getRowNode(rowId)
      if (rowNode) {
        rowNode.setData({ ...rowNode.data, error: true, tooltipText: errorMsg })
      }
    })
  }

  updateRowValue = data => {
    const rowNode = this.state.currentNode.node
    if (rowNode) {
      rowNode.updateData(data)
    }
  }

  getCellEditor = field => {
    if (field != '') {
      let editor = this.cellEditors && this.cellEditors[field] ? this.cellEditors[field] : null
      if (editor) {
        return editor
      }

      Object.keys(cellEditors).map(key => {
        if (key.toLowerCase().includes(field.toLowerCase())) {
          editor = cellEditors[key]
        }
      })
      return editor
    }

    return null
  }
  getDataAsExcel = (resetFilters = false) => {
    if (resetFilters) {
      this.resetFilter()
    }

    let blob = this.gridRef.current.api.getDataAsExcel()

    return new File([blob], 'data.xlsx', {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  }

  setFilter = async filters => {
    await this.gridRef.current.api.setFilterModel(filters)
    this.gridRef.current.api.onFilterChanged()
  }

  resetFilter = async () => {
    await this.gridRef.current.api.setFilterModel(null)
    await this.gridRef.current.api.setAdvancedFilterModel(null)
  }
  getSelectedRows = () => {
    return this.gridRef.current.api.getSelectedRows()
  }

  setAdvancedFilters = async filters => {
    this.gridRef.current.api.setAdvancedFilterModel(filters)
  }

  loadingOverlay = () => {
    return (
      <>
        {/* <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: analyticsLoading,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
              progressiveLoad: true,
              hideOnTransparent: true,
              clearCanvas: true,
              context: null,
            },
          }}
          height={400}
          width={400}
        /> */}
        <Spin />
      </>
    )
  }

  noRowOverlay = () => {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Empty
          description={
            <span className="text-gray-500 font-weight-bold">No data found with given filters</span>
          }
        />
      </div>
    )
  }

  getContextMenuItems = params => {
    var result = [
      // built in copy item
      'autoSizeAll',
      'separator',
      'copy',
      'copyWithHeaders',
      'separator',
      'excelExport',
    ]
    return result
  }

  render() {
    // const agTheme = this.props.theme != 'swipedark' ? 'ag-theme-custom' : 'ag-theme-custom-dark'
    const agTheme = this.props.theme

    const gridColumns = this.props.columns.map((column, index) => {
      let cellRenderer = params => {
        return <>{params.value}</>
      }

      // if (column.isHtml) {
      //   console.log('entered')
      //   cellRenderer = params => {
      //     const data = params.data
      //     const text = params.value
      //     console.log('column rendered', params)

      //     return (
      //       <Popover
      //         title="Description"
      //         trigger={['click']}
      //         // placement="right"
      //         // className="popover-child"
      //         content={<span dangerouslySetInnerHTML={{ __html: text }}></span>}
      //         onClick={e => e.stopPropagation()}
      //       >
      //         <span>
      //           <Button type="text">Show {params.colDef.headerName}</Button>
      //         </span>
      //       </Popover>
      //     )
      //   }
      // }

      column = {
        width: column.width,
        flex: column.width ? 0 : 1,
        minWidth: this.props.minRowWidth || 200,
        suppressMovable: column.disabled,
        field: column.field || column.dataIndex,
        headerName: column.title,
        cellEditor: this.getCellEditor(column.cellEditor ? column.cellEditor : ''),
        cellStyle: params => {
          if (
            params.data.error &&
            params.data.error_columns &&
            params.data.error_columns.includes(column.field || column.dataIndex)
          ) {
            return {
              border: '1px solid red',
              backgroundColor: 'rgba(255,0,0,0.1)',
              borderRadius: '5px',
            }
          }
          return null
        },
        lockVisible: column.disabled == true,
        hide: column.active == false,
        sortable: column.sorter,
        unSortIcon: true,
        cellRenderer: column.cellRenderer
          ? column.cellRenderer
          : params => {
              return cellRenderer(params)
            },
        editable: false,
        filter: column.filterType ? filterTypes[column.filterType] ?? false : false,
        floatingFilter: false,
        filterParams: {
          filters: column.filters,
          values: Object.keys(column.filters ?? {}),
          valueFormatter: params => {
            return params.colDef.filterParams.filters[params.value] ?? params.value
          },
          buttons: ['apply', 'reset'],
          closeOnApply: true,
        },
      }

      return column
    })
    var haveTotals = false
    const statusBarObject = this.props.statusBar
      ? gridColumns.reduce((acc, column, index) => {
          acc[column.field] = index === 0 ? 'Totals' : this.props.statusBar[column.headerName] ?? ''
          haveTotals = this.props.statusBar[column.headerName] ?? false ? true : haveTotals
          return acc
        }, {})
      : {}

    const defaultColDefs = {
      // flex: 1,
      // minWidth: this.props.minRowWidth || 200,
      // autoHeight: true,
      autoHeaderHeight: true,
      wrapText: true,
      editable: this.props.editable === undefined ? true : this.props.editable,
      sortable: this.props.sortable === false ? false : true,
      // filter: this.props.filter === undefined ? false : this.props.filter,
      enableCellChangeFlash: this.props.enableCellChangeFlash || true,
      lockVisible: this.props.lockVisible === undefined ? false : this.props.lockVisible,
      tooltipField: this.props.tooltipText || 'tooltipText',
      tooltipComponent: props => {
        return (
          <InfoComponent
            description={<span dangerouslySetInnerHTML={{ __html: props.value }}></span>}
            type="info"
          />
        )
      },
      onCellValueChanged: event => {
        if (event.newValue === null || event.newValue === undefined) {
          event.newValue = ''
          if (!this.props.readOnlyEdit) {
            event.node.setDataValue(event.colDef.field, event.newValue)
          }
        }

        if (this.props.readOnlyEdit) {
          this.setState({ currentNode: event }, () => {
            this.props.onUpdateValue(event.data, event.colDef.field, event.newValue)
          })
        }
      },
    }

    // Grid Properties
    const rowDragEntireRow =
      this.props.rowDragEntireRow == undefined ? false : this.props.rowDragEntireRow
    const rowDragManaged =
      this.props.rowDragManaged == undefined ? rowDragEntireRow : this.props.rowDragManaged

    return (
      <div
        style={{ width: '100%', height: '100%' }}
        className={this.props.cellSelection === false ? 'ag-no-select' : ''}
      >
        <div id="advancedFilterParent" className="my-2 d-none"></div>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '65vh' }}
          className={`${agTheme}`}
        >
          <div style={{ flex: '1 1 auto' }}>
            <AgGridReact
              ref={this.gridRef}
              alignedGrids={[this.footerGridRef]}
              // suppressHorizontalScroll
              alwaysShowVerticalScroll
              onStateUpdated={params => {
                if (this.props.statusBar && Object.keys(this.props.statusBar).length > 0) {
                  this.gridRef.current.api.setGridOption('statusBar', getStatusBar(this.props))
                }
              }}
              key={this.props.gridKey || 'ag-grid-table'}
              // serverSideDatasource={this.props.dataSource}
              grandTotalRow="bottom"
              rowStyle={{
                alignItems: 'center',
              }}
              // loading={this.props.loading}
              // showNoRowsOverlay={true}
              noRowsOverlayComponent={this.noRowOverlay}
              // noRowsOverlayComponentParams={this.noRowsOverlayComponentParams}
              domLayout={this.props.domLayout}
              loadingOverlayComponent={this.loadingOverlay}
              loadingCellRenderer={() => ''}
              animateRows={this.props.animateRows || true}
              // getRowHeight={params => {
              //   this.props.rowHeight === undefined
              //     ? params.node.group
              //       ? 50
              //       : 35
              //     : this.props.rowHeight
              // }}
              // getRowId sets the unique id for each row, cane give specific id from the data or by default it will be index if prop is not passed
              // getRowId={params => params.data[this.props.rowId || 'id']}
              // rowClassRules={
              //   this.props.classRules
              //     ? this.props.classRules
              //     : {
              //         'bg-danger': 'data.error === true',
              //         'bg-lgreen': "data.status === 'new'",
              //         'bg-warning': "data.status === 'update'",
              //       }
              // }
              // domLayout={"autoHeight"}
              columnDefs={gridColumns}
              columnHoverHighlight={true}
              onColumnMoved={params => {
                // console.log('moved', params)
                if (
                  params.finished === true &&
                  this.props.onColumnChange &&
                  (params.source == 'toolPanelUi' || params.source == 'uiColumnMoved')
                ) {
                  this.props.onColumnChange(params)
                }
              }}
              onColumnVisible={params => {
                // console.log('visible', params)
                if (this.props.onColumnChange && params.source == 'toolPanelUi') {
                  this.props.onColumnChange(params)
                }
              }}
              onColumnResized={params => {
                // console.log('resize', params)
                if (
                  params.finished === true &&
                  params.source != 'flex' &&
                  ['toolPanelUi', 'uiColumnResized'].includes(params.source) &&
                  this.props.onColumnChange
                ) {
                  this.props.onColumnChange(params)
                }
              }}
              sideBar={this.sideBar}
              allowDragFromColumnsToolPanel={true}
              reactiveCustomComponents={true}
              suppressDragLeaveHidesColumns={true}
              defaultColDef={defaultColDefs}
              // onFilterChanged={params => {
              //   console.log(params)
              // }}
              // statusBar={statusBar}
              // Edit type can be set to fullRow , fullRow will allow editing of full row [all the cells in the row are editable]
              editType={this.props.editType || ''}
              pagination={
                this.props.user.paid
                  ? this.props.pagination === undefined
                    ? true
                    : this.props.pagination
                  : false
              }
              paginationPageSize={this.props.num_records || 20}
              paginationPageSizeSelector={[10, 20, 30, 40, 50, 100]}
              onGridReady={event => {
                this.props.onReady(event)
              }}
              getRowStyle={params => {
                if (params.node.rowPinned && params.data.is_total) {
                  return { fontWeight: 'bolder' }
                }

                return null
              }}
              gridOptions={this.props.gridOptions || {}}
              pinnedBottomRowData={
                Object.keys(this.props.statusBar ?? {}).length > 0 && haveTotals
                  ? [{ ...statusBarObject, is_total: true }]
                  : []
              }
              onRowDataUpdated={event => {
                if (this.props.enableAdvancedFilter) {
                  event.api.setGridOption(
                    'advancedFilterParent',
                    document.getElementById('advancedFilterParent'),
                  )
                }
                this.props.onReady(event)
              }}
              // Read only edit will set the table property to read only, all the edit's will not be affected
              readOnlyEdit={this.props.readOnlyEdit === undefined ? false : this.props.readOnlyEdit}
              // On cell edit request will be called when the date is changed and saved
              // in tbale, for this to work, we need to set readonlyedit to true and onCellEditRequest to a function
              // which will be called when the cell is edited [Need to update from out end , table wll not updated any data
              // as we set read only mode]
              onCellEditRequest={event => {
                this.setState({ currentNode: event }, () => {
                  this.props.onUpdateValue(event.data, event.colDef.field, event.newValue)
                })
              }}
              // Selection
              // checkboxSelection={
              //   this.props.checkboxSelection === undefined ? false : this.props.checkboxSelection
              // }
              isRowSelectable={this.props.isRowSelectable || null}
              onRowSelected={
                !this.props.rowDragEntireRow && this.props.onRowSelected
                  ? this.props.onRowSelected
                  : null
              }
              onSelectionChanged={
                !this.props.rowDragEntireRow && this.props.onSelectionChanged
                  ? this.props.onSelectionChanged
                  : null
              }
              rowMultiSelectWithClick={this.props.rowMultiSelectWithClick || false}
              enableRangeSelection={!this.props.rowDragEntireRow}
              // Range selection cannot be used with rowDragEntireRow
              // Ranges
              enableRangeHandle={!this.props.rowDragEntireRow}
              enableFillHandle={true}
              // rowSelection='multiple' lets you select multiple rows, if set to single, only one row can be selected
              rowSelection={
                this.props.rowSelectionType == undefined ? 'multiple' : this.props.rowSelectionType
              }
              // For undo redo cell editing, readOnlEdit should be set to false
              undoRedoCellEditing={true}
              undoRedoCellEditingLimit={5}
              tooltipMouseTrack={true}
              enableBrowserTooltips={false}
              // tooltipShowDelay will work on if browser tooltips are disabled
              tooltipShowDelay={500}
              // onCellKeyDown={console.log}
              // Modules are the additional features that can be added to the table
              enableAdvancedFilter={
                this.props.enableAdvancedFilter === undefined
                  ? false
                  : this.props.enableAdvancedFilter
              }
              includeHiddenColumnsInAdvancedFilter={true}
              // Row Dragging
              rowDragEntireRow={rowDragEntireRow}
              rowDragManaged={rowDragManaged}
              rowDragMultiRow={
                this.props.rowDragMultiRow == undefined ? true : this.props.rowDragMultiRow
              }
              onRowDragEnd={event => {
                if (!rowDragManaged) {
                  this.props.onCommitDrag(event)
                }
              }}
              rowModelType={'serverSide'}
              cacheBlockSize={50}
              maxBlocksInCache={2}
              // isServerSideGroupOpenByDefault={true}
              // serverSideEnableClientSideSort={true}
              getContextMenuItems={this.getContextMenuItems}
              modules={[
                ServerSideRowModelModule,
                ClipboardModule,
                MenuModule,
                RangeSelectionModule,
                RichSelectModule,
                ColumnsToolPanelModule,
                FiltersToolPanelModule,
                SetFilterModule,
                ExcelExportModule,
                AdvancedFilterModule,
                StatusBarModule,
              ]}
            />
          </div>

          {/* Need to work on column totals */}
          {/* {!this.state.hideFooter && Object.keys(this.props.statusBar ?? {}).length > 0 && (
            <div className={'my-2'} style={{ height: '60px' }}>
              <AgGridReact
                ref={this.footerGridRef}
                alignedGrids={[this.gridRef]}
                headerHeight="0"
                suppressVerticalScroll
                alwaysShowHorizontalScroll
                key={(this.props.gridKey || 'ag-grid-table') + 'footer'}
                serverSideDatasource={{
                  getRows: async params => {
                    setTimeout(() => {
                      params.success({
                        rowData: [statusBarObject],
                        rowCount: 1,
                      })
                    }, 500)
                  },
                }}
                // rowData={[statusBarObject]}
                rowStyle={{
                  alignItems: 'center',
                  fontWeight: 'bold',
                }}
                domLayout={this.props.domLayout}
                columnDefs={gridColumns}
                defaultColDef={{ ...defaultColDefs, autoHeaderHeight: false }}
                enableRangeSelection={!this.props.rowDragEntireRow}
                enableRangeHandle={!this.props.rowDragEntireRow}
                rowSelection={
                  this.props.rowSelectionType == undefined
                    ? 'multiple'
                    : this.props.rowSelectionType
                }
                tooltipMouseTrack={true}
                enableBrowserTooltips={false}
                tooltipShowDelay={500}
                rowModelType={'serverSide'}
                cacheBlockSize={50}
                maxBlocksInCache={2}
                modules={[
                  ServerSideRowModelModule,
                  ClipboardModule,
                  MenuModule,
                  RangeSelectionModule,
                  RichSelectModule,
                  ColumnsToolPanelModule,
                  FiltersToolPanelModule,
                  SetFilterModule,
                  ExcelExportModule,
                  AdvancedFilterModule,
                  StatusBarModule,
                ]}
              />
            </div>
          )} */}
        </div>
        {Object.keys(this.props.statusBar ?? {}).length > 0 && (
          <div className="flex justify-end mt-2">
            <Card bordered={true} size="small" className="bg-lgreen w-2/5 mt-4">
              <span className="font-size-18 font-weight-bold mb-2">Totals</span>
              <InfoIcon
                text={
                  <span>
                    <span>These totals change based on the selected filters and date ranges.</span>
                  </span>
                }
              />
              <div className="grid gap-x-4 mt-2">
                {Object.keys(this.props.statusBar).map((key, index) => (
                  <div key={index} className="grid grid-cols-12 items-center">
                    <div className="col-span-5 text-left">
                      <h6>
                        <span className="text-gray-500 text-lg">{key}:</span>
                      </h6>
                    </div>
                    <div className="col-span-7 text-right">
                      <h6>
                        <span className="text-gray-500 text-lg">
                          <span className="mr-1">
                            {this.props?.user?.selectedCompany?.currency_symbol}
                          </span>
                          {Math.round(this.props.statusBar[key]).toLocaleString(
                            'en-IN',
                            dotOptions,
                          )}
                        </span>
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        )}
      </div>
    )
  }
}
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    tax_rates: state.document.tax_rates,
    user: state.user,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReportTable)
