import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tabs,
  message,
} from 'antd'
import EnhancedInvoiceForm from 'components/forms/invoiceDetails'
import CopyToClipboard from 'react-copy-to-clipboard'
import GetPaymentDocument from 'components/other/GetPaymentDocument'
import PaymentModes from 'components/other/paymentModes'
import SectionHeader from 'components/other/sectionHeader'
import UploadFiles from 'components/other/uploadFiles'
import { arrayBufferToBase64, formItemLayout } from 'components/other/utility'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { getAPI, getAPIPDF } from 'services/jwt'
import { EnhancedMailFrom } from './mailForm'
import BetaBadge from 'components/badges/beta'
import AuditHistory from 'components/other/Activity'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { EmailStatus } from 'components/other/emailStatus'

const { TextArea } = Input
const { Option } = Select
const { TabPane } = Tabs

dayjs.extend(utc)

class EditPayInForm extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.paymentRef = React.createRef()
    this.state = {
      visible: false,
      allPayments: [],
      banks: [],
      loading: false,
      type: '',
      name: '',
      amount: '',
      bank_id: '',
      selectedData: {
        hash_id: '',
      },
      paymentData: '',
      paymentFlag: false,
      is_linked: true,
      is_paid_with_credits: false,
      attachments: [],
      payment_history: [],
      paymentDetails: [],
      activeKey: 'payment_details',
      isTouched: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  dataUpdate = data => {
    this.setState({ data: data })
  }

  onFinish = async values => {
    this.setState({ loading: true })
    const req = {
      ...values,
      payment_date: dayjs(values.payment_date).format(this.dateFormat),
      serial_number: this.state.selectedData.serial_number,
      attachments: this.state.attachments,
    }

    const data = await getAPI('payments', 'edit', req)
    this.setState({ loading: false })
    if (data) {
      message.success(data.message)
      this.setState({ visible: false, attachments: [], isTouched: false }, () =>
        this.props.onApicalled(),
      )
    } else this.setState({ loading: false, isTouched: false })
  }

  getPayment = async () => {
    const req = {
      serial_number: this.state.selectedData.serial_number,
    }

    const data = await getAPIPDF('payments', 'get_payment_receipt', req)
    if (data) {
      let base64Str = arrayBufferToBase64(data.data)
      this.setState(this.setState({ paymentFlag: true }), () => {
        var iframe = document.createElement('iframe')
        iframe.style.width = '100%'
        iframe.style.height = '842pt'
        iframe.src = 'data:application/pdf;base64,' + base64Str
        document.getElementById('paymentpdf').appendChild(iframe)
      })
    }
  }

  cancelPaymentReceiptModal = () => {
    this.setState({ paymentFlag: false })
  }

  handleDelete = async id => {
    const req = {
      serial_number: id,
    }

    Modal.confirm({
      title: 'Are you sure you want to delete ?',
      content: (
        <>
          <p className="font-size-18">
            {this.state.is_linked && (
              <span>
                This Payment is linked to multiple Documents
                <br />
              </span>
            )}
            {this.state.is_paid_with_credits && (
              <span>
                This payment is created while settling with Invoice/Purchase/Credit/Debit Note. This
                action will delete the payment entry for respective Invoice/Purchase/Credit/Debit
                Notes as well.
                <br />
              </span>
            )}
          </p>
        </>
      ),
      width: '30%',
      icon: <i className="fad fa-info-circle fa-beat mr-3 mt-2 text-danger"></i>,
      okText: 'Confirm',
      onOk: async () => {
        this.setState({ loading: true })
        const data = await getAPI('payments', 'cancel', req)

        if (data) {
          message.success(data.message)
          this.setState({ visible: false, loading: false }, () => this.props.onApicalled())
        } else this.setState({ loading: false })
      },
      onCancel: () => {
        // message.error('Please change/remove the records and try again')
      },
    })
  }
  get_payment_history = async serial_number => {
    const req = {
      serial_number,
    }
    this.setState({ loading: true, activeKey: 'comments_history' })
    const payment_history = await getAPI('payments', 'get_payment_history', req)

    this.setState({ payment_history: payment_history.audit_history, loading: false })
  }

  onAdd = async (data, values, document_type = 'invoice') => {
    const req = {
      serial_number: values.serial_number,
    }

    const data1 = await getAPI('payments', 'get_payment', req)
    this.setState({ activeKey: 'payment_details' }, () => {
      if (data1.success) {
        const is_linked =
          data1.payment_in_settled_invoices.length > 1 || data1.payment_out_settled_pos.length > 1
        this.setState(
          {
            visible: true,
            name: data1.customer_details.name,
            company_name: data1.customer_details.company_name,
            amount: data1.payment_details.amount,
            allPayments: data.payment_modes,
            banks: data.bank_details,
            type: data1.payment_details.payment_mode,
            selectedData: data1.payment_details,
            paymentData: data1,
            document_type: document_type,
            is_linked: document_type == 'expense' ? false : is_linked,
            is_paid_with_credits: data1.payment_details.payment_mode == 'Credits',
            attachments: data1.payment_details.attachments,
            paymentDetails: data1.payment_details,
            data: data,
          },
          () => {
            this.formRef.current.setFieldsValue({
              ...data1.payment_details,
              amount: data1.payment_details.amount,
              exclusive_notes: data1.payment_details.exclusive_notes,
              payment_date: dayjs(data1.payment_details.payment_date),
              bank_id:
                data1.payment_details.payment_mode == 'Cash'
                  ? data.bank_details.length > 0
                    ? data.bank_details[0].id
                    : ''
                  : data1.payment_details.bank_id,
            })
          },
        )
      }
    })
  }
  showDocumentDrawer = async (document_type, serial_number) => {
    if (document_type != 'expense' && document_type != 'indirect_income') {
      const data = await getAPI('utils', 'get_new_hash_id', {
        document_type,
        serial_number,
      })
      if (data.success) {
        this.invoiceForm.showDocumentDrawer(document_type, data.new_hash_id)
      }
    }
  }

  render() {
    const dropdownShareMenu = data => (
      <Menu>
        <Menu.Item>
          <a
            type="whatsapp-outline"
            size="small"
            className="font-weight-bold"
            style={{ height: '24px', borderRadius: '8px' }}
            onClick={event => {
              event.stopPropagation()
              window.open(
                'https://api.whatsapp.com/send/?phone=' +
                  this.state.paymentData.customer_details.dial_code +
                  this.state.paymentData.customer_details.phone +
                  '&text=Hello%20*' +
                  encodeURIComponent(this.state.paymentData.customer_details.name) +
                  '*%2C%20%0A%0A' +
                  encodeURIComponent(this.state.paymentData.company_details.whatsapp_line1) +
                  '%20%0A%0APlease%20find%20your%20payment%20receipt%20' +
                  this.state.paymentData.payment_details.serial_number +
                  '%20here%2C%20%0Ahttps://app.getswipe.in/view/' +
                  'payments/' +
                  this.state.paymentData.payment_details.hash_id +
                  '%0A%0A' +
                  encodeURIComponent(this.state.paymentData.company_details.whatsapp_line2) +
                  '%0A%0AThanks%0A*' +
                  encodeURIComponent(this.state.paymentData.company_details.organization_name) +
                  '*%0A*' +
                  this.state.paymentData.company_details.mobile +
                  '*%0A%0ASent%20using%20*Swipe%3A%20Smart%20Invoicing%2C%20Inventory%2C%20Accounting%20for%20SMEs*%20(getswipe.in)',
              )
            }}
          >
            <i className="fa-brands fa-whatsapp fa-fw fa-lg text-whatsapp mr-2" />
            <span className="">Whatsapp</span>
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            size="small"
            className="font-weight-bold"
            onClick={event => {
              this.mail.onShow(this.state.paymentData)
            }}
          >
            <i className="fa-duotone fa-envelope fa-lg fa-fw mr-2 text-primary" />{' '}
            <span className="">Email</span>
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <CopyToClipboard
            text={`https://swipe.pe/view/payments/${this.state.paymentData.payment_details.hash_id}`}
            onCopy={() => message.success('Copied')}
          >
            <a
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <i className="fa-regular fa-clone fa-lg mr-2 fa-fw" />
              <span className="float">Copy link</span>
            </a>
          </CopyToClipboard>
        </Menu.Item>
      </Menu>
    )
    const settled_invoice_columns = [
      {
        title: 'Date',
        render: (text, record) => {
          return <span>{dayjs(record.record_time).format('DD/MM/YYYY')}</span>
        },
      },
      {
        title: 'Serial Number',
        render: (text, record) => {
          return (
            <span
              className="font-weight-bold cursor-pointer"
              onClick={() => {
                record.document_type != '' &&
                  this.showDocumentDrawer(record.document_type, record.invoice_serial_number)
              }}
            >
              {record.invoice_serial_number}
            </span>
          )
        },
      },
      {
        title: 'Amount',
        render: (text, record) => {
          return <span>{record.amount_paid.toFixed(2)}</span>
        },
      },
    ]
    const setteled_pos_columns = [
      {
        title: 'Date',
        render: (text, record) => {
          return <span>{dayjs(record.record_time).format('DD/MM/YYYY')}</span>
        },
      },
      {
        title: 'Serial Number',
        render: (text, record) => {
          return (
            <span
              className="font-weight-bold cursor-pointer"
              onClick={() => {
                record.document_type != '' &&
                  this.showDocumentDrawer(record.document_type, record.po_serial_number)
              }}
            >
              {record.po_serial_number}
            </span>
          )
        },
      },
      {
        title: 'Amount',
        render: (text, record) => {
          return <span>{record.amount_paid.toFixed(2)}</span>
        },
      },
    ]
    return (
      <>
        <GetPaymentDocument ref={this.paymentRef} />
        <Drawer
          title={
            <span>
              <span className="lh-normal">
                <span className="font-weight-bolder mb-0">Payment # </span>
                <span className="font-weight-bolder text-muted">
                  {this.state.paymentData?.payment_details?.serial_number}
                </span>
                {/*{capitalizeFirstLetters(this.state.document_type)} #*/}
              </span>
              <p className="font-size-13 text-muted font-weight-medium mt-0 mb-0 lh-normal">
                <span>
                  {this.state.paymentData?.customer_details?.company_name == ''
                    ? this.state.paymentData?.customer_details?.name
                    : this.state.paymentData?.customer_details?.company_name}
                </span>

                {this.state.paymentData?.customer_details?.balance > 0 ? (
                  <span className="text-forest font-weight-bold ml-2">
                    {this.state.paymentData?.customer_details?.balance}
                  </span>
                ) : (
                  <span className="text-danger font-weight-bold ml-2">
                    {this.state.paymentData?.customer_details?.balance}
                  </span>
                )}
              </p>
            </span>
          }
          width={isMobile ? '100%' : '50%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          onClose={() => this.setState({ visible: false, attachments: [] })}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          open={this.state.visible}
          extra={
            this.state.activeKey == 'payment_details' && (
              <>
                {this.state.isTouched && (
                  <Button
                    className="mr-2"
                    onClick={() => this.formRef.current.submit()}
                    type="primary"
                    loading={this.state.loading}
                    disabled={
                      this.state.is_paid_with_credits || this.props.permissions.payment_edit == 0
                    }
                  >
                    Update Payment
                  </Button>
                )}

                {this.props.permissions.payment_delete == 1 && (
                  <Button
                    type="danger"
                    onClick={() => this.handleDelete(this.state.selectedData.serial_number)}
                  >
                    <i className="fa fa-trash" />
                  </Button>
                )}
              </>
            )
          }
          footer={
            this.state.activeKey == 'payment_details' && (
              <>
                {this.state.isTouched && (
                  <Button
                    className="mr-2"
                    onClick={() => this.formRef.current.submit()}
                    type="primary"
                    loading={this.state.loading}
                    disabled={
                      this.state.is_paid_with_credits || this.props.permissions.payment_edit == 0
                    }
                  >
                    Update Payment
                  </Button>
                )}
                {this.props.permissions.payment_delete == 1 && (
                  <Button
                    type="danger"
                    onClick={() => this.handleDelete(this.state.selectedData.serial_number)}
                  >
                    <i className="fa fa-trash mr-2" /> Delete
                  </Button>
                )}
              </>
            )
          }
        >
          <Tabs
            destroyInactiveTabPane={true}
            defaultActiveKey="payment_details"
            // key={this.state.activeKey}
            activeKey={this.state.activeKey}
            onChange={activeKey => {
              // this.setState({ activeKey: activeKey }, () => {
              if (activeKey == 'payment_details') {
                this.onAdd(this.state.data, this.state.selectedData, this.state.document_type)
              } else {
                this.get_payment_history(this.state.selectedData.serial_number)
              }
              // })
            }}
          >
            <TabPane tab={<span>Details </span>} key="payment_details">
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef}
                name="payInForm"
                onFinish={this.onFinish}
                onFieldsChange={() => this.setState({ isTouched: true })}
              >
                {this.state.paymentData != '' && (
                  <div className="mb-4">
                    <Button
                      className="mr-2"
                      size="small"
                      type="view-button"
                      onClick={() =>
                        this.paymentRef.current.showModal(this.state.selectedData.serial_number)
                      }
                    >
                      <i className="fa-solid fa-eye mr-2" />
                      View Receipt
                    </Button>

                    <Dropdown
                      overlay={dropdownShareMenu()}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button type="send-button" size="small" className="mr-2">
                        <i className="fa-solid fa-paper-plane" />
                        <span className="ml-1">Send</span>
                      </Button>
                    </Dropdown>
                  </div>
                )}
                <SectionHeader title={'Payment details'} right={false} />
                <Card bordered={false} size="drawer" className="border-radius-small mb-4">
                  <Row gutter={18}>
                    <Col span={24}>
                      {this.state.is_paid_with_credits && (
                        <span className="my-2 text-primary">
                          Note: This payment was created while settling the document with the
                          credit/debit note that was issued earlier. You cannot edit the payment
                          details, but you can delete the payment and record a new one instead.
                        </span>
                      )}
                      <Form.Item name="amount" label="Amount" className="mt-0 pt-0 mb-2">
                        <InputNumber
                          disabled
                          className="w-full font-weight-bold"
                          precision={2}
                          size="large"
                          prefix={
                            <span className="text-gray-500">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                          }
                          suffix={
                            <>
                              <span></span>
                            </>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="payment_date" label="Payment Date" className="pb-2">
                        <DatePicker
                          allowClear={false}
                          format={this.dateFormat}
                          className="w-full"
                          style={{
                            width: 'auto',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                          disabled={this.state.is_paid_with_credits}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="payment_mode"
                    className="pb-2"
                    label="Payment Type"
                    hidden={this.state.is_paid_with_credits}
                    extra={
                      this.state.paymentDetails.reconciliation_status != 'pending' &&
                      'Bank details cannot be edited once the payment is reconciled.'
                    }
                  >
                    {this.state.type != 'TDS' && this.state.type != 'TCS' ? (
                      <PaymentModes
                        data={
                          this.state.type != 'TDS'
                            ? this.state.allPayments.filter(
                                item => item.value != 'TDS' && item.value != 'TCS',
                              )
                            : this.state.allPayments
                        }
                        value={this.state.type}
                        onChange={e => this.setState({ type: e.target.value })}
                        disabled={this.state.paymentDetails.reconciliation_status != 'pending'}
                      />
                    ) : (
                      <p style={{ color: 'red' }}>
                        * For {this.state.type} payments, Payment type cannot be edited. In case of
                        any changes, kindly delete and record a new payment.
                      </p>
                    )}
                  </Form.Item>

                  {this.state.type != 'Cash' &&
                    this.state.type != 'TDS' &&
                    this.state.type != 'TCS' &&
                    !this.state.is_paid_with_credits && (
                      <Form.Item
                        name="bank_id"
                        className="pb-2"
                        label="Bank"
                        extra={
                          this.state.paymentDetails.reconciliation_status != 'pending' &&
                          'Bank details cannot be edited once the payment is reconciled.'
                        }
                      >
                        <Select
                          placeholder="Select Bank"
                          optionFilterProp="children"
                          style={{ width: '64' }}
                          onChange={e => this.setState({ bank_id: e })}
                          value={this.state.bank_id}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={this.state.paymentDetails.reconciliation_status != 'pending'}
                        >
                          {this.state.banks.map((item, i) => (
                            <Option key={i} value={item.id}>
                              {item.bank_name + ' '}{' '}
                              {item.bank_no != '' &&
                                item.bank_no != '-' &&
                                '(' + item.bank_no + ')'}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}

                  <Form.Item name="notes" label="Notes" className="pb-2">
                    <TextArea
                      placeholder="Notes Description"
                      disabled={this.state.is_paid_with_credits}
                    />
                  </Form.Item>

                  <UploadFiles
                    attachments={this.state.attachments}
                    maxCount={3}
                    multiple={true}
                    onChange={attachments => this.setState({ attachments, isTouched: true })}
                    setLoading={loading => this.setState({ loading })}
                  />
                </Card>

                {this.state.paymentData.payment_in_settled_invoices?.length > 0 && (
                  <>
                    <div className="mt-4">
                      <SectionHeader
                        title={
                          this.state.document_type == 'expense'
                            ? 'Settled Expenses'
                            : 'Settled Documents'
                        }
                        right={false}
                      />
                    </div>
                    <Table
                      size="small"
                      dataSource={this.state.paymentData.payment_in_settled_invoices}
                      columns={settled_invoice_columns}
                      pagination={
                        this.state.paymentData.payment_in_settled_invoices > 5
                          ? { size: 'small' }
                          : false
                      }
                    />
                  </>
                )}

                <Form.Item
                  name="exclusive_notes"
                  label={
                    <span>
                      <i className="fa-solid fa-fingerprint mr-2"></i>Internal Notes
                    </span>
                  }
                  className="mt-4 pb-2"
                  extra={
                    'This note is exclusively for internal reference and will not be shown elsewhere.'
                  }
                >
                  <TextArea placeholder="Enter notes here..." />
                </Form.Item>

                {/*<Card>
                  <Form.Item
                    name="exclusive_notes"
                    label="Internal Notes"
                    className="pb-2"
                    extra={
                      'This note is exclusively for internal reference and will not be shown elsewhere.'
                    }
                  >
                    <TextArea placeholder="Enter notes here..." />
                  </Form.Item>
                </Card>*/}

                {this.state.paymentData.payment_out_settled_pos?.length > 0 && (
                  <Table
                    dataSource={this.state.paymentData.payment_out_settled_pos}
                    columns={setteled_pos_columns}
                    // pagination={false}
                    pagination={{ size: 'small' }}
                  />
                )}

                {this.state.paymentDetails?.emails_status != undefined &&
                this.state.paymentDetails?.emails_status != -1 ? (
                  <>
                    <div className="mt-4">
                      <SectionHeader title={'Email Status'} right={false} />
                    </div>
                    <EmailStatus
                      emails_status={this.state.paymentDetails?.emails_status}
                      serial_number={this.state.selectedData.serial_number}
                      document_type={'payment'}
                      showStatusTable={true}
                    />
                  </>
                ) : (
                  ''
                )}

                {/*</Card>*/}

                {/* <div className="mt-4 pt-4">
              <Button
                className="mr-2"
                onClick={() => this.formRef.current.submit()}
                type="primary"
                loading={this.state.loading}
              >
                <i className="fa fa-edit mr-2" /> Update Payment
              </Button>


            </div> */}
              </Form>
            </TabPane>
            {this.props.permissions.history == 1 && (
              <TabPane
                tab={
                  <span>
                    Activity
                    <BetaBadge />
                  </span>
                }
                key="comments_history"
              >
                <Card>
                  {this.state.loading ? (
                    <p className="w-full flex justify-center my-5">
                      <LoadingOutlined style={{ fontSize: 35 }} className="text-primary" spin />
                    </p>
                  ) : (
                    <AuditHistory auditHistory={this.state.payment_history} />
                  )}
                </Card>
              </TabPane>
            )}
          </Tabs>

          <EnhancedMailFrom
            onRef={ref => (this.mail = ref)}
            document_type={'payments'}
            menuName={'payments'}
          />
          <EnhancedInvoiceForm onRef={ref => (this.invoiceForm = ref)} />
        </Drawer>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    user: state.user,
  }
}

export default connect(mapStateToProps)(EditPayInForm)
