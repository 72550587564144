import { LoadingOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Card,
  Collapse,
  Descriptions,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Modal,
  Pagination,
  Select,
  Steps,
  Table,
  Tag,
  message,
} from 'antd'
import ProductForm from 'components/forms/productForm'
import { getPrice, getPriceWithTax, getRowTotal } from 'components/other/calculation'
import { get_auto_complete_product_format } from 'components/other/utility'
import InventoryManager from 'managers/inventory'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { getAPI } from 'services/jwt'

const { Panel } = Collapse
export class BillOfMaterials extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      product: {},
      searchText: '',
      autoCompleteOpen: false,
      products: [],
      selected_category: 'All',
      page: 0,
      num_records: 10,
      product_search_loading: false,
      prevReq: {},
      bom_products: [],
      type: 'add',
      loading: false,
      current: 0,
      searchType: 'Product',
      selected_category: 'All',
      categories: [],
      product_page: 0,
      total_products: 0,
      selectedProduct: [],
      selectedRowKeys: [],
      search: '',
      products: [],
      product_loading: false,
      received_bom_products: [],
      searched_products: [],
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    // this.onProductSearch('')
    // this.callData(this.state.product_page, this.state.num_records)
  }
  async callData(page, records) {
    this.setState({ product_loading: true })
    const req = {
      num_records: records,
      page: page,
      query: this.state.search,
      category: this.state.selected_category,
      selected_price_list: 0,
    }
    const data = await getAPI('product', 'search', req)
    if (data) {
      let results = data.products.map((row, index) => {
        return {
          key: index,
          ...row,
        }
      })
      results = results.filter(item => item.product_id != this.state.product.product_id)
      results = get_auto_complete_product_format(results, this.props.document_settings)
      this.setState({
        products: results,
        product_page: page,
        num_records: records,
        total_products: data.total,
      })
    }
    this.setState({ product_loading: false })
  }
  onPaginationChange = current => {
    this.setState({ product_page: current - 1, products: [] }, () =>
      this.callData(this.state.product_page, this.state.num_records),
    )
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  addBOM = product => {
    if (product) {
      this.setState(
        {
          visible: true,
          product: product,
          type: product.has_bom ? 'edit' : 'add',
          autoCompleteOpen: false,
        },
        () => {
          this.getBOM()
        },
      )
    } else {
      this.onProductSearch('')
      this.setState({
        type: 'add',
        visible: true,
        product: {
          product_id: 0,
          variant_id: 0,
          product_name: '',
          name: '',
        },
        autoCompleteOpen: true,
      })
    }
  }

  doSearch = text => {
    text = text == undefined ? '' : text
    text = typeof text == 'string' ? text : text.target.value
    this.setState({
      searchText: text,
      autoCompleteOpen: text == '' ? false : true,
    })
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.onProductSearch(typeof text == 'string' ? text : text.target.value)
    }, 500)
  }

  onProductSearch = async (searchText, item) => {
    const req = {
      query: searchText,
      selected_price_list: '',
      page: 0,
      num_records: this.state.num_records,
      category: this.state.selected_category,
      products_for_sale: false,
      document_type: '',
    }
    this.setState({ product_search_loading: true })

    this.getProducts(req)
  }

  async getProducts(req) {
    const data = await getAPI('product', 'search', req)
    if (data) {
      let updatedProducts = data.products.map(value => {
        return {
          ...value,
          value:
            value.product_name +
            (value.variant_name == '' || value.variant_name == null
              ? ''
              : ' ' + value.variant_name),
          // id: value.product_id,
          id:
            String(value.product_id) +
            '_' +
            String(value.variant_id == undefined ? 0 : value.variant_id),
          price: value.price / value.conversion_rate,
          unit_price: value.price / value.conversion_rate,
          price_with_tax: value.price_with_tax / value.conversion_rate,
          qtyinstock: value.qty,
          qty: 1,
        }
      })

      updatedProducts = updatedProducts.filter(
        product => !product.has_bom && product.has_batches == 0,
      )
      updatedProducts = get_auto_complete_product_format(
        updatedProducts,
        this.props.document_settings,
      )
      this.setState(
        {
          searched_products: updatedProducts,
          product_search_loading: false,
          autoCompleteOpen: true,
        },
        () => {
          this.auto?.focus()
        },
      )
    }
  }

  handleAutoCompleteInputClick = event => {
    event.preventDefault()
    this.setState({ autoCompleteOpen: true })
  }

  findEndProductIndex = async () => {
    const req = {
      query: this.state.searchText,
      selected_price_list: '',
      page: this.state.page + 1,
      num_records: this.state.num_records,
      category: this.state.selected_category,
    }
    try {
      if (JSON.stringify(req) === JSON.stringify(this.state.prevReq)) {
        return
      } else {
        this.setState({ prevReq: req })
      }
    } catch (error) {
      console.log(error)
    }

    const data = await getAPI('product', 'search', req)
    if (data) {
      let allProducts = data.products.map(value => {
        return {
          ...value,
          value:
            value.product_name +
            (value.variant_name == '' || value.variant_name == null
              ? ''
              : ' ' + value.variant_name),
          id:
            String(value.product_id) +
            '_' +
            String(value.variant_id == undefined ? 0 : value.variant_id),
          price: value.price / value.conversion_rate,
          unit_price: value.price / value.conversion_rate,
          price_with_tax: value.price_with_tax / value.conversion_rate,
          qtyinstock: value.qty,
          qty: 1,
        }
      })
      allProducts = get_auto_complete_product_format(allProducts, this.props.document_settings)
      this.setState({
        searched_products: [...this.state.searched_products, ...allProducts],
        page: this.state.page + 1,
      })
    }
  }

  onBaseProductSelect = async (value, item) => {
    item = {
      ...item,
      name: item.variant_name,
    }

    this.setState({ searchText: '', visible: false, autoCompleteOpen: false }, () =>
      this.addBOM(item),
    )
  }

  onProductSelect = async (value, item) => {
    const index = this.state.bom_products.findIndex(product => product.id == item.id)
    if (index > -1) {
      message.error('Product already added')
      this.setState({
        autoCompleteOpen: false,
      })
      return
    }

    if (
      this.state.product.product_id == item.product_id &&
      this.state.product.variant_id == item.variant_id
    ) {
      message.error('You cannot add a product to its own group')
      this.setState({
        autoCompleteOpen: false,
      })
      return
    }
    this.setState({
      autoCompleteOpen: false,
      bom_products: [...this.state.bom_products, item],
      searchText: '',
    })
  }

  onChange = (value, item, type) => {
    const index = this.state.bom_products.findIndex(product => product.id == item.id)
    if (index > -1) {
      let dataSource = [...this.state.bom_products]
      if (type == 'qty') {
        dataSource[index].qty = value
      } else if (type == 'price') {
        dataSource[index].price = value
        dataSource[index].unit_price = value
        dataSource[index].price_with_tax = getPriceWithTax(dataSource[index])
      } else if (type == 'price_with_tax') {
        dataSource[index].price_with_tax = value
        dataSource[index].price = getPrice(dataSource[index])
        dataSource[index].unit_price = dataSource[index].price
      } else if (type == 'unit_id') {
        let prev_conversion_rate = dataSource[index]['conversion_rate']
        dataSource[index]['unit_id'] = value
        if (dataSource[index]['unit_id'] == 0 || dataSource[index]['unit_id'] == null) {
          dataSource[index]['conversion_rate'] = 1
        } else {
          dataSource[index]['conversion_rate'] = dataSource[index]['units']
            ? dataSource[index]['units'].find(item => item.unit_id == value)?.conversion_rate
            : 1
        }
        dataSource[index]['price'] =
          (dataSource[index]['price'] * prev_conversion_rate) / dataSource[index]['conversion_rate']
        dataSource[index]['unit_price'] = dataSource[index]['price']
        dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])

        dataSource[index]['unit'] =
          dataSource[index]['unit'] == '' || dataSource[index]['unit'] == null
            ? ''
            : dataSource[index]['units']
            ? dataSource[index]['units'].filter(
                item => item.unit_id == dataSource[index]['unit_id'],
              )?.[0].alternative_unit
            : dataSource[index]['unit']
      }

      this.setState({
        bom_products: dataSource,
      })
    }
  }

  onRemoveBomProduct = item => {
    const index = this.state.selectedProduct.findIndex(product => product.id == item.id)
    const selectedKeys = this.state.selectedRowKeys.filter(id => id != item.id)
    const new_received_products = this.state.received_bom_products?.filter(
      product => product.id != item.id,
    )
    if (index > -1) {
      let dataSource = [...this.state.selectedProduct]
      dataSource.splice(index, 1)
      this.setState({
        received_bom_products: new_received_products,
        bom_products: dataSource,
        selectedProduct: dataSource,
        selectedRowKeys: selectedKeys,
      })
    }
  }

  onRemoveAll = () => {
    this.setState({
      bom_products: [],
    })
  }

  onAddNew = async item => {
    const { bom_products } = this.state
    let dataSource = [...bom_products]
    item.id = String(item.product_id) + '_0'
    const index = bom_products.findIndex(product => product.id == item.id)
    var newData = {
      ...item,
      qty: 1,
      value: item.product_name,
      tax: item.tax,
      qtyinstock: item.qty,
      product_name: item.product_name,
    }
    if (index == -1) {
      newData.qty = 1
      dataSource.push(newData)
    } else {
      newData.qty = bom_products[index].qty
      dataSource[index] = { ...bom_products[index], ...newData }
    }
    this.setState({
      bom_products: dataSource,
    })
  }

  getBOM = async () => {
    const products = await InventoryManager.getBOM(
      this.state.product.product_id,
      this.state.product.variant_id ? this.state.product.variant_id : 0,
    )
    const selectedRowKeys = products.map(product => product.id)
    this.setState({
      bom_products: products,
      received_bom_products: products,
      current: products.length == 0 ? 1 : 2,
      searchText: this.state.product.product_name,
      selectedProduct: products,
      selectedRowKeys: selectedRowKeys,
    })

    if (products.lenth > 0) {
      this.doSearch('')
      this.auto.focus()
      this.callData(this.state.product_page, this.state.num_records)
    }
  }

  onFinishBOM = async () => {
    this.setState({ loading: true })
    const req = {
      base_product_id: this.state.product.product_id,
      base_variant_id: this.state.product.variant_id ? this.state.product.variant_id : 0,
      bom_products: this.state.selectedProduct.map(item => {
        return {
          bom_id: this.state.type == 'edit' ? item.bom_id : undefined,
          product_id: item.product_id,
          variant_id: item.variant_id ? item.variant_id : 0,
          qty: item.qty,
          unit_price: item.unit_price,
          price_with_tax: item.price_with_tax,
          unit_id: item.unit_id,
          total_amount: getRowTotal(item),
        }
      }),
    }

    const route = this.state.type == 'edit' ? 'edit_bom' : 'add_bom'
    const data = await getAPI('product', route, req)
    if (data.success) {
      message.success(data.message)
      this.setState(
        {
          searchText: '',
          bom_products: [],
          received_bom_products: [],
          autoCompleteOpen: false,
          selectedProduct: [],
          visible: false,
          selectedRowKeys: [],
          current: 0,
        },
        () => this.props.onAPICalled(),
      )
    }
    this.setState({ loading: false })
  }
  onNext = () => {
    if (this.state.product.product_name == '' || this.state.searchText == '') {
      message.error('Select a product')
      return
    }
    this.setState({ current: this.state.current + 1 })
  }
  onPrevious = () => {
    if (this.state.products.length == 0) {
      this.callData(this.state.product_page, this.state.num_records)
    }
    this.setState({ current: this.state.current - 1 })
  }
  onSelectChange = selectedRowKeys => {
    const allProducts = Array.from(new Set([...this.state.selectedProduct, ...this.state.products]))
    let selected_products_keys = []
    let selectedProducts = allProducts.filter(product => {
      let filter_condition =
        selectedRowKeys.includes(product.id) && !selected_products_keys.includes(product.id)
      selected_products_keys.push(product.id)
      return filter_condition
    })
    selectedProducts = selectedProducts.map(product => {
      return {
        ...product,
        qty: 1,
      }
    })
    const alreadyAddedProducts = selectedProducts.filter(product =>
      this.state.received_bom_products.some(bomProduct => bomProduct.id === product.id),
    )
    const selectingBaseProduct = selectedProducts.filter(
      product => this.state.product.id == product.id,
    )
    if (selectingBaseProduct.length > 0) {
      message.error('Base Product cannnot be added as sub product')
      return
    }
    // if (alreadyAddedProducts.length > 0) {
    //   // Show error message for products already added to BOM
    //   message.error('Product already added')
    //   return
    // }
    this.setState({
      selectedProduct: selectedProducts,
      selectedRowKeys,
    })
  }
  searchProducts = e => {
    this.setState({ search: e.target.value, product_page: 0 }, () => {
      this.callData(this.state.product_page, this.state.num_records)
    })
  }
  onSelectAllClick = () => {
    const allKeys = this.state.products.map(product => product.id)
    const selectedRowKeys = allKeys.filter(key => !this.state.selectedRowKeys.includes(key))
    const selectedProduct = this.state.products.filter(product =>
      selectedRowKeys.includes(product.id),
    )
    this.setState({
      selectedRowKeys: Array.from(new Set([...this.state.selectedRowKeys, ...selectedRowKeys])),
      selectedProduct: Array.from(new Set([...this.state.selectedProduct, ...selectedProduct])),
    })
  }
  onSelectNoneClick = () => {
    this.setState({ selectedRowKeys: [], selectedProduct: [] })
  }
  onItemSelect = (data, option) => {
    if (this.state.type == 'edit') {
      this.addBOM(option)
    }
    this.setState({
      searchText:
        option.variant_id != 0
          ? `${option.product_name} ${option.variant_name}`
          : option.product_name,
      autoCompleteOpen: false,
      base_product: option,
      product: option,
    })
  }

  render() {
    const categories = this.props.product_categories
    const handleScroll = e => {
      const bottom =
        parseInt(e.target.scrollHeight - e.target.scrollTop) - parseInt(e.target.clientHeight) <= 1

      if (bottom) {
        this.findEndProductIndex()
      }
    }
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const selectUnits = data => (
      <span className="select-borderless">
        <Select
          suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
          placeholder="Unit"
          optionFilterProp="children"
          style={{ maxWidth: '90px' }}
          dropdownStyle={{ minWidth: '300px' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={data.units?.filter(item => item.unit_id == data?.unit_id)?.[0].unit_id}
          value={data?.unit_id == 0 || data?.unit_id == null ? 0 : data?.unit_id}
          onChange={value => {
            this.onChange(value, data, 'unit_id')
          }}
          // disabled={}
        >
          {data.units?.map((e, i) => (
            <Option key={i} value={e.unit_id}>
              {e.alternative_unit}{' '}
              {data.unit != '' && e.primary_unit == undefined
                ? ` - (1 ${data.units?.[0].alternative_unit} = ${e.conversion_rate} ${e.alternative_unit})`
                : ''}
            </Option>
          ))}
        </Select>
      </span>
    )

    let columns = [
      {
        title: 'Sub-product',
        dataIndex: 'product_name',
        key: 'product_name',
        width: '20%',
        render: (text, record) => (
          <span>
            {record.product_name}{' '}
            {record.variant_name == '' || record.variant_name == null
              ? ''
              : ' ' + record.variant_name}
          </span>
        ),
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        align: '',
        width: '20%',
        render: (text, record) => (
          <InputNumber
            min={0}
            defaultValue={record.qty}
            value={record.qty}
            className="w-full"
            onChange={value => this.onChange(value, record, 'qty')}
            addonAfter={record.has_alternative_units == 0 ? record.unit : selectUnits(record)}
          />
        ),
      },
      {
        title: 'Price',
        dataIndex: 'unit_price',
        key: 'unit_price',
        width: '13%',
        render: (text, record) => (
          <InputNumber
            min={0}
            className="w-full"
            defaultValue={record.unit_price}
            value={record.unit_price}
            onChange={value => this.onChange(value, record, 'price')}
          />
        ),
        hidden: true,
      },
      {
        title: 'Price with Tax',
        dataIndex: 'price_with_tax',
        key: 'price_with_tax',
        width: '12%',
        render: (text, record) => (
          <InputNumber
            min={0}
            className="w-full"
            defaultValue={record.price_with_tax}
            value={record.price_with_tax}
            onChange={value => this.onChange(value, record, 'price_with_tax')}
          />
        ),
        hidden: true,
      },
      {
        title: 'Total Amount',
        dataIndex: 'total_amount',
        key: 'total_amount',
        width: '20%',
        render: (text, record) => (
          <span className="font-weight-bold font-size-18 text-center">
            <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>
            {parseInt(String(record.id).split('_')[0]) > -1
              ? getRowTotal(record).toLocaleString('en-IN', dotOptions)
              : 0}
          </span>
        ),
        hidden: true,
      },

      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: '15%',
        render: (text, record) => (
          <>
            <Button
              type="warning"
              size="small"
              onClick={event => {
                event.stopPropagation()
                this.form.onFill(record)
              }}
              className="mr-2"
            >
              <i className="fa-solid fa-edit mr-1" />
              <span className="hidden sm:inline font-weight-bold">
                <FormattedMessage id="button.edit" />
              </span>
            </Button>
            <span>
              <a
                className="text-danger"
                onClick={event => {
                  event.stopPropagation()
                  this.onRemoveBomProduct(record)
                }}
              >
                <i className="fa-solid fa-trash fa-fw mr-1" />
                <span className="font-weight-bold">Remove </span>
              </a>
            </span>
          </>
        ),
      },
    ]

    columns = columns.filter(item => !item.hidden)
    const headerMenu = (
      <Menu>
        <Menu.Item key="selectAll" onClick={() => this.onSelectAllClick()}>
          Select All Data
        </Menu.Item>
        <Menu.Item key="selectNone" onClick={() => this.onSelectNoneClick()}>
          Clear All Data
        </Menu.Item>
      </Menu>
    )
    const selectProducts = [
      {
        title: (
          <>
            <Dropdown overlay={headerMenu}>
              <span>
                <i className="fa fa-chevron-down mr-2" />
              </span>
            </Dropdown>
            {'  '}
            Product
          </>
        ),
        key: 'product_id',
        render: text => (
          <a className="pl-4" href="#" onClick={e => e.preventDefault()}>
            {text.variant_name == null
              ? text.product_name
              : text.product_name + ' ' + text.variant_name}
          </a>
        ),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'product_id',
        render: text => (
          <a className="" href="#" onClick={e => e.preventDefault()}>
            <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>
            {text.toFixed(2)}
          </a>
        ),
      },
      {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'product_id',
        render: text => (
          <a className="font-weight-bold" href="#" onClick={e => e.preventDefault()}>
            {text}
          </a>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'product_category',
        key: 'product_id',
        render: text => (
          <a className="" href="#" onClick={e => e.preventDefault()}>
            {text}
          </a>
        ),
      },
    ]
    const steps = [
      {
        title: 'Select Main Product',
      },
      {
        title: 'Select Sub Products',
      },
      {
        title: 'Edit Qty of Sub Products',
      },
    ]
    const rowSelection = {
      preserveSelectedRowKeys: true,
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    }

    return (
      <>
        <Modal
          title={
            <span>
              <span className="lh-normal">
                <span className="font-weight-bolder mb-0">
                  {this.state.product.has_bom ? 'Edit ' : 'Create '}
                  Group{' '}
                </span>
              </span>
              <p className="font-size-13 font-weight-medium mt-0 mb-0 lh-normal">
                {this.state.product.product_name ? this.state.product.product_name : ''}
              </p>
            </span>
          }
          width={'90vw'}
          destroyOnClose
          maskClosable={false}
          centered
          open={this.state.visible}
          closable={true}
          closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
          onCancel={() =>
            this.setState({
              visible: false,
              bom_products: [],
              selectedRowKeys: [],
              received_bom_products: [],
              current: 0,
              searchText: '',
              product: {},
            })
          }
          bodyStyle={{
            height: '80vh',
            overflowY: 'auto',
          }}
          // extra={

          //   this.state.product.product_id != 0 && (
          //     <Space>
          //       <Button
          //         onClick={() => this.onFinishBOM()}
          //         type="primary"
          //         className="font-weight-bold arrow-transition"
          //         loading={this.state.loading}
          //       >
          //         Save & Close
          //         <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
          //       </Button>
          //     </Space>
          //   )
          // }
          footer={
            <>
              {this.state.current == 1 && (
                <div className="w-full flex justify-end">
                  <Button
                    type="action"
                    size="medium"
                    className="mt-2 mr-2 arrow-transition-left"
                    onClick={e => {
                      this.onPrevious()
                    }}
                  >
                    <i className="fa-solid fa-arrow-left arrow-transition-left mr-2"></i>
                    Previous
                  </Button>
                  <Button
                    type="action"
                    size="medium"
                    className="mt-2 arrow-transition"
                    onClick={e => {
                      if (this.state.selectedProduct.length == 0) {
                        message.error('No products selected')
                        return
                      }
                      this.onNext()
                      this.setState({
                        bom_products: [
                          ...this.state.received_bom_products,
                          ...this.state.selectedProduct,
                        ],
                      })
                    }}
                  >
                    Edit Products
                    <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                  </Button>
                </div>
              )}
              {this.state.current == 2 && (
                <>
                  <div className="w-full flex justify-end">
                    <Button
                      type="action"
                      size="medium"
                      className="mt-2 mr-2 arrow-transition-left"
                      onClick={e => {
                        this.onPrevious()
                      }}
                    >
                      <i className="fa-solid fa-arrow-left arrow-transition-left mr-2"></i>
                      Previous
                    </Button>
                    <Button
                      type="action"
                      size="medium"
                      className="mt-2 arrow-transition"
                      onClick={e => {
                        // this.onNext()
                        this.onFinishBOM()
                      }}
                    >
                      Submit
                      <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                    </Button>
                  </div>
                </>
              )}
            </>
          }
        >
          {this.state.current == 1 && (
            <div className="w-full flex mb-4">
              <Button
                type="action"
                size="small"
                className="mt-2 mr-2 arrow-transition-left"
                onClick={e => {
                  this.onPrevious()
                }}
              >
                <i className="fa-solid fa-arrow-left arrow-transition-left mr-2"></i>
                Previous
              </Button>
              <Button
                type="action"
                size="small"
                className="mt-2 arrow-transition"
                onClick={e => {
                  if (this.state.selectedProduct.length == 0) {
                    message.error('No products selected')
                    return
                  }
                  this.onNext()
                  this.setState({
                    bom_products: [
                      ...this.state.received_bom_products,
                      ...this.state.selectedProduct,
                    ],
                  })
                }}
              >
                Edit Products
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </div>
          )}
          {this.state.current == 2 && (
            <div className="w-full flex mb-2">
              <Button
                type="action"
                size="small"
                className="mt-2 mr-2 arrow-transition-left"
                onClick={e => {
                  this.onPrevious()
                }}
              >
                <i className="fa-solid fa-arrow-left arrow-transition-left mr-2"></i>
                Previous
              </Button>
              <Button
                type="action"
                size="small"
                className="mt-2 arrow-transition"
                onClick={e => {
                  // this.onNext()
                  this.onFinishBOM()
                }}
              >
                Submit
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </div>
          )}
          {/* <Card> */}
          <Steps items={steps} current={this.state.current} size="small" className="font-size-12" />
          {/* </Card> */}

          {this.state.current == 1 && (
            <>
              <div className="w-full mt-5">
                <Descriptions bordered size="small" column={1}>
                  <Descriptions.Item label="Main Product">
                    {this.state.product.product_name}
                  </Descriptions.Item>
                </Descriptions>
              </div>

              <div className="inline-flex items-center float-left mb-2 w-full mt-3">
                <Select
                  showSearch
                  allowClear={true}
                  style={{ width: 200, fontSize: '12px' }}
                  placeholder="Select Category"
                  optionFilterProp="children"
                  className="mr-2"
                  onChange={e => {
                    this.setState({ selected_category: e }, () =>
                      this.callData(this.state.page, this.state.num_records),
                    )
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  listHeight={200}
                  dropdownStyle={{ minWidth: '300px' }}
                >
                  <Option value={'All'} key={0}>
                    {'All'}
                  </Option>
                  {categories?.map(item => (
                    <Option value={item.category_name} key={item.category_name}>
                      {item.category_name}
                    </Option>
                  ))}
                </Select>

                <Input
                  type="text"
                  className="mr-2"
                  placeholder="Search Product"
                  onChange={this.searchProducts}
                />
                <div className="flex w-full justify-end">
                  {/* <Button type='' className="font-bolder">
                    <span className="font-weight-bold">
                      {this.state.selectedRowKeys.length} products selected
                    </span>
                  </Button> */}
                  <Tag color="blue" className="p-2">
                    {this.state.selectedRowKeys.length} products selected
                  </Tag>
                </div>
              </div>
              <Table
                className="bulk-edit-table mt-2"
                columns={selectProducts}
                dataSource={this.state.products}
                pagination={false}
                size="small"
                scroll={{ y: 480 }}
                id="style-8"
                rowKey="id"
                loading={{
                  spinning: this.state.product_loading,
                  indicator: (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                      }}
                      spin
                    />
                  ),
                }}
                rowSelection={rowSelection}
              />
              <div className="inline-flex items-center float-right mt-3 mb-5">
                <Pagination
                  size="small"
                  showSizeChanger={false}
                  pageSize={this.state.num_records}
                  total={this.state.total_products}
                  onChange={this.onPaginationChange}
                  current={this.state.product_page + 1}
                />
                <Select
                  optionFilterProp="children"
                  className="mr-2"
                  defaultValue={this.state.num_records}
                  onChange={value => this.callData(this.state.product_page, value)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[10, 20, 50, 100].map((e, i) => (
                    <Option key={i} value={e}>
                      {e}
                    </Option>
                  ))}
                </Select>
              </div>
            </>
          )}
          {this.state.current == 2 && (
            <Table
              style={{ overflowX: 'auto' }}
              columns={columns}
              dataSource={this.state.selectedProduct}
              pagination={{
                pageSize: 10,
              }}
              size="small"
              rowKey="product_id"
              className="mt-5"
            />
          )}

          {this.state.current == 0 && (
            <>
              <div className="mt-5 flex justify-center w-full">
                <Card>
                  <div className="mb-3 font-size-16">Select Main Product</div>
                  <AutoComplete
                    options={this.state.searched_products}
                    className="mr-2 pb-2"
                    autoFocus
                    style={{ width: '640px', height: '32px' }}
                    popupMatchSelectWidth={640}
                    dropdownMenuStyle={{ top: 18 }}
                    trigger="hover"
                    defaultOpen
                    dropdownRender={menu => (
                      <>
                        {menu}
                        <div style={{ position: 'sticky', bottom: 0 }} className="w-full">
                          <div className="ant-select-item ant-select-item-option auto-add-btn p-2 w-full">
                            <Button
                              block
                              size="large"
                              type="primary-light"
                              className="font-weight-bolder"
                              onClick={() => this.form.onAdd()}
                            >
                              <i className="fa-solid fa-circle-plus mr-2" /> Add New Product
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    notFoundContent={
                      <>
                        <div className="px-4 py-2 text-gray-500 font-weight">
                          <span className="font-weight-bold">No search results found</span>
                          <p className="mb-0">Please try again with a different search query or </p>
                        </div>
                      </>
                    }
                    value={this.state.searchText}
                    onSearch={this.doSearch}
                    onSelect={this.onItemSelect}
                    placeholder={`Search Products`}
                    prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
                    onPopupScroll={handleScroll}
                  ></AutoComplete>
                  {this.state.product.product_id != 0 && (
                    <div className="text-gray-400 mt-2">
                      *Now select sub products to create a group
                    </div>
                  )}
                </Card>
              </div>
            </>
          )}

          {this.state.current == 0 && (
            <div className="position-fixed" style={{ bottom: 70, width: '85vw' }}>
              <div className="flex justify-end mb-4 w-full">
                <Button
                  type="action"
                  size="medium"
                  className="mt-2 arrow-transition mr-3"
                  onClick={e => {
                    this.callData(this.state.product_page, this.state.num_records)
                    this.onNext()
                  }}
                >
                  Select Sub Products
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>
              </div>

              <div>
                <Collapse
                  defaultActiveKey={['1']}
                  accordion
                  className="mb-2 mr-3"
                  style={{ background: 'transparent' }}
                >
                  <Panel
                    header={
                      <span className="font-weight-bold " style={{ background: 'transparent' }}>
                        Instructions
                      </span>
                    }
                    key="1"
                  >
                    <div className="px-3">
                      <ul list-style-type="disc">
                        <li className="font-weight-bold">
                          Grouping simplifies spare parts management. It allows for easy
                          identification of the components, facilitating efficient inventory
                          management and replacement of parts when needed
                        </li>
                        {this.state.product.variant_id == 0 && (
                          <li className="text-danger">
                            If Your Product has a variants, Add the variants first and then add the
                            Bill of Materials for the variants.
                          </li>
                        )}

                        <li className="text-gray-600 font-size-15">
                          Search for products and add them to the list. You can add multiple
                          products to the list.
                        </li>
                        <li>
                          Enter the quantity for the product in the list which will be used to
                        </li>
                        <li className="text-gray-600 font-size-15">
                          Entered Quantity for every product in the list will be added or removed
                          from the inventory when you did stock out or stock in respectively.
                        </li>

                        <li className="text-gray-600 font-size-15">
                          You cannot delete a product if it is there in the groups list.
                        </li>
                        <li className="text-gray-600 font-size-15">
                          You cannot add a variants if it is there in the groups list.
                        </li>
                      </ul>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
          )}
          <ProductForm
            onRef={ref => (this.form = ref)}
            onAPICalled={newData => this.onAddNew(newData)}
          />
        </Modal>
      </>
    )
  }
}
function mapStateToProps(state, ownProps) {
  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    invoices_created: state.welcome.invoices_created,
    permissions: state.permissions.permission,
    document: state.document,
    product_categories: state.document.categories,
    document_settings: state.document.invoiceSettings,
    user: state.user,
  }
}

export default connect(mapStateToProps)(BillOfMaterials)
