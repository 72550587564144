import {
  Button,
  Card,
  Divider,
  Drawer,
  Modal,
  Spin,
  Timeline,
  List,
  Tag,
  Typography,
  Popover,
  Collapse,
  Row,
  Col,
  Table,
  Affix,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { UserAvatar } from 'components/other/utility'
import { MyDocument } from 'pages/doc/view'
import React, { useEffect, useState } from 'react'
import { getAPI } from 'services/jwt'
import { useSelector } from 'react-redux'
import store from 'store'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import Visibility from 'components/other/visibility'
const { Text, Title } = Typography
const { Panel } = Collapse

export const CommentsAndHistory = ({ document_type, doc_count, new_hash_id, menu_name }) => {
  // console.log(document_type, doc_count, new_hash_id)
  const [timelineData, setTimelineData] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [moreDetailsView, setMoreDetailsView] = useState(false)
  const [invoiceData, setInvoiceData] = useState('')
  const [type, setType] = useState(['ORIGINAL FOR RECIPIENT'])
  const [show_both_currencies, setShowBothCurrencies] = useState(false)
  const [historyAllowed, setHistoryAllowed] = useState(false)
  const user = useSelector(state => state.user)

  const subscriptionPlanRef = React.createRef()

  const getInvoice = async (new_hash_id, version) => {
    const req = {
      new_hash_id: new_hash_id,
      document_type: document_type,
      is_pdf: true,
      version: version,
    }

    const data = await getAPI('v2/doc', 'get_invoice', req)
    if (data.success) {
      setInvoiceData(data.invoice_details)
      setType(data.invoice_details.invoice_settings.labels.original_for_recipient)
      setShowBothCurrencies(data.invoice_details.invoice_settings.show_both_currencies == 1)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAPI('v2/doc', 'activity', { document_type, doc_count })

        if (response.allow_history) {
          let activity = response.activity
          activity = activity.filter(item => item.desc.change_log !== '' || item.order !== 1)

          setTimelineData(activity)
          setHistoryAllowed(true)
        } else {
          setTimelineData([])
          setHistoryAllowed(false)
        }
      } catch (error) {
        console.error('Error fetching timeline data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [document_type, doc_count])

  const getColor = action => {
    switch (action) {
      case 'added':
        return '#dff0d8' // light green
      case 'removed':
        return '#f2dede' // light red
      case 'modified':
        return '#fcf8e3' // light yellow
      default:
        return '#fff' // default white
    }
  }

  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <Card className="px-4">
        {historyAllowed ? (
          <>
            <Timeline className="mt-4">
              {timelineData.map((activity, index) => (
                <>
                  {(activity.desc.change_log !== '' || activity.order !== 1) && (
                    <Timeline.Item
                      className="mb-1 pb-1"
                      key={index}
                      dot={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <UserAvatar name={activity.user.name} />
                        </div>
                      }
                    >
                      <>
                        <div
                          key={index}
                          className="ml-1"
                          style={
                            store.get('paid') == 0 && index > 1
                              ? {
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  filter: 'blur(5px)',
                                }
                              : {
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }
                          }
                        >
                          <div>
                            <span className="mb-1 font-weight-medium">{activity.text}</span>
                            {/* {activity.order === 1 && (
                            <Button
                              type="view-button"
                              size="small"
                              onClick={event => {
                                event.stopPropagation()
                                getInvoice(new_hash_id, activity.version)
                              }}
                              className="mx-2 font-size-12"
                            >
                              <i className="fa-regular fa-eye" />
                              <span className="ml-1 hidden d-xl-inline">
                                <FormattedMessage id="button.view" />
                              </span>
                            </Button>
                          )} */}

                            <p className="mb-1 text-gray-500 font-size-14">
                              {activity.serial_number != '' && (
                                <span className=""># {activity.serial_number}</span>
                              )}
                              {activity.total_amount != 0 && <>, </>}
                              {activity.total_amount != 0 && (
                                <span className="">
                                  <span className="text-gray-500 font-size-10 mr-1">
                                    {user?.selectedCompany?.currency_symbol}
                                  </span>
                                  {activity.total_amount.toLocaleString('en-IN', options)}
                                </span>
                              )}
                            </p>

                            {activity.desc.change_log != '' && (
                              <p className="text-gray-500 font-size-14 mb-1">
                                {activity.desc.change_log != 'Document created' && (
                                  <span className="text-gray-400">Changes in:</span>
                                )}{' '}
                                {activity.desc.change_log}
                              </p>
                            )}
                          </div>
                          <div>
                            <p className="font-size-14 text-gray-500 mb-1">
                              {activity.display_time}
                            </p>

                            {activity.order == 1 && Object.keys(activity.changed_keys).length > 0 && (
                              <a
                                className="text-gray-500 font-size-12"
                                onClick={() => {
                                  if (store.get('paid') == 0) {
                                    subscriptionPlanRef.current.openModal('activity')
                                    return
                                  }
                                  setSelectedActivity(activity)
                                  setMoreDetailsView(true)
                                }}
                              >
                                Show More
                              </a>
                            )}
                          </div>
                        </div>
                        {index !== timelineData.length - 1 && <Divider className="mt-2 mb-0" />}
                      </>
                    </Timeline.Item>
                  )}
                </>
              ))}
            </Timeline>
            {store.get('paid') == 0 && (
              <Affix offsetBottom={30}>
                <Visibility type="activity" className="mb-4" visible="true" />
              </Affix>
            )}
          </>
        ) : (
          <>
            <div className="text-center">
              <i className="fa-solid fa-lg fa-info-circle text-gray-400"></i>
              <p className="text-gray-400 mt-2">
                {loading
                  ? 'Fetching History...'
                  : 'Edit history is only available for the documents created after Feb 1st 2024.'}
              </p>
            </div>
          </>
        )}
      </Card>
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
      {invoiceData != '' && (
        <Modal
          title={<span className="text-gray-700">Preview</span>}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          open={invoiceData != ''}
          style={{ top: 18 }}
          width={1080}
          bodyStyle={{ height: '100vh' }}
          onOk={() => setInvoiceData('')}
          onCancel={() => setInvoiceData('')}
          footer={null}
        >
          <MyDocument
            data={invoiceData}
            menuName={menu_name}
            type={type}
            myKey={Math.random()}
            flag={false}
            width={720}
            show_both_currencies={show_both_currencies}
          />
        </Modal>
      )}

      <Drawer
        title="Activity Details"
        placement="right"
        closable={true}
        onClose={() => setMoreDetailsView(false)}
        open={moreDetailsView}
        width={'100%'}
        style={{ overflowY: 'auto' }}
      >
        <div className="px-4">
          {/* two columns for the current and previous activity */}
          <div className="flex ">
            <div className="col-12">
              <Card>
                <div className="flex justify-between">
                  <div>
                    <h4 className="font-weight-medium">Changes</h4>
                    <p className="text-gray-500 font-size-14">{selectedActivity?.text}</p>
                  </div>
                  <p className="text-gray-500 font-size-14">{selectedActivity?.display_time}</p>
                </div>

                <div className="mt-3">
                  <ListCollapse
                    changes={selectedActivity?.changed_keys?.document_keys}
                    label="Basic Details"
                    key={'1'}
                  />
                  <MultiListCollapse
                    changes={selectedActivity?.changed_keys?.custom_header_keys}
                    label="Custom Headers"
                    key={'2'}
                  />
                  <MultiListCollapse
                    changes={selectedActivity?.changed_keys?.export_invoice_keys}
                    label="Export Details"
                    key={'3'}
                  />
                  <MultiListCollapse
                    changes={selectedActivity?.changed_keys?.item_keys}
                    label="Items"
                    key={'4'}
                  />
                  <MultiListCollapse
                    changes={selectedActivity?.changed_keys?.tds_keys}
                    label="TDS/TCS"
                    key={'5'}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </Drawer>
    </Spin>
  )
}
const ListItemRender = (data, action) => {
  switch (action) {
    case 'modified':
      const columns = [
        {
          title: 'Field',
          dataIndex: 'label',
          key: 'label',
        },
        {
          title: 'Old Value',
          dataIndex: 'old',

          key: 'oldValue',
          render: text => <span className="text-danger">{text}</span>,
        },
        {
          title: 'New Value',
          dataIndex: 'new',
          key: 'newValue',
          render: text => <span className="text-success">{text}</span>,
        },
      ]

      return <Table columns={columns} dataSource={data} pagination={false} bordered />

    case 'added':
      return data.map(change => (
        <div key={change.key} className="bg-success mb-1  p-1">
          <h6 className="text-gray-500">{change.label}</h6>
          <Text>{change.new}</Text>
        </div>
      ))
    case 'removed':
      return data.map(change => (
        <div key={change.key} className="mb-1 bg-danger p-1">
          <h6 className="text-gray-500">{change.label}</h6>
          <Text>{change.old}</Text>
        </div>
      ))
    default:
      return null
  }
}

const ListCollapse = ({ changes, label }) => {
  const addedChanges = changes?.filter(change => change.action === 'added')
  const removedChanges = changes?.filter(change => change.action === 'removed')
  const modifiedChanges = changes?.filter(change => change.action === 'modified')

  return (
    <Collapse accordion className="mb-2">
      <Panel header={label} key="1">
        {addedChanges.length > 0 && (
          <div>
            <Title level={5} className="text-gray-500">
              Added
            </Title>
            {ListItemRender(addedChanges, 'added')}
          </div>
        )}
        {removedChanges.length > 0 && (
          <div>
            <Title level={5} className="text-gray-500">
              Removed
            </Title>
            {ListItemRender(removedChanges, 'removed')}
          </div>
        )}
        {modifiedChanges.length > 0 && (
          <div>
            <Title level={5} className="text-gray-500">
              Modified
            </Title>
            {ListItemRender(modifiedChanges, 'modified')}
          </div>
        )}
      </Panel>
    </Collapse>
  )
}

const renderTable = items => {
  if (items.length === 0) return <></>
  const columns = Object.keys(items[0]).map(key => ({
    title: key,
    dataIndex: key,
    key,
    render: text => <Text>{text}</Text>,
  }))

  return (
    <Table
      style={{
        overflow: 'auto',
      }}
      dataSource={items.map((item, index) => ({ ...item, key: index }))}
      columns={columns}
      pagination={false}
      bordered
    />
  )
}

const renderModifiedTable = modifiedItems => {
  if (modifiedItems.length === 0) return <></>
  const columnsOld = Object.keys(modifiedItems[0].old).map(key => ({
    title: key,
    dataIndex: key,
    key,
    render: text => <Text delete>{text}</Text>,
  }))

  const columnsNew = Object.keys(modifiedItems[0].new).map(key => ({
    title: key,
    dataIndex: key,
    key,
    render: text => <Text>{text}</Text>,
  }))

  let old_data = modifiedItems.map(item => item.old)
  let new_data = modifiedItems.map(item => item.new)

  return (
    <div
      className="mb-1"
      style={{
        overflow: 'auto',
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <div
            style={{
              overflow: 'scroll',
            }}
          >
            <Table dataSource={old_data} columns={columnsOld} pagination={false} bordered />
          </div>
        </Col>
        <Col span={12}>
          <div
            style={{
              overflow: 'scroll',
            }}
          >
            <Table dataSource={new_data} columns={columnsNew} pagination={false} bordered />
          </div>
        </Col>
      </Row>
    </div>
  )
}

const MultiListCollapse = ({ changes, label, key }) => {
  return (
    (changes?.added.length > 0 || changes?.removed.length > 0 || changes?.modified.length > 0) && (
      <Collapse accordion key={key} className="mb-2">
        <Panel header={label} key={'1'}>
          {changes?.added.length > 0 && (
            <div>
              <Title level={5} className="text-gray-500">
                Added
              </Title>
              {renderTable(changes.added)}
            </div>
          )}
          {changes?.removed.length > 0 && (
            <div>
              <Title level={5} className="text-gray-500">
                Removed
              </Title>
              {renderTable(changes.removed)}
            </div>
          )}
          {changes?.modified.length > 0 && (
            <div>
              <Title level={5} className="text-gray-500">
                Modified
              </Title>
              {renderModifiedTable(changes.modified)}
            </div>
          )}
        </Panel>
      </Collapse>
    )
  )
}
