export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const SET_DEFAULT_URL = 'SET_DEFAULT_URL'
export const GET_DEFAULT_URL = 'GET_DEFAULT_URL'
export const GET_INTEGRATIONS = 'GET_INTEGRATIONS'
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS'
export const SET_BEACON = 'SET_BEACON'
export const GET_PRODUCT_SETTINGS = 'GET_PRODUCT_SETTINGS'
export const SET_PRODUCT_SETTINGS = 'SET_PRODUCT_SETTINGS'
