import React, { Fragment } from 'react'
import { HIDE_PRICES } from '../utils'
import { Text, View, Image, Font } from '@react-pdf/renderer'
import { extraChargesAmount, getTotalQty } from 'components/other/calculation'
import RichText from '../RichText'
import { styles as getStyles } from './styles'
import InvoiceNotes from './invoiceNotes'
import InvoiceBankDetails from '../11/invoiceBankDetails'
import { has_multiple_units } from '../export_condition'

const InvoiceTableFooter = ({ items, total, netAmount, invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const tax_options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const hide_hsn = invoice.invoice_settings.hide_hsn
  const hide_discount = invoice.invoice_settings.hide_discount
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }

  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  let currency_code = invoice.company.currency_code
  const is_export = invoice.is_export == 1
  if (invoice.is_export == 1 || invoice.is_multi_currency == 1) {
    if (invoice.invoice_settings.show_both_currencies != true) {
      currency_symbol = invoice.export_details[0].currency_symbol
      conversion_factor = invoice.export_details[0].conversion_factor
    }
    currency_code = invoice.export_details[0].currency_code
  }

  let rows = items.map(
    (item, i) =>
      invoice.company.gstin.length == 15 &&
      item.tax_amount != 0 &&
      invoice.with_tax != 0 && (
        <View>
          <View key={i} style={styles.invoiceTableFooter_row2}>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              {item.tax_type}
              {'\u00A0'}
              {item.tax_percent}
            </Text>
            <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
              {currency_symbol}
              {(item.tax_amount / conversion_factor).toLocaleString('en-IN', tax_options)}
            </Text>
          </View>
        </View>
      ),
  )

  {
    invoice.cess_amount - invoice.cess_on_qty_value > 0 &&
      invoice.company.gstin.length == 15 &&
      invoice.with_tax != 0 &&
      rows.push(
        <View className="flex flex-col">
          <View style={styles.invoiceTableFooter_block}>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              Cess
            </Text>
            <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
              {currency_symbol}
              {Number(
                ((invoice.cess_amount - invoice.cess_on_qty_value) / conversion_factor).toFixed(2),
              ).toLocaleString('en-IN', options)}
            </Text>
          </View>
        </View>,
      )
  }
  {
    invoice.cess_on_qty_value > 0 &&
      invoice.company.gstin.length == 15 &&
      invoice.with_tax != 0 &&
      rows.push(
        <View>
          <View style={styles.invoiceTableFooter_block}>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              Cess on Qty
            </Text>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              {currency_symbol}
              {Number((invoice.cess_on_qty_value / conversion_factor).toFixed(2)).toLocaleString(
                'en-IN',
                options,
              )}
            </Text>
          </View>
        </View>,
      )
  }

  const has_multiple_unit_products = has_multiple_units(invoice.items)
  return (
    <View style={styles.invoiceTableFooter_blockC} wrap={false}>
      <View style={styles.invoiceTableFooter_blockCA}>
        <InvoiceBankDetails invoice={invoice} type={items} />
        <View>
          {type != 'Delivery Challan' && type != HIDE_PRICES && (
            <>
              {invoice.document_type != 'estimates' && (
                <View>
                  <Text style={styles.invoiceTableFooter_itemsQty}>
                    {!has_multiple_unit_products && invoice.invoice_settings.hide_qty == 0 && (
                      <>
                        <Text>
                          {'Total Items / Qty : '}
                          {invoice.items.length} / {getTotalQty(invoice.items)}
                        </Text>
                      </>
                    )}
                  </Text>
                  <Text style={styles.invoiceTableFooter_amountinwords}>
                    Total amount (in words):
                    {'\u00A0'}
                    {invoice.total_amount_in_words}.
                  </Text>
                </View>
              )}

              {invoice.document_type == 'estimates' && invoice.hide_totals == 0 && (
                <View>
                  <Text style={styles.invoiceTableFooter_itemsQty}>
                    {!has_multiple_unit_products && invoice.invoice_settings.hide_qty == 0 && (
                      <>
                        {'Total Items / Qty : '}
                        {invoice.items.length} / {getTotalQty(invoice.items)}{' '}
                      </>
                    )}
                  </Text>
                  <Text style={styles.invoiceItemsTable_amountinwords}>
                    Total amount (in words):
                    {'\u00A0'}
                    {invoice.total_amount_in_words}.
                  </Text>
                </View>
              )}
            </>
          )}
          {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
            <>
              {!has_multiple_unit_products && invoice.invoice_settings.hide_qty == 0 && (
                <View>
                  <Text style={[styles.invoiceItemsTable_dc_totals, styles.invoiceItemsTable_bold]}>
                    Total Items / Qty : {invoice.items.length} / {getTotalQty(invoice.items)}{' '}
                  </Text>
                </View>
              )}
            </>
          )}
        </View>
      </View>

      <View style={styles.invoiceTableFooter_blockCB}>
        <View>
          {invoice.document_custom_additional_charges.map((item, index) => (
            <>
              {item.total_amount != 0 && (
                <View style={styles.invoiceTableFooter_row2}>
                  <Text style={styles.invoiceTableFooter_description}>
                    {item['name']}
                    {'\n'}
                    {item['sac_code'] != '' && !hide_hsn && (
                      <View>
                        <RichText
                          locale={locale}
                          FONT_FACTOR={0.8 * 8.5 * FONT_FACTOR}
                          note={'SAC: ' + item['sac_code']}
                        />
                      </View>
                    )}
                  </Text>
                  <Text style={styles.invoiceTableFooter_total}>
                    {item.type == -1 ? '(-) ' : ''}
                    {currency_symbol}
                    {Number(item.net_amount / conversion_factor).toFixed(2)}
                  </Text>
                </View>
              )}
            </>
          ))}

          {invoice.company.gstin.length == 15 &&
            invoice.with_tax != 0 &&
            invoice.invoice_settings.hide_taxable_amount == 0 && (
              <View style={styles.invoiceTableFooter_row2}>
                <Text
                  style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}
                >
                  Taxable Amount
                </Text>
                <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                  {currency_symbol}
                  {Number(
                    (
                      (netAmount +
                        extraChargesAmount(
                          invoice.document_custom_additional_charges,
                          'net_amount',
                        )) /
                      conversion_factor
                    ).toFixed(2),
                  ).toLocaleString('en-IN', options)}
                </Text>
              </View>
            )}

          {rows}

          {invoice.extra_discount != 0 && invoice.extra_discount != null && hide_discount != 1 && (
            <View style={styles.invoiceTableFooter_row2}>
              <Text style={styles.invoiceTableFooter_description}>Discount</Text>
              <Text style={styles.invoiceTableFooter_total}>
                -{currency_symbol}
                {Number(invoice.extra_discount.toFixed(2)).toLocaleString('en-IN', options)}
              </Text>
            </View>
          )}

          {invoice.roundoff == 1 &&
            invoice.roundoff_value != 0 &&
            invoice.is_export == 0 &&
            invoice.is_multi_currency == 0 &&
            invoice.invoice_settings.show_round_off == 1 && (
              <View style={styles.invoiceTableFooter_row2}>
                <Text
                  style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}
                >
                  Round Off
                </Text>
                <Text
                  style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}
                >
                  {(-1 * Number(invoice.roundoff_value.toFixed(2))).toLocaleString(
                    'en-IN',
                    options,
                  )}
                </Text>
              </View>
            )}

          {invoice.is_tcs == 1 && (
            <View style={styles.invoiceTableFooter_row2}>
              <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
                TCS @ {invoice.tcs_details[0].tax}% {invoice.tcs_details[0].section}
              </Text>
              <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                {'\u20B9'}
                {invoice.tcs_details[0].tcs_amount.toLocaleString('en-IN', options)}
              </Text>
            </View>
          )}
          {/* has_extra_charges */}

          {/*<View style={{ flexDirection: 'row' }}>
              <View style={styles.invoiceTableFooter_borderBlock}></View>
              <View style={styles.invoiceTableFooter_borderBlock2}></View>
            </View>*/}

          <View style={styles.invoiceTableFooter_row1}>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              {total[0].name}
            </Text>
            <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
              {currency_symbol}
              {Number((total[0].amount / conversion_factor).toFixed(2)).toLocaleString(
                'en-IN',
                options,
              )}
            </Text>
          </View>
          {(invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
            invoice.invoice_settings.show_both_currencies == true && (
              <View style={styles.invoiceTableFooter_row2}>
                <Text
                  style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}
                >
                  {total[0].name} ({invoice.export_details[0].currency_code})
                </Text>
                <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                  {invoice.export_details[0].currency_symbol}
                  {Number(
                    (total[0].amount / invoice.export_details[0].conversion_factor).toFixed(2),
                  ).toLocaleString('en-IN', options)}
                </Text>
              </View>
            )}

          {/* hiding total discount in 11 template on feature request */}
          {/* {invoice.total_discount != 0 && invoice.total_discount != null && hide_discount != 1 && (
            <View style={styles.invoiceTableFooter_row2}>
              <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
                Total Discount
              </Text>
              <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                {currency_symbol}
                {Number((invoice.total_discount / conversion_factor).toFixed(2)).toLocaleString(
                  'en-IN',
                  options,
                )}
              </Text>
            </View>
          )} */}

          {invoice.is_tds == 1 && (
            <View style={styles.invoiceTableFooter_row2}>
              <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
                TDS @ {invoice.tds_details[0].tax}% {invoice.tds_details[0].section}
              </Text>
              <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                {'\u20B9'}
                {invoice.tds_details[0].tds_amount}
              </Text>
            </View>
          )}
        </View>

        <View>
          {type != 'Delivery Challan' && type != HIDE_PRICES && (
            <>
              {(invoice.document_type == 'sales' || invoice.document_type == 'pos') &&
                invoice.amount_pending > 0 && (
                  <>
                    <View style={styles.invoiceTableFooter_row2}>
                      <Text style={styles.invoiceTableFooter_labels}>Amount Payable:</Text>
                      <Text style={styles.invoiceTableFooter_info}>
                        {currency_symbol}
                        {Number(
                          (invoice.amount_pending / conversion_factor).toFixed(2),
                        ).toLocaleString('en-IN', options)}
                      </Text>
                    </View>

                    {invoice.amount_paid > 0 && invoice.amount_paid < invoice.total[0].amount && (
                      <View style={styles.invoiceTableFooter_row2}>
                        <Text style={styles.invoiceTableFooter_labels}>Amount Paid:</Text>
                        <Text style={styles.invoiceTableFooter_info}>
                          {currency_symbol}
                          {Number(
                            (invoice.amount_paid / conversion_factor).toFixed(2),
                          ).toLocaleString('en-IN', options)}
                        </Text>
                      </View>
                    )}
                  </>
                )}

              {/* Amount Fully PAID */}
              {(invoice.document_type == 'sales' ||
                invoice.document_type == 'purchases' ||
                invoice.document_type == 'sales_returns' ||
                invoice.document_type == 'purchase_returns') &&
                invoice.amount_paid == invoice.total[0].amount && (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginHorizontal: 'auto',
                      marginRight: 0,
                      marginBottom: 1.8,
                    }}
                  >
                    <Image
                      style={styles.invoiceTableFooter_image}
                      src={{
                        uri: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/checkmark.png',
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                      }}
                      crossorigin="anonymous"
                    />
                    <Text
                      style={[styles.invoiceTableFooter_amountPaid, styles.invoiceTableFooter_bold]}
                    >
                      Amount Paid
                    </Text>
                  </View>
                )}

              {/* Show Payments */}
              {invoice.invoice_settings.show_payments != 0 &&
                invoice.payments != '' &&
                invoice.payments.map(
                  (item, i) =>
                    item.payment_mode != 'TDS' && (
                      <View key={i} style={styles.invoiceTableFooter_row2}>
                        <Text style={styles.invoiceTableFooter_payments}>
                          <Text>
                            <Text>
                              {currency_symbol}
                              {item.settled_amount / conversion_factor} Paid via {item.payment_mode}{' '}
                              on {item.payment_date}
                              {'\n'}
                            </Text>
                            <Text style={{ color: '#343435', fontSize: 8.1 }}>
                              {item.notes != '' && ' (' + item.notes + ')'}
                            </Text>
                          </Text>
                        </Text>
                      </View>
                    ),
                )}

              {/* Show Net Balance */}
              {invoice.invoice_settings.show_net_balance != 0 &&
                invoice.customer.balance + invoice.customer.opening_balance < 0 && (
                  <View>
                    {/*<View style={{ flexDirection: 'row' }}>
                        <View style={styles.invoiceTableFooter_borderBlock}></View>
                        <View style={styles.invoiceTableFooter_borderBlock2}></View>
                      </View>*/}
                    <View style={styles.invoiceTableFooter_row2}>
                      <Text style={styles.invoiceTableFooter_labels}>Total Balance due:</Text>
                      <Text style={styles.invoiceTableFooter_info}>
                        {currency_symbol}
                        {Math.abs(
                          (
                            (invoice.customer.balance + invoice.customer.opening_balance) /
                            conversion_factor
                          ).toFixed(2),
                        ).toLocaleString('en-IN', options)}
                      </Text>
                    </View>
                  </View>
                )}
            </>
          )}
        </View>
      </View>
    </View>
  )
}

export default InvoiceTableFooter
