import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  message,
} from 'antd'
import { getBase64 } from 'components/other/utility'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { getStates } from 'redux/document/actions'
import { setCompanyDetails } from 'redux/permissions/actions'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
import store from 'store'
import { CONSTANTS } from 'utils/constants'
import SettingForm from './customColumns'
import CompanyAddressForm from './companyAddressForm'

const { TextArea } = Input
const { Option } = Select

const emptyCompanyData = {
  organization_name: '',
  company_name: '',
  email: '',
  mobile: '',
  gstin: '',
  address: {
    address_1: '',
    address_2: '',
    city: '',
    state: undefined,
    pincode: '',
    country: 'India',
  },
  billing_address: [],
  shipping_address: [],
  previewImage: '',
  previewVisible: false,
  previewTitle: '',
}

const emptyAddress = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  pincode: '',
  is_same: false,
  country: 'India',
}

class CompanyForm extends Component {
  formRef = React.createRef()
  formRefAddress = React.createRef()
  addressFormRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      shippingAddress: false,
      states: [],
      loaded: false,
      companyDetails: { ...emptyCompanyData },
      fetchLoading: false,
      selectedAddressType: 'billing',
      selectedAddrID: -1,
      outSideFormVisible: false,
      gstin: 'no',
      otherTerritory: false,
    }
    this._onAddressFinish = this._onAddressFinish.bind(this)
    this.onAddressFinish = _.throttle(this._onAddressFinish, 2000)
  }

  async componentDidMount() {
    this.props.onRef(this)
    if (
      window.location.pathname.includes('user') ||
      window.location.pathname.includes('sales') ||
      window.location.pathname.includes('estimates') ||
      window.location.pathname.includes('create') ||
      window.location.pathname.includes('edit') ||
      window.location.pathname.includes('inventory') ||
      window.location.pathname.includes('einvoice')
    ) {
      this.setState({ outSideFormVisible: true })
    }

    var data = await getAPIData('v2/company', 'company_profile')
    if (data) {
      store.set('one_time_offer_eligible', data.show_one_time_offer)
      this.setState(
        { companyDetails: data.company_details, imageUrl: data.company_details.logo },
        () => {
          this.setState({ loaded: true, gstin: data.company_details.gstin != '' ? 'yes' : 'no' })
        },
      )
    }

    if (this.props?.states?.length == 0) {
      this.props.dispatch(getStates())
    }
  }

  refresh = async () => {
    var data = await getAPIData('v2/company', 'company_profile')
    if (data) {
      this.setState(
        { companyDetails: data.company_details, imageUrl: data.company_details.logo },
        () => {
          this.setState({ loaded: true })
        },
      )
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
    this.onAddressFinish.cancel()
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }

    const form = new FormData()
    form.append('file', file)

    const data = await getMultipartAPI('user', 'update_logo', form)
    if (data.success) {
      onSuccess(null, file)
      this.setState({ imageUrl: file.url || file.preview })
      message.success({
        content: 'Logo Updated',
        style: {
          color: 'success',
        },
      })
    } else {
      message.error({
        content: data.message,
        style: {
          color: 'red',
        },
      })
      onError()
    }
  }

  fetchGSTDetails = async () => {
    this.setState({ fetchLoading: true })
    const gstin = this.formRef.current.getFieldsValue()['gstin']
    var req = {
      gstin: gstin,
    }
    var data = await getAPI('user', 'fetch_gst_details', req)
    if (data) {
      const gst_data = data.response
      var values = {}
      values['line1'] = data.response.billing.address_1
      values['line2'] = data.response.billing.address_2
      values['city'] = data.response.billing.city
      values['state'] = data.response.billing.state
      values['pincode'] = data.response.billing.pincode

      // as billing address is already present, edit the existing address
      if (this.state.companyDetails.billing_address.length > 0) {
        var req = {
          addr_id: this.state.companyDetails.billing_address[0]['addr_id'],
          ...values,
        }
        var data1 = await getAPI('v2/company', 'edit_billing_address', req)
        if (data1.success) {
          message.success(data1.message)
          this.refresh()
        }
      } else {
        var req = {
          ...values,
        }
        var data1 = await getAPI('v2/company', 'add_billing_address', req)
        if (data1.success) {
          message.success(data1.message)
          this.refresh()
        }
      }

      await this.formRef.current.setFieldsValue({
        company_name: gst_data['company_name'],
      })
      await this.formRef.current.submit()
    }
    this.setState({ fetchLoading: false })
  }

  addressDelete = async (address_type, addr_id) => {
    var req = {
      address_type: address_type,
      addr_id: addr_id,
      customer_id: this.state.companyDetails.customer_id,
    }

    var data = await getAPI('v2/company', 'delete_address', req)
    if (data.success) {
      message.success(data.message)
      this.refresh()
    }
  }

  _onAddressFinish = async (values, addr_id, addressType) => {
    if (values.state == undefined) {
      message.error('State is Required')
      this.setState({ loading: false })
      return true
    }
    if (values.line1 == undefined || values.line1 == '') {
      message.error('Address Line 1 is Required')
      this.setState({ loading: false })
      return true
    }
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] === undefined) {
        values[key] = ''
      }
    }

    if (addr_id >= 0) {
      if (addressType == 'billing') {
        var req = {
          addr_id: addr_id,
          ...values,
          pincode: values.pincode ? values.pincode : '',
        }
        var data = await getAPI('v2/company', 'edit_billing_address', req)
        if (data.success) {
          store.set('address_1', values.line1)
          store.set('address_2', values.line2)
          store.set('company_city', values.city)
          store.set('company_pincode', values.pincode)
          message.success(data.message)
          await this.refresh()
        }
      } else {
        var req = {
          addr_id: addr_id,
          ...values,
          pincode: values.pincode ? values.pincode : '',
        }
        var data = await getAPI('v2/company', 'edit_shipping_address', req)
        if (data.success) {
          message.success(data.message)
          await this.refresh()
        }
      }
    } else {
      if (addressType == 'billing') {
        values['is_edit'] = false
        var req = {
          ...values,
          pincode: values.pincode ? values.pincode : '',
        }
        var data = await getAPI('v2/company', 'add_billing_address', req)
        if (data.success) {
          message.success(data.message)
          await this.refresh()
        }
      } else {
        values['is_edit'] = false
        var req = {
          ...values,
          pincode: values.pincode ? values.pincode : '',
        }
        var data = await getAPI('v2/company', 'add_shipping_address', req)
        if (data.success) {
          message.success(data.message)
          await this.refresh()
        }
      }
    }
    this.addressFormRef?.current?.onClose()
    this.setState({ loading: false, addressDrawerVisible: false })
  }

  onShipingChange = e => {
    this.setState({ shippingAddress: e.target.checked })
  }

  onFinish = async values => {
    if (values.gstin == undefined) {
      values.gstin = 'NA'
    }

    this.setState({ loading: true })
    const req = {
      billing_address: this.state.companyDetails.billing_address,
      shipping_address: this.state.companyDetails.shipping_address,
      name: values.name,
      ...values,
      dial_code: this.state.companyDetails.dial_code,
      mobile: this.state.companyDetails.mobile,
    }

    const data = await getAPI('v2/company', 'company_edit', req)
    if (data) {
      store.set('company_details_added', true)
      store.set('organization_name', values.organization_name)
      store.set('company_name', values.company_name)
      store.set('company_mobile', values.mobile)
      store.set('gstin', values.gstin)
      store.set('address_1', values.address_1)
      store.set('address_2', values.address_2)
      store.set('company_city', values.city)
      store.set('company_pincode', values.pincode)
      message.success({
        content: data.message,
        style: {
          color: 'success',
        },
      })
      this.props.dispatch(setCompanyDetails(values))

      await this.props.onAPICalled()
      this.setState({ visible: false })
    }
    this.setState({ loading: false })
  }

  onClose = () => {
    this.setState({ companyDrawerVisible: false, outSideFormVisible: false })
  }

  onOpenCompanyDrawer = () => {
    this.setState({ companyDrawerVisible: true, outSideFormVisible: false })
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handleRemoveId = id => {
    if (id === '') {
      return null
    } else {
      this.setState({ imageUrl: '' })
      return <uploadButton />
    }
  }

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  onAdd = () => {
    this.setState({ visible: true })
  }

  onRemove = async file => {
    var data = await getAPIData('v2/company', 'remove_logo')
    if (data.success) {
      this.setState({ imageUrl: '' })
      message.success(data.message)
    }
  }

  render() {
    const formItemLayout = {
      labelCol: {
        sm: { span: 8 },
        md: { span: 8 },
      },
      wrapperCol: {
        sm: { span: 16 },
        md: { span: 16 },
      },
    }

    var { previewImage, previewTitle, previewVisible } = this.state

    if (this.state.imageUrl != '') {
      var fileList = [
        {
          uid: '-1',
          name: 'Logo.png',
          status: 'done',
          url: this.state.imageUrl,
        },
      ]
    } else {
      var fileList = []
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>
          <FormattedMessage id="button.upload" />{' '}
        </div>
      </div>
    )

    const CompanyForm1 = (
      <Form
        {...formItemLayout}
        labelAlign="left"
        ref={this.formRef}
        name="categoryForm"
        onFinish={this.onFinish}
        onFinishFailed={() => {
          message.error('Check All Details Again')
          this.setState({ loading: false })
        }}
        initialValues={{
          ...this.state.companyDetails,
        }}
      >
        <Card>
          {/* <Button
            onClick={() => this.setState({ loading: true }, () => this.formRef.current.submit())}
            type="primary"
            className="px-2 mb-5"
            loading={this.state.loading}
          >
            <FormattedMessage id="button.save&update" />
          </Button> */}
          <Form.Item
            name="company_logo"
            label={
              <span>
                <FormattedMessage id="form.field.logo" />
              </span>
            }
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              fileList={fileList}
              onPreview={this.handlePreview}
              customRequest={this.customRequest}
              beforeUpload={this.beforeUpload}
              onRemove={this.onRemove}
            >
              {this.state.imageUrl == '' ? uploadButton : null}
            </Upload>
          </Form.Item>
          <Modal
            open={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={this.handleCancel}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
          <Form.Item
            name="company_name"
            label={<FormattedMessage id="form.field.companyName" />}
            rules={[{ required: true, message: 'Please fill Company Name' }]}
          >
            <Input placeholder="Bundl Technologies Private Limited" />
          </Form.Item>
          <Form.Item
            name="mobile"
            label={
              <span>
                <FormattedMessage id="form.field.companyPhone" />
              </span>
            }
          >
            <div className="flex">
              <Select
                showSearch
                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                //placeholder="Select DialCode"
                optionFilterProp="children"
                style={{ width: '99px' }}
                filterOption={(input, option) =>
                  (option.children[0].props.children + option.children[1].props.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                trigger="click"
                dropdownStyle={{ minWidth: '270px' }}
                defaultValue={'+' + this.state.companyDetails.dial_code}
                onChange={value => {
                  this.setState({
                    companyDetails: {
                      ...this.state.companyDetails,
                      dial_code: value,
                    },
                  })
                }}
                value={
                  this.state.companyDetails.dial_code == ''
                    ? '91'
                    : this.state.companyDetails.dial_code
                }
              >
                {this.props?.countries?.map((item, i) => (
                  <Option key={i} value={item.dial_code}>
                    <span className="font-weight-bold mr-2 w-50">{'+' + item.dial_code}</span>
                    <span className="text-gray-500 w-50">{item.name}</span>
                  </Option>
                ))}
              </Select>
              <InputNumber
                value={this.state.companyDetails.mobile}
                placeholder="Company Phone Number"
                style={{ width: '100%' }}
                onChange={value => {
                  this.setState({
                    companyDetails: {
                      ...this.state.companyDetails,
                      mobile: value,
                    },
                  })
                }}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <span>
                <FormattedMessage id="form.field.companyEmail" />
              </span>
            }
            rules={[
              {
                type: 'email',
                message: 'Not valid E-mail!',
              },
              { required: false, message: 'Please fill Email' },
            ]}
          >
            <Input
              onInput={e => (e.target.value = e.target.value.toLowerCase())}
              placeholder="Company Email Address"
            />
          </Form.Item>
          <Form.Item
            name=""
            label={<span>Do you have {this.props?.user?.selectedCompany?.tax_id_label} ?</span>}
          >
            {/*<span className="font-weight-bold pr-4 text-gray-600">Do you have GST No?</span>*/}
            <Radio.Group
              defaultValue={this.state.gstin}
              buttonStyle="solid"
              onChange={e => this.setState({ gstin: e.target.value })}
            >
              <Radio.Button value="yes">Yes</Radio.Button>
              <Radio.Button value="no">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {this.state.gstin == 'yes' && this.props?.user?.selectedCompany?.country_code == 'IN' && (
            <Form.Item
              name="gstin"
              label={this.props?.user?.selectedCompany?.tax_id_label}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value == 'NA' || new RegExp(CONSTANTS.GSTIN_REGEX).test(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Please enter a valid GSTIN'))
                  },
                }),
              ]}
            >
              <Input.Search
                placeholder="29AABCT1332L000"
                enterButton={<FormattedMessage id="form.field.fetch" />}
                loading={this.state.fetchLoading}
                onChange={e => {
                  if (
                    e.target.value.length == 15 &&
                    e.target.value != this.state.companyDetails.gstin
                  ) {
                    this.fetchGSTDetails()
                  }
                }}
                onSearch={this.fetchGSTDetails}
              />
            </Form.Item>
          )}
          {this.state.gstin == 'yes' && this.props?.user?.selectedCompany?.country_code != 'IN' && (
            <Form.Item name="gstin" label={this.props?.user?.selectedCompany?.tax_id_label}>
              <Input placeholder="100123456789012" />
            </Form.Item>
          )}
          {/* <div className="flex justify-between">
          <h6 className="font-weight-bold mt-4">Custom Fields</h6>
          <a
            onClick={e => {
              store.get('paid') == 0
                ? this.subscription.showModal()
                : this.custom_column.onCompanyCustomFields()
            }}
            type="action-o"
            className="font-weight-bold  sm:block flex justify-end mt-0"
          >
            <i className="fa fa-lg fa-plus-circle mr-2 text-action " />
            Custom Fields
          </a>
        </div> */}
          <h6 className="font-weight-bold mt-4">
            <FormattedMessage id="form.field.billing" />
          </h6>
          <Row>
            {this.state.companyDetails.billing_address.map((item, index) => (
              <Card
                key={index}
                size="small"
                style={{ width: 333, borderRadius: '5px', cursor: 'pointer' }}
                className="mr-2 my-2 mr-4 border-gray-100"
              >
                <span className="font-size-14">
                  <span>
                    <p className="mb-0">{item.line1}</p>
                    <p className="mb-0">{item.line2}</p>
                    <p className="mb-0">{item.city}</p>
                    <p className="mb-1">
                      {item.state}{' '}
                      {item.pincode != '' && this.state != '' ? '-' : '' + item.pincode}
                    </p>
                    <p className="mb-0">{item.country}</p>
                  </span>
                </span>
                <a
                  onClick={e =>
                    this.addressFormRef?.current?.showAddressDrawer('billing', item.addr_id, item)
                  }
                  className="card-link font-weight-bold text-action mr-3"
                >
                  <FormattedMessage id="button.edit" />
                  <i className="fa fa-pencil-o" />
                </a>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={e => this.addressDelete('billing', item.addr_id)}
                  onClick={event => event.stopPropagation()}
                  onCancel={event => event.stopPropagation()}
                  className="mr-3"
                >
                  <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
                    <FormattedMessage id="button.delete" />
                  </span>
                </Popconfirm>
                <a
                  onClick={e => {
                    this.setState({ selectedAddressType: 'shipping', selectedAddrID: -1 }, () =>
                      this.onAddressFinish({
                        line1: item.line1,
                        line2: item.line2,
                        city: item.city,
                        state: item.state,
                        pincode: item.pincode,
                        country: item.country,
                      }),
                    )
                  }}
                  className="card-link font-weight-bold text-green mr-3"
                >
                  <FormattedMessage id="button.copytoShipping" />
                  <i className="fa fa-pencil-o" />
                </a>
              </Card>
            ))}
          </Row>
          {this.state.companyDetails.billing_address.length == 0 && (
            <Button
              type="add-new-1"
              size="large"
              className="my-2 mb-4 px-4"
              onClick={e =>
                this.addressFormRef?.current?.showAddressDrawer('billing', -1, { addr_id: -1 })
              }
            >
              <span className="font-weight-bold font-size-16" style={{ color: '#fd5e76' }}>
                <i className="fa fa-plus-circle fa-lg mr-2" />{' '}
                <FormattedMessage id="button.billingAddress" />
              </span>
            </Button>
          )}
          <h6 className="font-weight-bold mt-2">
            <FormattedMessage id="form.field.shipping" />
          </h6>
          <Row>
            {this.state.companyDetails.shipping_address.map((item, index) => (
              <>
                <Card
                  key={index}
                  size="small"
                  style={{ width: 333, borderRadius: '5px', cursor: 'pointer' }}
                  className="mr-2 my-2 mr-4 border-gray-100"
                >
                  <span className="font-size-14">
                    <span>
                      <p className="mb-0">{item.line1}</p>
                      <p className="mb-0">{item.line2}</p>
                      <p className="mb-0">{item.city}</p>
                      <p className="mb-1">
                        {item.state}{' '}
                        {item.pincode != '' && this.state != '' ? '-' : '' + item.pincode}
                      </p>
                      <p className="mb-0">{item.country}</p>
                    </span>
                  </span>
                  <a
                    onClick={e =>
                      this.addressFormRef?.current?.showAddressDrawer(
                        'shipping',
                        item.addr_id,
                        item,
                      )
                    }
                    className="card-link text-action font-weight-bold mr-3"
                  >
                    <FormattedMessage id="button.edit" />
                  </a>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={e => this.addressDelete('shipping', item.addr_id)}
                    onClick={event => event.stopPropagation()}
                    onCancel={event => event.stopPropagation()}
                  >
                    <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
                      <FormattedMessage id="button.delete" />
                    </span>
                  </Popconfirm>
                </Card>
              </>
            ))}
          </Row>
          <div>
            <Button
              type="add-new-1"
              size="large"
              className="my-2 mb-4 px-4"
              onClick={e =>
                this.addressFormRef?.current?.showAddressDrawer('shipping', -1, { addr_id: -1 })
              }
            >
              <span className="font-weight-bold font-size-16" style={{ color: '#fd5e76' }}>
                <i className="fa fa-plus-circle fa-lg mr-2" />
                <FormattedMessage id="button.shippingAddress" />
              </span>
            </Button>
          </div>
          {/* <Button
            onClick={() => this.setState({ loading: true }, () => this.formRef.current.submit())}
            type="primary"
            className="px-2 mb-5"
            loading={this.state.loading}
          >
            <FormattedMessage id="button.save&update" />
          </Button> */}
        </Card>
      </Form>
    )

    return (
      <div>
        <Drawer
          title={
            <>
              <div className="flex items-center">
                <div className="mr-auto">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    Add Company details to display on Invoice
                  </span>
                </div>
              </div>
            </>
          }
          width={isMobile ? '100%' : '50%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closable={() => this.setState({ visible: false })}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onClose={() => this.setState({ visible: false })}
          visible={this.state.visible}
          footer={
            <Button
              onClick={() => this.setState({ loading: true }, () => this.formRef.current.submit())}
              type="primary"
              className="px-2 py-2"
              loading={this.state.loading}
            >
              <FormattedMessage id="button.save&update" />
            </Button>
          }
          extra={
            <Button
              onClick={() => this.setState({ loading: true }, () => this.formRef.current.submit())}
              type="primary"
              className="px-2 py-2"
              loading={this.state.loading}
            >
              <FormattedMessage id="button.save&update" />
            </Button>
          }
        >
          {this.state.loaded == false ? (
            <Spin indicator={antIcon} />
          ) : (
            <div hidden={!this.state.outSideFormVisible} className="">
              {CompanyForm1}
              <CompanyAddressForm
                onFinish={this.onAddressFinish}
                selectedAddrID={this.state.selectedAddrID}
                selectedAddressType={this.state.selectedAddressType}
                selectedData={
                  this.state.selectedAddressType == 'billing'
                    ? this.state.companyDetails.billing_address.find(
                        e => e.addr_id == this.state.selectedAddrID,
                      )
                    : this.state.companyDetails.shipping_address.find(
                        e => e.addr_id == this.state.selectedAddrID,
                      )
                }
                ref={this.addressFormRef}
              />

              <Drawer
                title="Company Details"
                width={isMobile ? '100%' : '50%'}
                height={isMobile ? '90%' : '100%'}
                placement={isMobile ? 'top' : 'right'}
                maskClosable={false}
                closeIcon={<i className="fa-solid mr-3 fa-xmark"></i>}
                closable={true}
                onClose={this.onClose}
                visible={this.state.companyDrawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                  <>
                    <Button
                      onClick={() =>
                        this.setState({ loading: true }, () => this.formRefAddress.current.submit())
                      }
                      type="primary"
                      loading={this.state.loading}
                      className="float-left arrow-transition"
                    >
                      <FormattedMessage id="button.save" />
                      <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                    </Button>
                    <Button
                      onClick={this.onClose}
                      style={{ marginRight: 8 }}
                      className="float-left ml-2"
                    >
                      <FormattedMessage id="button.cancel" />
                    </Button>
                  </>
                }
              >
                {CompanyForm1}
              </Drawer>
            </div>
          )}
          <SettingForm
            onRef={ref => (this.custom_column = ref)}
            onAPICalled={() => this.refresh()}
          />
        </Drawer>
      </div>
    )
  }
}

class EditCompanyForm extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <CompanyForm {...this.props} />
  }
}
const mapStateToProps = state => {
  return {
    states: state.document.states,
    countries: state.document.countries,
    user: state.user,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(CompanyForm)
