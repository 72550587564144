import { GET_PRODUCT_SETTINGS, SET_PRODUCT_SETTINGS } from './types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getAPIData } from 'services/jwt'
export function* get_product_settings() {
  const data = yield call(async () => await getAPIData('utils', 'product_settings'))
  if (data.success) {
    yield put({ type: SET_PRODUCT_SETTINGS, payload: data.product_settings })
  }
}
export default function* permissions() {
  yield all([takeEvery(GET_PRODUCT_SETTINGS, get_product_settings)])
}
