import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Drawer, Form, Input, Switch, Tooltip, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { formItemLayout } from 'components/other/utility'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { get_payment_modes } from 'redux/document/actions'
import { getAPI, getAPIData } from 'services/jwt'
import store from 'store'

class BankForm extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      bankData: '',
      visible: false,
      selectedBank: {},
      empty_bank: {
        bank_name: '',
        branch_name: '',
        bank_no: '',
        ifsc: '',
        upi: '',
        opening_balance: '',
        gpay_number: '',
        notes: '',
        is_default: false,
        confirm: '',
        upi_id: '',
        upiVerified: false,
        upiLoading: false,
        upiName: '',
      },
      touched: { bank_no: false, confirm: false },
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  handleBlur = field => {
    this.setState({ touched: { ...this.state.touched, [field]: true } })
  }

  onFinish = async values => {
    if (values.upi && !this.state.upiVerified) {
      message.error('Please verify UPI ID')
      return
    }

    var url = 'add_bank_details'
    // console.log(this.state.selectedBank.id);
    var reqData = {}
    if (this.state.selectedBank.id != undefined) {
      url = 'bank_details'
      reqData = { id: this.state.selectedBank.id }
    }
    const req = {
      ...values,
      ...reqData,
    }

    const data = await getAPI('user', url, req)
    if (data) {
      store.set('bank_details_added', true)
      message.success('Updated Profile')
      this.setState(
        {
          visible: false,
          selectedBank: {},
          upiVerified: false,
        },
        () => {
          try {
            this.props.refreshBanks()
          } catch {}
        },
      )
      try {
        this.props.dispatch(get_payment_modes())
      } catch (e) {
        console.log(e)
      }
    }
  }

  onFetchBankDetails = async () => {
    if (this.props?.user?.selectedCompany?.country_code == 'IN') {
      return
    }
    const ifsc = this.formRef.current.getFieldValue('ifsc')
    if (ifsc) {
      try {
        const data = await fetch(`https://ifsc.razorpay.com/${ifsc}`)

        if (data.ok) {
          let bank_details = await data.json()
          this.formRef.current.setFieldsValue({
            bank_name: bank_details.BANK,
            branch_name: bank_details.BRANCH,
          })
          message.success('Bank details fetched successfully')
        } else {
          message.error('Invalid IFSC Code! Enter Correct IFSC Code or Fill Manually')
          this.formRef.current.setFieldsValue({
            bank_name: this.formRef.current.getFieldValue('bank_name') || '',
            branch_name: this.formRef.current.getFieldValue('branch_name') || '',
          })
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      message.error('Please enter IFSC code')
    }
  }

  onAddFromInvoicePage = () => {
    this.setState({ visible: true }, () => {
      this.formRef.current.setFieldsValue({ ...this.state.empty_bank })
    })
  }
  verifyUPI = async () => {
    if (!this.state.upi_id || this.state.upi_id == '') {
      message.error('Please enter upi id')
      return
    }
    this.setState({ upiLoading: true })
    let res = await getAPIData('payments', `validate_vpa?upi_id=${this.state.upi_id}`)
    res = res.response

    if (res.success) {
      this.setState({
        upiVerified: true,

        upiName: res.data.name,
      })
    } else {
      message.error(res.message)
    }
    this.setState({ upiLoading: false })
  }

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const columns = [
      {
        title: '#id',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'bank_name',
      },
      {
        title: 'Branch',
        dataIndex: 'branch_name',
      },
      {
        title: 'Account Number',
        dataIndex: 'bank_no',
      },
      {
        title: this.props?.user?.selectedCompany?.labels?.ifsc,
        dataIndex: 'ifsc',
      },
      {
        title: 'UPI ID',
        dataIndex: 'upi',
      },
      {
        title: 'Opening Balance',
        dataIndex: 'opening_balance',
      },
      {
        title: 'GPay/PhonePe Number',
        dataIndex: 'gpay_number',
      },
      {
        title: 'Actions',
        dataIndex: '',
        render: (text, currentData) => (
          <span>
            <a
              onClick={() =>
                this.setState({ visible: true, selectedBank: currentData }, () => {
                  this.formRef.current.setFieldsValue({
                    ...currentData,
                  })
                })
              }
              className="btn btn-table-sm btn-warning mr-2"
            >
              <i className="fe fe-edit mr-2" />
              Edit
            </a>
          </span>
        ),
        className: 'text-left',
      },
    ]

    return (
      <div>
        {/* {this.state.bankData == '' ? (
          <Spin indicator={antIcon} />
        ) : ( */}
        <div>
          <Drawer
            title={
              <div className="flex justify-between items-center">
                <span>Bank Details</span>
                <Button
                  type="primary"
                  onClick={() => this.formRef.current.submit()}
                  className="arrow-transition"
                >
                  {<FormattedMessage id="button.save&update" />}
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>
              </div>
            }
            placement="right"
            width={isMobile ? '90%' : '50%'}
            closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
            closable={() => this.setState({ visible: false, selectedBank: {}, upiVerified: false })}
            onClose={() => this.setState({ visible: false, selectedBank: {}, upiVerified: false })}
            open={this.state.visible}
            footer={[
              <Button
                type="primary"
                onClick={() => this.formRef.current.submit()}
                className="arrow-transition"
              >
                {<FormattedMessage id="button.save&update" />}
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>,
            ]}
          >
            <Card>
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef}
                name="bankForm"
                onFinish={this.onFinish}
                onFinishFailed={() =>
                  message.error({
                    content: 'Check all details again',
                    style: {
                      color: 'red',
                    },
                  })
                }
                className="addon-form"
                values={{
                  ...this.state.selectedBank,
                  confirm: this.state.selectedBank.bank_no,
                }}
              >
                <Form.Item
                  label={<FormattedMessage id="form.field.accountNumber" />}
                  className="col-12"
                  rules={[{ required: true, message: 'Please fill Bank Account No.' }]}
                  name="bank_no"
                >
                  <Input
                    placeholder="Bank Account No."
                    size="medium"
                    type="password"
                    onBlur={() => this.handleBlur('bank_no')}
                  />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label={<FormattedMessage id="form.field.confirmAccountNumber" />}
                  dependencies={['bank_no']}
                  className="col-12"
                  hasFeedback={this.state.touched.bank_no || this.state.touched.confirm}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your Bank Account No.',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('bank_no') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          'The Bank Account No.s that you entered do not match!',
                        )
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Confirm Bank Account No."
                    size="medium"
                    onBlur={() => this.handleBlur('confirm')}
                  />
                </Form.Item>
                <Form.Item
                  label={this.props?.user?.selectedCompany?.labels?.ifsc}
                  className="col-12"
                  name="ifsc"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill ' + this.props?.user?.selectedCompany?.labels?.ifsc,
                    },
                  ]}
                >
                  <Input
                    placeholder={this.props?.user?.selectedCompany?.labels?.ifsc}
                    size="medium"
                    addonAfter={
                      this.props?.user?.selectedCompany?.country_code == 'IN' && (
                        <Button
                          style={{ backgroundColor: '#f0f0f0' }}
                          className="px-2"
                          onClick={() => this.onFetchBankDetails()}
                        >
                          Fetch Bank Details
                        </Button>
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id="form.field.bankName" />}
                  name="bank_name"
                  rules={[{ required: true, message: 'Please fill Bank Name' }]}
                  className="col-12"
                >
                  <Input placeholder="Bank Name" size="medium" />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id="form.field.branchName" />}
                  name="branch_name"
                  rules={[{ required: true, message: 'Please fill Branch Name' }]}
                  className="col-12"
                >
                  <Input placeholder="Bank Branch Name" size="medium" />
                </Form.Item>

                {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <Form.Item
                    label={
                      <span>
                        <FormattedMessage id="form.field.upi" />
                        <span className="badge badge-gray ml-1">OPTIONAL</span>
                        <span>
                          <Tooltip
                            title={
                              <span>
                                <span>
                                  A UPI ID or VPA (Virtual Payment Address) is a unique ID that is
                                  used to make UPI payments in place of bank account details.
                                </span>
                                <br />
                                <span>
                                  This UPI ID will be used to generate{' '}
                                  <strong>Dynamic QR codes</strong> on the invoices and bills.
                                </span>
                              </span>
                            }
                          >
                            <i className="fa fa-info-circle text-gray-3 ml-1 mr-3" />
                          </Tooltip>
                        </span>
                      </span>
                    }
                    className="col-12"
                    name="upi"
                    extra={
                      <>
                        {this.state.upiVerified && (
                          <>
                            <Tooltip title="Verified">
                              <i className="fa-solid fa-circle-check text-success mr-2" />
                            </Tooltip>
                            <span className="font-weight-bold text-success">
                              {`${this.state.upiName} `}
                            </span>
                          </>
                        )}
                        This UPI ID will be used to generate <strong>Dynamic QR codes</strong> on
                        the invoices and bills.
                      </>
                    }
                  >
                    <Input
                      placeholder="UPI ID eg. username@okicici"
                      size="medium"
                      onChange={e => {
                        this.setState({ upi_id: e.target.value })
                        if (this.state.upiVerified) {
                          this.setState({ upiVerified: false })
                        }
                      }}
                      addonAfter={
                        <>
                          {!this.state.upiVerified ? (
                            <Button
                              className="px-2"
                              onClick={() => this.verifyUPI()}
                              loading={this.state.upiLoading}
                              style={{ backgroundColor: '#f0f0f0' }}
                            >
                              Verify UPI ID
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      }
                    />
                  </Form.Item>
                )}
                {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <Form.Item
                    label={
                      <span>
                        <FormattedMessage id="form.field.upiNumber" />{' '}
                        <span className="badge badge-gray ml-1">OPTIONAL</span>
                      </span>
                    }
                    className="col-12"
                    name="gpay_number"
                  >
                    <Input placeholder="GPay/PhonePe Number (Optional)" size="medium" />
                  </Form.Item>
                )}
                <Form.Item
                  label={
                    <span>
                      <FormattedMessage id="form.field.openingBalance" />{' '}
                      <span className="badge badge-gray ml-1">OPTIONAL</span>
                    </span>
                  }
                  className="col-12"
                  name="opening_balance"
                >
                  <Input placeholder="Opening Balance (Optional)" size="medium" />
                </Form.Item>
                <Form.Item
                  name="notes"
                  label={<FormattedMessage id="text.notes" />}
                  className="col-12"
                >
                  <TextArea placeholder="Beneficiary Name, SWIFT Code etc.." />
                </Form.Item>
                <Form.Item
                  name="is_default"
                  valuePropName="checked"
                  label={'Default'}
                  className="col-12"
                  extra={<>This will override you previous default bank</>}
                >
                  <Switch />
                </Form.Item>
                {/* <Button
                  type="submit"
                  className="btn btn-primary px-2 mt-2 mb-5"
                  onClick={() => this.formRef.current.submit()}
                >
                  {<FormattedMessage id="button.save&update" />}
                </Button> */}
              </Form>
            </Card>
          </Drawer>
        </div>
        {/* )} */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(BankForm)
export class EnhancedBankForm extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <BankForm {...this.props} />
  }
}
