import { Input, Select, message, Modal } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import CreateEwayBill from 'components/modal/ewaybill/create'
import { getPriceWithTax, getRowTotal, getTaxAmount } from 'components/other/calculation'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { history } from 'index'
import _ from 'lodash'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Document, Item, State } from 'redux/types'
import { getAPI, getAPIData } from 'services/jwt'

dayjs.extend(utc)

interface EwaybillProps {
  onRef: any
  onCancel: any
  dispatch: any
}

class EwayBill extends React.Component<EwaybillProps> {
  dateFormat = 'DD/MM/YYYY'
  timeout = 0
  state: any = {}
  updateShippingAddresses: any = () => {}
  constructor(props: any) {
    super(props)
    this.timeout = 0
    this.state = {
      supplyType: [],
      selectedSupplyType: '',
      subSupplyType: [],
      selectedSubSupplyType: '',
      transportMode: [],
      selectedTransportMode: '',
      subSupplyDesc: 'Other Document',
      documentType: 'INV',
      documentNo: '',
      documentDate: dayjs()
        .utcOffset(330)
        .format(this.dateFormat),
      distance: '',
      itemList: [],
      totalInvValue: 0,
      transactionType: 4,
      from_details: {},
      to_details: {},
      transporterName: '',
      transDocDate: dayjs()
        .utcOffset(330)
        .format(this.dateFormat),
      transDocNo: '',
      loadingSave: false,
      loadingPrint: false,
      flag: false,
      customerName: '',
      openDatePicker: false,
      description: false,
      extraDiscount: 0,
      extraDiscountPer: 0,
      document_custom_additional_charges: [],
      states: [],
      invoiceData: '',
      data: [],
      dataSource: [],
      count: 1,
      serial_number: '',
      due_date: dayjs()
        .utcOffset(330)
        .format(this.dateFormat),
      document_company_shipping_address: [],
      document_customer_shipping_address: [],
      edit: false,
      vehicleNumber: undefined,
      transporterGSTIN: undefined,
      visible: false,
      is_einvoice: false,
      new_hash_id: '',
      type: 'invoice',
      loading: true,
      showContent: false,
      show_ewaybill_header: true,
      selectedTransactionType: 4,
      recommendedTransactionType: 4,
      transaction_types: [],
      approx_distance: 0,
      isPartAChecked: true,
      isPartBChecked: true,
      partToUncheck: '',
      uncheckWarningShowed: false,
    }
    this.closeModal = this.closeModal.bind(this)
    this._updateShippingAddresses = this._updateShippingAddresses.bind(this)
    this.updateShippingAddresses = _.throttle(this._updateShippingAddresses, 2000)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  openConfirmationDialog = () => {
    this.setState({
      showModal: true,
    })
  }

  handleCheckboxChange = (e: CheckboxChangeEvent, part: any) => {
    if (!e.target.checked && !this.state.uncheckWarningShowed) {
      // To show the confirmation Modal only once
      this.setState(
        { partToUncheck: part, uncheckWarningShowed: true },
        this.openConfirmationDialog,
      )
    } else if (!e.target.checked) {
      this.setState({ partToUncheck: part }, () => {
        this.handleOk()
        this.setState({ [part]: e.target.checked })
      })
    } else {
      // When checking part B -> set transport mode to Road
      if (part == 'isPartBChecked') {
        this.setState({ selectedTransportMode: '1' })
      }
      this.setState({ [part]: e.target.checked })
    }
  }

  handleOk = () => {
    if (this.state.partToUncheck == 'isPartAChecked') {
      this.setState({
        transporterGSTIN: undefined,
        transporterName: '',
      })
    } else {
      this.setState({
        transDocNo: '',
        selectedTransportMode: undefined,
        vehicleNumber: undefined,
      })
    }
    this.setState({
      [this.state.partToUncheck]: false,
      showModal: false,
      partToUncheck: '',
    })
  }

  handleCancel = () => {
    this.setState({
      showModal: false,
      partToUncheck: '',
    })
  }

  updateCustomer = async (customer: any) => {
    if (customer.shipping_address.length) {
      this.setState({
        customer_details: customer,
        selected_customer_shipping_address: customer['shipping_address'][0],
      })
    } else {
      this.setState({
        customer_details: customer,
        selected_customer_shipping_address: {},
      })
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    var data = await getAPIData('ewaybills', 'get_details')

    if (data) {
      this.setState({
        supplyType: data.supply_type,
        subSupplyType: data.sub_supply_type,
        transportMode: data.transport_mode,
        selectedSupplyType: data.supply_type[0]['value'],
        selectedSubSupplyType: data.sub_supply_type[0]['value'],
        selectedTransportMode: data.transport_mode[0]['value'],
        taxes: data.taxes,
        transaction_types: data.transaction_types,
      })
    } else {
      message.error('Something went wrong. Please try again later.')
      history.push('/sales')
    }
  }

  componentWillUnmount() {
    // this.props.onRef(null)
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
    this.updateShippingAddresses.cancel()
  }

  getEwayBillData = async () => {
    if (this.state.visible) {
      const req = {
        new_hash_id: this.state.new_hash_id,
        document_type: this.state.type,
      }

      var data = await getAPI('ewaybills', 'get_invoice', req)
      if (data) {
        const results = data.invoice_details.items.map((row: Item, index: number) => ({
          ...row,
          key: index,
          discount: Number(row.discount),
          discount_value: Number((row.discount * getPriceWithTax(row) * row.qty) / 100),
          id: row.product_id,
          qtyinstock: row.qtyinstock,
          price_with_tax: getPriceWithTax(row),
          hsn_code: row.hsn_code,
          product_name: row.name,
          variant_name: row.variant_name,
          value:
            row.name +
            (row.variant_name == '' || row.variant_name == null ? '' : ' ' + row.variant_name),
        }))

        await this.updateCustomer(data.invoice_details.customer)

        this.setState(
          {
            data: {
              ...data,
              invoice_date: dayjs(data.invoice_details.invoice_date)
                .utcOffset(330)
                .format(this.dateFormat),
            },
            dataSource: results,
            count: results.length,
            documentNo: data.invoice_details.serial_number,
            documentDate: data.invoice_details.start_date.replace(/-/g, '/'),
            documentType: 'INV',
            from_details: data.invoice_details.from_details,
            to_details: data.invoice_details.to_details,
            total_invoice_value: data.invoice_details.total_amount,
            net_amount: data.invoice_details.net_amount,
            tax_amount: data.invoice_details.tax_amount,
            cess_amount: 0,
            loading: false,
          },
          () => {
            let selectedSubSupplyType =
              this.state.type == 'estimate' || this.state.type == 'purchase_return'
                ? this.state.subSupplyType.filter(
                    (item: { value: string }) => item.value == '8',
                  )[0]['value']
                : this.state.type == 'delivery_challan'
                ? this.state.subSupplyType[11]['value']
                : this.state.data.invoice_details.is_export
                ? this.state.subSupplyType[1]['value']
                : this.state.subSupplyType[0]['value']

            if (this.state.type == 'purchase' && this.state.data.invoice_details.is_export) {
              selectedSubSupplyType = '2'
            } else if (
              this.state.type == 'purchase' &&
              !this.state.data.invoice_details.is_export
            ) {
              selectedSubSupplyType = '1'
            } else if (this.state.type == 'sales_return') {
              selectedSubSupplyType = '7'
            }

            let seletedSupplyType =
              this.state.type == 'sales_return' || this.state.type == 'purchase' ? 'I' : 'O'
            this.setState(
              {
                document_company_shipping_address: data.invoice_details.from_details.shipping_addresses.filter(
                  (item: { addr_id: number }) =>
                    item.addr_id == data.invoice_details.company_shipping_addr_id,
                ),
                document_customer_shipping_address: data.invoice_details.to_details.shipping_address.filter(
                  (item: { addr_id: number }) =>
                    item.addr_id == data.invoice_details.customer_shipping_addr_id,
                ),
                extraDiscount: data.invoice_details.extra_discount,

                document_custom_additional_charges:
                  data.invoice_details.document_custom_additional_charges,
                selectedSubSupplyType: selectedSubSupplyType,
                selectedSupplyType: seletedSupplyType,
              },
              () => {
                if (
                  this.state.document_customer_shipping_address.length > 0 &&
                  this.state.document_company_shipping_address.length > 0
                ) {
                  this.getDistance()
                }
                this.updateTransactionType()

                this.setState({
                  document_custom_additional_charges:
                    data.invoice_details.document_custom_additional_charges,
                })
              },
            )
          },
        )
      }
    }
  }

  updateTransactionType = () => {
    let transaction_type = 4
    try {
      const { invoice_details } = this.state.data
      const gstin = invoice_details['from_details']['gstin']

      let company_state_code = gstin.slice(0, 2) ? gstin.slice(0, 2) : -1
      let from_state_code = company_state_code
      let act_to_state_code = 96
      let to_state_code = 96

      try {
        from_state_code = parseInt(
          invoice_details['from_details']['shipping_address']['state'].slice(0, 2),
          10,
        )
      } catch (error) {
        from_state_code = parseInt(invoice_details['from_details']['gstin'].slice(0, 2), 10)
      }

      try {
        if (invoice_details['to_details']['gstin'].length !== 15) {
          if (invoice_details['to_details']['shipping']['state'] !== null) {
            act_to_state_code = parseInt(
              invoice_details['to_details']['shipping']['state'].slice(0, 2),
              10,
            )
          }
        } else {
          if (
            invoice_details['to_details']['shipping']['state'] !== null &&
            invoice_details['to_details']['shipping']['state'] !== 'OTHERTERRITORY'
          ) {
            act_to_state_code = parseInt(
              invoice_details['to_details']['shipping']['state'].slice(0, 2),
              10,
            )
          } else {
            act_to_state_code = parseInt(invoice_details['to_details']['gstin'].slice(0, 2), 10)
          }
        }
      } catch (error) {
        message.warning('Warning: Please Check Dispatch To GST Numbers or State')
      }

      try {
        if (invoice_details['to_details']['gstin'].length == 15) {
          to_state_code = parseInt(invoice_details['to_details']['gstin'].slice(0, 2), 10)
        } else if (invoice_details['to_details']['billing_state'] !== null) {
          to_state_code = parseInt(invoice_details['to_details']['billing_state'].slice(0, 2), 10)
        } else {
          to_state_code = parseInt(invoice_details['from_details']['gstin'].slice(0, 2), 10)
        }
      } catch (error) {
        message.warning('Warning: Please Check Dispatch To GST Numbers or State')
      }

      if (invoice_details['to_details']['shipping']['state'] === 'OTHERTERRITORY') {
        to_state_code = 97
        act_to_state_code = 97
      }

      if (company_state_code == from_state_code && to_state_code == act_to_state_code) {
        transaction_type = 1
      }

      if (to_state_code != act_to_state_code) {
        transaction_type = 2
      }

      if (company_state_code != from_state_code) {
        transaction_type = 3
      }

      if (company_state_code != from_state_code && to_state_code != act_to_state_code) {
        transaction_type = 4
      }
    } catch (error) {
      transaction_type = 4
    }

    this.setState({
      selectedTransactionType: transaction_type,
      recommendedTransactionType: transaction_type,
    })
  }

  getData = async () => {
    if (this.state.visible) {
      this.setState({
        loading: true,
      })
      this.getEwayBillData()
    }
  }

  _updateShippingAddresses = async (data: Document) => {
    var req = {
      ...data.from_details.billing_address,
    }

    if (req.addr_id != -1) {
      var add_shipping = await getAPI('v2/company', 'add_shipping_address', req)
      if (add_shipping.success) {
        this.getEwayBillData()
      }
    }
  }

  handleDelete = (key: string) => {
    const dataSource = [...this.state.dataSource]
    const newData = dataSource.filter(item => item.key !== key)
    this.setState({
      dataSource: newData,
    })
  }

  handleSave = (row: any) => {
    if (row.title == 'Price with Tax') {
      row.price = (row.price_with_tax / (1 + row.tax / 100)).toFixed(2)
    } else if (row.title == 'Unit Price') {
      row.price_with_tax = getPriceWithTax(row)
    }

    const newData = [...this.state.dataSource]
    const index = newData.findIndex(item => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData,
    })
  }

  getNetAmount = (record: Item) => {
    return record.price * record.quantity - (record.price * record.quantity * record.discount) / 100
  }

  getTaxAmount = (record: Item) => {
    return (this.getNetAmount(record) * record.tax) / 100
  }

  getRowTotal = (record: Item) => {
    return Math.round(this.getNetAmount(record) + this.getTaxAmount(record))
  }

  getTaxPrice = (item: Item) => {
    return Math.round(item.price * (1 + item.tax / 100))
  }

  save = async () => {
    if (!this.state.is_einvoice) {
      if (this.state.dataSource.length == 0) {
        message.error('There is an error in your product list')
      } else {
        this.setState({ loadingSave: true })

        let partA = {
          transporterId:
            this.state.transporterGSTIN == undefined ? '' : this.state.transporterGSTIN,
          transporterName: this.state.transporterName,
        }

        let partB = {
          transMode:
            this.state.selectedTransportMode == '1' && !this.state.vehicleNumber
              ? ''
              : this.state.selectedTransportMode,
          vehicleNo: this.state.vehicleNumber == undefined ? '' : this.state.vehicleNumber,
          transDistance: this.state.distance,
          transDocNo: this.state.transDocNo,
          transDocDate: this.state.transDocDate,
        }

        if (!this.state.isPartAChecked) {
          partA = {
            transporterId: '',
            transporterName: '',
          }
        }

        if (!this.state.isPartBChecked) {
          partB = {
            ...partB,
            transMode: '',
            vehicleNo: '',
            transDistance: '',
            transDocNo: '',
          }
        }

        const req = {
          ...partA,
          ...partB,
          document_type: this.state.data.invoice_details.document_type,
          new_hash_id: this.state.data.invoice_details.new_hash_id,
          supplyType: this.state.selectedSupplyType,
          subSupplyType: this.state.selectedSubSupplyType,
          dispatch_from_addr_id: this.state.data.invoice_details.company_shipping_addr_id,
          dispatch_to_addr_id: this.state.data.invoice_details.customer_shipping_addr_id,
          transactionType: this.state.selectedTransactionType,
          subSupplyDesc: this.state.subSupplyDesc,
        }

        if (this.state.selectedSubSupplyType != '8') {
          delete req.subSupplyDesc
        }
        try {
          var data = { success: false, message: '' }
          if (this.state.edit) {
            data = await getAPI('ewaybills', 'edit', req)
          } else {
            data = await getAPI('ewaybills', 'create', req)
          }

          if (data.success) {
            message.success(data.message)

            history.push('/ewaybills')
          }
          this.setState({ loadingSave: false })
        } catch (e) {
          console.log(e)
        }
      }
      this.setState({ loadingSave: false })
    } else {
      this.onEinvoiceToEwaybill()
    }
  }

  onTransporterDateChange = (_: Dayjs, string: string | string[]) => {
    this.setState({ transDocDate: string })
  }

  dataUpdate = (data: any) => {
    this.setState({ dataSource: data })
  }

  getTotal = () => {
    var sum = 0
    this.state.dataSource.map((item: Item) => {
      sum = sum + getRowTotal(item)
    })
    return Math.round(sum)
  }

  handleChange = (value: any, type: string) => {
    if (type == 'supply_type') {
      this.setState({ selectedSupplyType: value })
    } else if (type == 'sub_supply_type') {
      this.setState({ selectedSubSupplyType: value })
    } else if (type == 'transport_mode') {
      this.setState({ selectedTransportMode: value })
    } else if (type == 'distance') {
      const distance = value.target.value
      const pattern = /^[0-9]*$/
      if (!pattern.test(distance)) {
        message.error('Distance must be a Number, Decimals Not Allowed')
        return
      }
      this.setState({ distance: value.target.value })
    } else if (type == 'transporter_gstin') {
      try {
        this.setState({ transporterGSTIN: value.target.value })
      } catch (e) {
        this.setState({ transporterGSTIN: value })
      }
    } else if (type == 'vehicle_number') {
      try {
        this.setState({ vehicleNumber: value.target.value })
      } catch (e) {
        this.setState({ vehicleNumber: value })
      }
    } else if (type == 'transporter_doc_no') {
      this.setState({ transDocNo: value.target.value })
    } else if (type == 'transporter_name') {
      this.setState({ transporterName: value.target ? value.target.value : value })
    } else if (type == 'sub_supply_type_desc') {
      this.setState({ subSupplyDesc: value.target.value })
    } else if (type == 'transaction_type') {
      this.setState({ selectedTransactionType: value })
    }
  }

  showModal(document_type: string, hash_id: string, is_einvoice: boolean) {
    this.setState(
      {
        visible: true,
        new_hash_id: hash_id,
        type: document_type,
        loading: true,
        is_einvoice: is_einvoice,
        show_ewaybill_header: !is_einvoice,
      },
      () => {
        this.getEwayBillData()
      },
    )
  }

  showEinvoiceModal(document_type: string, hash_id: string) {
    this.setState({
      is_einvoice: true,
      visible: true,
      new_hash_id: hash_id,
      type: document_type,
    })
  }

  onEinvoiceToEwaybill = async () => {
    this.setState({ loadingSave: true })
    const req = {
      Distance: this.state.distance,
      TransMode: this.state.selectedTransportMode,
      TransId: this.state.transporterGSTIN,
      TransName: this.state.transporterName,
      TransDocDt: this.state.transDocDate,
      TransDocNo: this.state.transDocNo == undefined ? '' : this.state.transDocNo,
      VehNo: this.state.vehicleNumber == undefined ? '' : this.state.vehicleNumber,
      document_type: this.state.type,
      new_hash_id: this.state.new_hash_id,
      // VehType: 'R',
    }

    //remove empty fields
    // Object.keys(req).forEach(key => req[key] == '' && delete req[key])

    const data = await getAPI('einvoice', 'einvoice_to_ewaybill', req)
    if (data.success) {
      message.success(data.message)
      this.setState({ loadingSave: false, is_einvoice: false }, () => {
        history.push('/ewaybills')
      })
    } else {
      this.setState({ loadingSave: false })
    }
  }

  getDistance = async () => {
    const req = {
      pincode1: this.state.document_company_shipping_address[0]['pincode'],
      pincode2: this.state.document_customer_shipping_address[0]['pincode'],
      source: 'ewaybill',
    }
    const data = await getAPI('ewaybills', 'get_distance', req)
    if (data.success) {
      this.setState({ approx_distance: data.distance, distance: data.is_old ? data.distance : '' })
    }
  }

  closeModal() {
    this.setState({ visible: false, dataSource: [] }, () => {
      this.props.onCancel()
    })
  }

  handleEInvoice = () => {
    this.setState({ is_einvoice: !this.state.is_einvoice })
  }

  render() {
    return (
      <>
        <div className="">
          {this.state.visible && (
            <>
              <CreateEwayBill
                visible={this.state.visible}
                closeModal={this.closeModal}
                edit={this.state.edit}
                loadingSave={this.state.loadingSave}
                save={this.save}
                loading={this.state.loading}
                supplyType={this.state.supplyType}
                subSupplyType={this.state.subSupplyType}
                selectedSupplyType={this.state.selectedSupplyType}
                selectedSubSupplyType={this.state.selectedSubSupplyType}
                handleChange={(e: any, type: string) => this.handleChange(e, type)}
                data={this.state.data}
                dataSource={this.state.dataSource}
                document_company_shipping_address={this.state.document_company_shipping_address}
                document_customer_shipping_address={this.state.document_customer_shipping_address}
                distance={this.state.distance}
                approx_distance={this.state.approx_distance}
                transportMode={this.state.transportMode}
                selectedTransportMode={this.state.selectedTransportMode}
                vehicleNumber={this.state.vehicleNumber}
                transporterName={this.state.transporterName}
                transporterGSTIN={this.state.transporterGSTIN}
                transDocNo={this.state.transDocNo}
                transDocDate={this.state.transDocDate}
                document_custom_additional_charges={this.state.document_custom_additional_charges}
                onTransporterDateChange={(date: Dayjs, string: string | string[]) =>
                  this.onTransporterDateChange(date, string)
                }
                extraDiscount={this.state.extraDiscount}
                getEwayBillData={() => this.getEwayBillData()}
                is_einvoice={this.state.is_einvoice}
                dispatch={() => this.props.dispatch}
                handleEInvoice={this.handleEInvoice}
                show_ewaybill_header={!this.state.show_ewaybill_header}
                transaction_types={this.state.transaction_types}
                selectedTransactionType={this.state.selectedTransactionType}
                recommendedTransactionType={this.state.recommendedTransactionType}
                isPartAChecked={this.state.isPartAChecked}
                isPartBChecked={this.state.isPartBChecked}
                handleCheckboxChange={this.handleCheckboxChange}
              />
              <Modal
                title="Are you sure?"
                open={this.state.showModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <p>Unchecking will remove the entered details of this section</p>
              </Modal>
            </>
          )}
        </div>
      </>
    )
  }
}

function mapStateToProps(state: State) {
  return { ...state }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(EwayBill)

export class EnhancedEwayBill extends React.Component<EwaybillProps> {
  constructor(props: any) {
    super(props)
  }
  render() {
    return <EwayBill {...this.props} />
  }
}
