import { InboxOutlined } from '@ant-design/icons'
import {
  Affix,
  Button,
  Card,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tabs,
  Tooltip,
  Upload,
  message,
} from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import AddPriceList from 'components/modal/addPriceList'
import { EnhancedBulkUpload } from 'components/modal/upload'
import FooterComponent from 'components/other/FooterComponent'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { downloadAPI, getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
import store from 'store'
const { Dragger } = Upload
import Experience from 'components/other/experience'
import AgGridTable from 'components/agGrid/agGridTable'
import InfoComponent from 'components/badges/InfoComponent'
import { history } from 'index'

export class PriceListForm extends Component {
  formRef = React.createRef()

  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)
    this.gridRef = React.createRef()
    this.state = {
      visible: false,
      upload_visible: false,
      price_list_name: '',
      dataSource: [],
      data: {
        products: [],
        records: {},
        modified_products: 0,
        have_error: false,
        columns: [],
      },
      error: '',
      is_multiple: false,
      loading: false,
      showConfimationModal: false,
      filter: 'all',
      price_list_id: 0,
      table_loading: false,
      filter: 'all',
      updatedCount: 0,
      uploadData: false,
      search: '',
      timeout: null,
      showAddModal: false,
      selectedSearch: this.props.selectedSearch || '',
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    this.onFill()
  }

  onImportDataChangePriceList = data => {
    const results = data.products.map((row, index) => ({
      ...row,
      key: index,
    }))
    this.setState({
      priceListConfirmFlag: true,
      importData: results,
      price_list_id: data.price_list_id,
    })
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    this.setState({ table_loading: true })
    if (this.state.price_list_name == '' && !this.state.is_multiple) {
      message.error('Please give a name to the Price List')
      return true
    }
    const form = new FormData()
    form.append('file', file)
    form.append('price_list_name', this.state.price_list_name)
    form.append('is_multiple', this.state.is_multiple)
    form.append('upload_type', 'price_list')
    const data = await getMultipartAPI('upload', 'uploads', form)
    if (data) {
      if (this.state.is_multiple) {
        if (data.success) {
          this.setState({
            showConfimationModal: true,
            data: {
              products: data.products,
              records: JSON.parse(data.records),
              modified_products: data.modified_products,
              have_error: data.have_error,
            },
            visible: false,
            products: data.products.filter(item => !item.skip),
          })
          this.closeTableModal()
        }
      } else {
        if (data.success == undefined) {
          this.setState({ error: 'Something went wrong. Please try again later.' })
        } else if (data.success) {
          message.success('Price List added')
          this.setState(
            { error: '', visible: false, showAddModal: false },
            // () => {
            //   if (data.message != 'Done') {
            //     this.onImportDataChangePriceList(data)
            //   }
            // },
          )
          this.closeTableModal()
          // this.props.onUpdatePriceList()
        }
      }
      this.setState({ uploadData: false })
    } else {
      message.error('Something went wrong. Please try again later.')
    }
    this.setState({ table_loading: false })
  }

  addPriceList = async price_list_name => {
    this.setState({ loading: true })
    const data = await getAPI('product', 'add_price_list', { price_list_name: price_list_name })
    if (data && data.success) {
      message.success('Price List added')
      this.setState({
        dataSource: [data.price_list, ...this.state.dataSource],
        showAddModal: false,
      })
    }
    this.setState({ loading: false })
  }

  onFill = async data => {
    var data = await getAPI('product', 'get_price_lists', { search: this.state.search })
    this.setState({ visible: true, dataSource: data?.price_lists }, () => {
      this.props.onTotalChange('totalPriceLists', data?.price_lists?.length)
    })
  }

  handleDelete = async (price_list_id, company_id) => {
    var req = {
      price_list_id: price_list_id,
      company_id: company_id,
    }
    var data = await getAPI('product', 'delete_price_list', req)
    if (data.success) {
      const newData = this.state.dataSource.filter(item => item.price_list_id !== price_list_id)
      this.setState({ dataSource: newData }, () => {
        message.success('Price List Deleted')
      })
      this.props.onUpdatePriceList()
    }
  }

  handleDownload = async (price_list_id, getFileData = false) => {
    var req = {
      price_list_id: price_list_id,
      is_multiple: this.state.is_multiple,
      get_file_data: getFileData,
    }
    var data = await downloadAPI('product', 'download_price_list', req)
    if (getFileData) {
      return data
    }
  }

  handleDone = () => {
    this.setState({ visible: false })
  }

  async priceListCallConfirmAPI(flag) {
    const req = {
      is_confirm: flag,
      price_list_id: this.state.price_list_id,
      is_multiple: this.state.is_multiple,
    }
    const data = await getAPI('product', 'confirm_price_list', req)

    if (data.success) {
      flag ? message.success('Successfully updated') : message.error('Upload Cancelled')
      this.setState({
        loading: false,
        showConfimationModal: false,
        priceListConfirmFlag: false,
        upload_visible: false,
        price_list_name: '',
        is_multiple: false,
      })

      this.onFill()
    }
  }

  getPrices = async price_list_id => {
    this.setState({ table_loading: true })
    this.setState({
      price_list_name: this.state.dataSource.find(item => item.price_list_id == price_list_id).name,
    })
    const data = await this.handleDownload(price_list_id, true)

    if (data && data.success) {
      let columns = data.columns.map((item, index) => {
        return {
          title: item,
          dataIndex: item,
          key: 'id',
          width: '150px',
          filter: true,
          editable: [
            'Product',
            'Variant',
            'Tax',
            'Discount %',
            'Price',
            'Price with Tax',
            'Discount',
          ].includes(item)
            ? false
            : true,
          cellEditor:
            item.includes('Tax') && !item.includes('Price') ? 'taxEditor' : 'agTextCellEditor',
        }
      })

      columns = [
        ...columns,
        {
          title: 'status',
          dataIndex: 'status',
          hide: true,
          filter: true,
        },
      ]

      this.setState({
        data: data,
        columns: columns,
        showTableModal: true,
        price_list_id: price_list_id,
        is_multiple: false,
        selectedSearch: this.props.selectedSearch || '',
      })
    }
    this.setState({ table_loading: false })
  }

  updatePrices = (data, key, newValue) => {
    if (newValue == undefined || newValue == null) {
      newValue = ''
    }

    let tax_key = this.state.price_list_name + ' ' + 'Tax'
    let price_key = this.state.price_list_name + ' ' + 'Price'
    let price_with_tax_key = this.state.price_list_name + ' ' + 'Price with Tax'

    this.setState({ changesDetected: true, updatedCount: this.state.updatedCount + 1 })
    if (key == tax_key) {
      data[price_with_tax_key] =
        parseFloat(data[price_key]) * (1 + parseFloat(newValue) / 100).toFixed(2)
    } else if (key == price_key) {
      data[price_with_tax_key] =
        parseFloat(newValue) * (1 + parseFloat(data[tax_key]) / 100).toFixed(2)
    } else if (key == price_with_tax_key) {
      data[price_key] = (parseFloat(newValue) * 100) / (100 + parseFloat(data[tax_key])).toFixed(2)
    }
    data[key] = newValue
    data['status'] = 'update'
    this.gridRef.updateRowValue(data)
  }

  setStatusFilter = (selectedSearch = undefined) => {
    let condition = [
      {
        filterType: 'text',
        colId: 'Product',
        type: 'contains',
        filter: selectedSearch,
      },
    ]
    let has_variant = this.state.columns.find(item => item.title == 'Variant')
    if (has_variant) {
      condition.push({
        filterType: 'text',
        colId: 'Variant',
        type: 'contains',
        filter: selectedSearch,
      })
    }
    if (!selectedSearch) {
      if (this.state.filter == 'all') {
        this.gridRef.resetFilter()
      } else {
        this.gridRef.setAdvancedFilters({
          filterType: 'text',
          colId: 'status',
          type: 'contains',
          filter: 'update',
        })
      }
    } else {
      if (this.state.filter == 'update') {
        this.gridRef.setAdvancedFilters({
          filterType: 'join',
          type: 'AND',
          conditions: [
            {
              filterType: 'join',
              type: 'OR',
              conditions: condition,
            },
            {
              filterType: 'text',
              colId: 'status',
              type: 'contains',
              filter: this.state.filter == 'update' ? 'update' : '',
            },
          ],
        })
      } else {
        this.gridRef.setAdvancedFilters({
          filterType: 'join',
          type: 'OR',
          conditions: condition,
        })
      }
    }

    if (this.state.uploadData && this.state.updatedCount > 0 && this.state.filter == 'update') {
      this.onConfirmUpdates()
    }
  }

  onConfirmUpdates = async () => {
    await this.customRequest({
      onSuccess: () => {},
      onError: () => {},
      file: this.gridRef.getDataAsExcel(),
    })
    this.priceListCallConfirmAPI(true)
  }

  closeTableModal = () => {
    this.setState({ showTableModal: false, uploadData: false, updatedCount: 0 })
  }

  render() {
    const priceListColumns = [
      {
        title: <FormattedMessage id="table.product" />,
        dataIndex: 'product_name',
        key: 'product_name',
      },
      {
        title: <FormattedMessage id="table.price" />,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: <FormattedMessage id="table.priceWithTax" />,
        dataIndex: 'price_with_tax',
        key: 'price_with_tax',
      },
      {
        title: <FormattedMessage id="table.discount" />,
        dataIndex: 'discount',
        key: 'discount',
        render: text => (
          <a className="" href="#" onClick={e => e.preventDefault()}>
            {text}
          </a>
        ),
        responsive: ['md'],
      },
      {
        title: <FormattedMessage id="table.tax" />,
        dataIndex: 'tax',
        key: 'product_id',
        render: text => (
          <a className="" href="#" onClick={e => e.preventDefault()}>
            {text}
          </a>
        ),
        responsive: ['md'],
      },
    ]
    let windowHeight = window.innerHeight
    const columns = [
      {
        title: 'Price List',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (_, data) => (
          <>
            {/* <Button
              size="small"
              onClick={() =>
                this.setState({
                  upload_visible: true,
                  price_list_name: data.name,
                  price_list_id: data.price_list_id,
                  is_multiple: false,
                })
              }
              className="mr-2 hidden sm:inline"
            >
              <i className="fa-solid fa-arrow-up mr-2" />{' '}
              <FormattedMessage id="button.bulkUpload" />
              {store.get('paid') == 0 && <ProBadge />}
            </Button> */}
            <Button
              type="warning"
              size="small"
              className="mr-2"
              onClick={() => this.getPrices(data.price_list_id)}
            >
              <i className="fa-solid fa-pen mr-2" /> Change Prices
            </Button>

            <Button
              type="primary"
              size="small"
              className="mr-2"
              onClick={() =>
                this.setState({ is_multiple: false }, () => this.handleDownload(data.price_list_id))
              }
            >
              <i className="fa-solid fa-arrow-down mr-2" /> Download
            </Button>

            <Popconfirm
              title="Sure to delete? Cannot undo once deleted."
              onConfirm={() => this.handleDelete(data.price_list_id, data.company_id)}
            >
              <Button size="small" type="danger" className="">
                <i className="fe fe-trash-2" /> Delete
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ]

    const confirmColumns = [
      {
        title: 'Product Name',
        dataIndex: 'Product',
        width: 200,
        key: 'Product',
        render: (text, record) => (
          <Tooltip title={record.message}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Variant Name',
        dataIndex: 'Variant',
        width: 200,
        key: 'Variant',
        render: (text, record) => (
          <Tooltip title={record.message}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Tax',
        dataIndex: 'Tax',
        width: 200,
        key: 'Tax',
        render: (text, record) => (
          <Tooltip title={record.message}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Discount',
        dataIndex: 'Discount',
        width: 200,
        key: 'Discount',
        render: (text, record) => (
          <Tooltip title={record.message}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        width: 200,
        key: 'Price',
        render: (text, record) => (
          <Tooltip title={record.message}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Price with Tax',
        dataIndex: 'Price with Tax',
        width: 200,
        key: 'Price with Tax',
        render: (text, record) => (
          <Tooltip title={record.message}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Message',
        dataIndex: 'message',
        width: 200,
        key: 'message',
        render: (text, record) => (
          <Tooltip title={record.message}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
    ]
    return (
      <>
        {this.state.priceListConfirmFlag == true && (
          <React.Fragment>
            <EnhancedBulkUpload
              columns={priceListColumns}
              data={this.state.importData}
              title={'Confirm Uploading PriceList'}
              callConfirmAPI={flag => {
                this.priceListCallConfirmAPI(flag)
              }}
              loading={this.state.loading}
              loadingCancel={this.state.loadingCancel}
              confirmFlag={this.state.priceListConfirmFlag}
              onClose={() => {
                this.setState({ priceListConfirmFlag: false })
              }}
            />
          </React.Fragment>
        )}
        {store.get('paid') == 0 && !window.location.hostname.includes('mac.getswipe.in') ? (
          <>
            <Experience type={'price_list'} toUpgrade={true} />
          </>
        ) : (
          <>
            <div className="flex items-center justify-between">
              <div>
                <Input.Group compact>
                  <Input
                    prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
                    type="text"
                    className=""
                    style={{ width: 360 }}
                    data-beacon-id="search_products_item_wise"
                    placeholder="Search price lists"
                    onChange={e => {
                      clearTimeout(this.state.timeout)
                      this.setState({
                        search: e.target.value,
                        timeout: setTimeout(this.onFill, 1200),
                      })
                    }}
                  />
                </Input.Group>
              </div>
              <div className="flex">
                <Button
                  className="mr-2 font-weight-bold"
                  type="secondary"
                  onClick={() => history.push('/upload?tab=price_list')}
                >
                  <i className="fa-solid fa-arrow-up mr-2" />
                  Upload
                </Button>

                <AddPriceList onPriceListAdded={this.onFill} />
              </div>
            </div>

            <div>
              <Table
                size="small"
                dataSource={this.state.dataSource}
                columns={columns}
                locale={{
                  emptyText: (
                    <>
                      {!this.state.loading && (
                        <div className="flex justify-center items-center w-full h-full">
                          <Empty
                            className="m-4"
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{
                              height: 256,
                            }}
                            description={
                              <span className="font-size-16 text-gray-500 font-weight-bold">
                                You have no pricelists.
                              </span>
                            }
                          />
                        </div>
                      )}
                    </>
                  ),
                }}
              />
            </div>
          </>
        )}
        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />

        {/* upload price list file modal */}
        <Modal
          open={this.state.upload_visible}
          footer={null}
          width={isMobile ? '100%' : '50%'}
          onCancel={() =>
            this.setState({ upload_visible: false, price_list_name: '', is_multiple: false })
          }
          onClose={() =>
            this.setState({ upload_visible: false, price_list_name: '', is_multiple: false })
          }
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          destroyOnClose
        >
          <h6>
            <span className="mr-3 text-gray-500 font-weight-bold">Upload the excel file here</span>
          </h6>

          <Dragger
            customRequest={this.customRequest}
            disabled={this.state.price_list_name == '' && !this.state.is_multiple ? true : false}
            className="mb-4"
            multiple={false}
            maxCount={1}
            status={Object.keys(this.state.data.records).length > 0 ? 'done' : 'uploading'}
          >
            <div className="flex flex-col justify-center items-center">
              <Button
                className=" mt-2 mb-4 items-center"
                onClick={e => {
                  e.stopPropagation()
                  this.handleDownload(0)
                }}
                type="primary"
              >
                Download Price List Template
              </Button>
              {!this.state.is_multiple && (
                <Select
                  onChange={value => this.setState({ price_list_name: value || '' })}
                  autoFocus
                  style={{ width: 200 }}
                  placeholder="Select Price List"
                  onClick={e => e.stopPropagation()}
                  className="mb-3"
                  allowClear
                >
                  {this.state.dataSource.map(item => (
                    <Select.Option value={item.name}>{item.name}</Select.Option>
                  ))}
                </Select>
              )}
            </div>

            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Once uploaded click on Add Pricelist button for saving.
            </p>
          </Dragger>
        </Modal>

        {/* AG Grid Table Modal */}
        <Modal
          open={this.state.showTableModal}
          maskClosable={false}
          title={'Change Prices'}
          description
          width={'90vw'}
          closeIcon={<i className="fa-solid fa-xmark"></i>}
          onCancel={() => {
            this.closeTableModal()
            this.setState({
              showTableModal: false,
              upload_visible: false,
              price_list_name: '',
              is_multiple: false,
            })
          }}
          destroyOnClose
          footer={
            <>
              <Button key="back" onClick={() => this.closeTableModal()}>
                Close
              </Button>{' '}
              <Tooltip
                title={
                  this.state.updatedCount == 0 ? 'No changes detected' : 'Confirm the changes made'
                }
              >
                <Button
                  className="font-weight-bold"
                  type={this.state.updatedCount == 0 ? '' : 'success'}
                  onClick={() => {
                    this.setState({ filter: 'update', uploadData: true, table_loading: true })
                  }}
                  loading={this.state.table_loading}
                  disabled={this.state.updatedCount == 0}
                >
                  <i className="fa-solid fa-circle-check mr-2" />
                  Confirm Updates
                  {this.state.updatedCount == 0 ? '' : ` (${this.state.updatedCount})`}
                </Button>{' '}
              </Tooltip>
            </>
          }
        >
          <InfoComponent
            type="warning"
            description="Updated records data only will be saved in the system."
          />
          <Tabs
            activeKey={this.state.filter == 'update' ? '1' : '0'}
            key={this.state.filter == 'update' ? '1' : '0'}
            tabBarExtraContent={
              <Input
                placeholder="Search Product or Variant"
                value={this.state.selectedSearch}
                onChange={e =>
                  // this.gridRef.setAdvancedFilters({
                  //   filterType: 'join',
                  //   type: 'OR',
                  //   conditions: [
                  //     {
                  //       filterType: 'text',
                  //       colId: 'Product',
                  //       type: 'contains',
                  //       filter: e.target.value,
                  //     },
                  //     {
                  //       filterType: 'text',
                  //       colId: 'Variant',
                  //       type: 'contains',
                  //       filter: e.target.value,
                  //     },
                  //   ],
                  // })
                  this.setState({ selectedSearch: e.target.value }, () =>
                    this.setStatusFilter(e.target.value),
                  )
                }
                style={{ width: 400 }}
                className="my-2"
              />
            }
            onChange={key => {
              if (key == '1') {
                this.setState({ filter: 'update' })
              } else {
                this.setState({ filter: 'all' })
              }
              // this.setStatusFilter()
            }}
          >
            <TabPane tab="All Records" key="0"></TabPane>
            <TabPane tab="Updated Records" key="1"></TabPane>
          </Tabs>

          <AgGridTable
            gridKey={'updateTable' + this.state.filter}
            onReady={async event => {
              this.setStatusFilter(this.state.selectedSearch)
            }}
            dataSource={this.state.data.records}
            columns={this.state.columns}
            onRef={ref => (this.gridRef = ref)}
            rowId={false}
            readOnlyEdit={true}
            editable={true}
            tooltipText={'message'}
            onUpdateValue={this.updatePrices}
            enableAdvancedFilter={true}
          />
        </Modal>

        <Modal
          open={this.state.showConfimationModal}
          maskClosable={false}
          title={'Approve modified values?'}
          description
          width={'75vw'}
          closeIcon={<i className="fa-solid mr-3 fa-xmark"></i>}
          onCancel={() =>
            this.setState({
              showConfimationModal: false,
              upload_visible: false,
              price_list_name: '',
              is_multiple: false,
            })
          }
          destroyOnClose
          footer={
            <div className="flex justify-between">
              <div className="flex items-center ml-2 font-size-12">
                <div className="mr-auto">
                  <span className=" text-gray-700 1 mr-2">
                    <div
                      className="bg-lgreen mr-2 inline-block border-gray-2"
                      style={{ width: '10px', height: '10px' }}
                    />{' '}
                    Success
                  </span>
                  <span className=" text-gray-700 1 mr-2 ">
                    <div
                      className="bg-warning mr-2 inline-block border-gray-2"
                      style={{ width: '10px', height: '10px' }}
                    />{' '}
                    Updated
                  </span>
                  <span className=" text-gray-700 1 mr-2 ">
                    <div
                      className="bg-negative mr-2 inline-block border-gray-2"
                      style={{ width: '10px', height: '10px' }}
                    />{' '}
                    Delete
                  </span>
                  <span className=" text-gray-700 1 mr-2 ">
                    <div
                      className="bg-danger mr-2 inline-block border-gray-2"
                      style={{ width: '10px', height: '10px' }}
                    />{' '}
                    Error
                  </span>
                </div>
              </div>
              <div className="float-right">
                <Button
                  loading={this.state.loading}
                  onClick={e => this.priceListCallConfirmAPI(false)}
                  className="mr-2"
                  type="danger"
                >
                  <i className="fa fa-times mr-2" /> <FormattedMessage id="button.cancel" />
                </Button>
                {Object.keys(this.state.data.records).length != 0 && (
                  <Popconfirm
                    title="Confirm Moditification? "
                    description={
                      this.state.data.have_error
                        ? 'There are some errors in the file. Do you want to modify successful records?'
                        : ''
                    }
                    onConfirm={() => this.priceListCallConfirmAPI(true)}
                  >
                    <Button
                      loading={this.state.loading}
                      type="success"
                      className="font-weight-bold"
                    >
                      <i className="fa-solid fa-circle-check mr-2" /> Confirm
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </div>
          }
        >
          {this.state.showConfimationModal && (
            <>
              <Tabs
                defaultActiveKey="0"
                tabBarExtraContent={
                  <Select
                    defaultValue={this.state.filter}
                    style={{ width: 150 }}
                    onChange={value => this.setState({ filter: value })}
                    bordered={false}
                  >
                    <Select.Option value="all">All records</Select.Option>
                    <Select.Option value="errors">Error records</Select.Option>
                    <Select.Option value="success">Success records</Select.Option>
                  </Select>
                }
              >
                {Object.keys(this.state.data.records).length == 0 ? (
                  //  show no data found illustration
                  <TabPane tab="No Data Found" key="0">
                    <Empty
                      className="m-4"
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 256,
                      }}
                      description={
                        <span className="font-size-16 text-gray-500 font-weight-bold">
                          You have no records.
                        </span>
                      }
                    />
                  </TabPane>
                ) : (
                  Object.keys(this.state.data.records).map((key, index) => (
                    <TabPane tab={key} key={index}>
                      <div id="style-9">
                        <Table
                          columns={confirmColumns}
                          dataSource={
                            this.state.filter == 'all'
                              ? this.state.data.records[key]
                              : this.state.data.records[key].filter(
                                  item =>
                                    item.error == (this.state.filter == 'errors' ? true : false),
                                )
                          }
                          scroll={{ x: 'max-content', y: windowHeight - 400 }}
                          rowClassName={(record, index) =>
                            record.error
                              ? 'bg-danger'
                              : record.status == 'update'
                              ? 'bg-warning'
                              : record.status == 'new'
                              ? 'bg-lgreen'
                              : 'bg-negative'
                          }
                          pagination={{
                            size: 'small',
                            showSizeChanger: true,
                            showQuickJumper: true,
                          }}
                          size="small"
                        />
                      </div>
                    </TabPane>
                  ))
                )}
              </Tabs>
            </>
          )}
        </Modal>
      </>
    )
  }
}

export class EnhancedPriceListForm extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <PriceListForm {...this.props} />
  }
}

export default EnhancedPriceListForm
