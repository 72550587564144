import { Button, Card, Checkbox, Drawer, Form, Input, InputNumber, Select, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import React, {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { getCountryInfo } from 'redux/countries/actions'
import { FormattedMessage } from 'react-intl'
import { formItemLayout, getPincodeDetails } from 'components/other/utility'

const CompanyAddressForm = forwardRef((props, ref) => {
  const countries = useSelector(state => state.document.countries)
  const states = useSelector(state => state.document.states)
  const country_info = useSelector(state => state.countries.info)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const formRefAddress = useRef()

  const emptyAddress = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    pincode: '',
    is_same: false,
    country: undefined,
  }

  const [selected_country_code, setSelectedCountryCode] = useState(
    user?.selectedCompany?.country_code,
  )

  const [selectedAddress, setAddressDetails] = useState({
    addr_id: -1,
  })

  const [selectedAddressType, setAddressType] = useState('shipping')
  const [selectedAddrID, setSelectedAddrID] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const onClose = () => {
    setAddressDetails({ addr_id: -1 })
    setVisible(false)
  }

  useImperativeHandle(ref, () => ({
    showAddressDrawer,
    onClose,
  }))

  const get_country_code = country => {
    var filteredCountry = countries?.filter(item => item.name == country)
    var filteredCountryCode = user?.selectedCompany?.country_code
    if (filteredCountry.length > 0) {
      filteredCountryCode = filteredCountry[0].code
    }

    return filteredCountryCode
  }

  const showAddressDrawer = (address_type, addr_id, selectedData) => {
    const current_country =
      selectedData.addr_id == -1 ? user?.selectedCompany?.country : selectedData.country

    const address = {
      ...selectedData,
      state: selectedData.state ? selectedData.state : undefined,
      country: current_country,
    }

    var selected_country_code = user?.selectedCompany?.country_code
    setAddressDetails(address)
    setAddressType(address_type)
    setSelectedAddrID(addr_id)
    setVisible(true)

    // load states according to selected country
    var filteredCountry = countries?.filter(item => item.name == user?.selectedCompany?.country)
    var filteredCountryCode = user?.selectedCompany?.country_code
    if (filteredCountry.length > 0) {
      filteredCountryCode = filteredCountry[0].code
    }
    setSelectedCountryCode(filteredCountryCode)
    dispatch(
      getCountryInfo({
        country_code: filteredCountryCode,
      }),
    )
  }

  const onWritingPincode = async value => {
    if (selected_country_code != 'IN') return
    const [success, data] = await getPincodeDetails(value, states, countries)
    if (success) {
      formRefAddress.current.setFieldsValue({
        state: data.state,
        city: data.city,
        country: data.country,
      })
    }
  }

  const onSelectCountry = countryCode => {
    if (countryCode != selected_country_code) {
      formRefAddress.current.setFieldsValue({
        state: undefined,
      })
    }

    var payload = {
      country_code: countryCode,
    }

    if (user?.selectedCompany?.country_code == 'IN') {
      if (user?.selectedCompany?.country_code != countryCode) {
        formRefAddress.current.setFieldsValue({
          state: '97-OTHER TERRITORY',
        })
      }
    }
    dispatch(getCountryInfo(payload))
    setSelectedCountryCode(countryCode)
  }

  return (
    <Drawer
      title={
        `Add ${selectedAddressType.charAt(0).toUpperCase()}` +
        `${selectedAddressType.slice(1)} Address`
      }
      width={isMobile ? '100%' : '50%'}
      height={isMobile ? '90%' : '100%'}
      placement={isMobile ? 'top' : 'right'}
      maskClosable={false}
      closable={true}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      onClose={onClose}
      open={visible}
      destroyOnClose
      extra={
        <Button
          onClick={() => {
            formRefAddress?.current?.submit()
          }}
          type="primary"
          loading={loading}
          className="float-right"
        >
          <FormattedMessage id="button.save&close" />
        </Button>
      }
      footer={[
        <Button
          onClick={() => {
            formRefAddress?.current?.submit()
          }}
          type="primary"
          loading={loading}
        >
          <FormattedMessage id="button.save&close" />
        </Button>,
      ]}
    >
      <Card>
        <Form
          {...formItemLayout}
          labelAlign="left"
          ref={formRefAddress}
          name="customerForm"
          onFinish={values => {
            setLoading(true)
            props.onFinish({ ...values }, selectedAddrID, selectedAddressType)
            setLoading(false)
            onClose()
          }}
          initialValues={{ ...selectedAddress }}
          onFinishFailed={() => setLoading(false)}
          extra={
            <Space>
              <Button onClick={onClose}>
                <FormattedMessage id="button.cancel" />
              </Button>
              <Button onClick={onClose} type="primary">
                <FormattedMessage id="button.submit" />
              </Button>
            </Space>
          }
          size="default"
        >
          <Form.Item
            name={'country'}
            label={<FormattedMessage id="form.field.country" />}
            className="pb-2"
            hidden={selectedAddressType == 'billing'}
          >
            <Select
              showSearch
              placeholder="Select Country"
              optionFilterProp="children"
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={selectedAddressType == 'billing'}
              onSelect={(value, option) => {
                onSelectCountry(option.code)
              }}
            >
              {countries?.map((item, i) => (
                <Option key={i} value={item.name} code={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedAddressType === 'shipping' && (
            <Form.Item
              name={'title'}
              label={<FormattedMessage id="form.field.companyShippingTitle" />}
              initialValue={''}
            >
              <Input placeholder="Title" />
            </Form.Item>
          )}

          <Form.Item
            name={'line1'}
            label={<FormattedMessage id="form.field.addressLine1" />}
            rules={[
              {
                required: true,
                message: 'Please fill address line 1!',
              },
            ]}
          >
            <Input placeholder="Address Line 1" />
          </Form.Item>
          <Form.Item name={'line2'} label={<FormattedMessage id="form.field.addressLine2" />}>
            <Input placeholder="Address Line 2" />
          </Form.Item>
          <Form.Item
            name={'pincode'}
            label={user?.selectedCompany?.labels?.pincode}
            rules={[
              {
                required: !selected_country_code != 'IN',
                message: 'Please enter ' + user?.selectedCompany?.labels?.pincode,
              },
            ]}
          >
            <InputNumber
              placeholder={user?.selectedCompany?.labels?.pincode}
              style={{ width: '100%' }}
              onChange={onWritingPincode}
              maxLength={6}
            />
          </Form.Item>
          <Form.Item name={'city'} label={<FormattedMessage id="form.field.city" />}>
            <Input placeholder="City" />
          </Form.Item>

          <Form.Item
            name={'state'}
            label={user?.selectedCompany?.labels?.state}
            extra={
              selectedAddressType == 'billing' && selected_country_code == 'IN'
                ? 'Billing State (like 36-Telangana) is responsible for deciding CGST + SGST/UTGST or IGST calculation on the invoice. Please ignore this, if you do not have GST.'
                : ''
            }
          >
            <Select
              showSearch
              placeholder={`Select ${user?.selectedCompany?.labels?.state}`}
              optionFilterProp="children"
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              disabled={
                user?.selectedCompany?.country_code == 'IN' && selected_country_code != 'IN'
              }
            >
              {country_info?.[selected_country_code]?.states?.map((item, i) => (
                <Option key={i} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedAddressType == 'shipping' && (
            <div className="pb-3">
              <Form.Item label="Notes" name={'notes'}>
                <TextArea rows={4} placeholder="Notes" maxLength={60} />
              </Form.Item>
            </div>
          )}

          {selectedAddressType == 'billing' && selectedAddrID < 0 && (
            <Form.Item
              name={'is_same'}
              valuePropName="checked"
              label={<FormattedMessage id="button.shippingAddress" />}
            >
              <Checkbox>
                <FormattedMessage id="text.sameAsBilling" />
              </Checkbox>
            </Form.Item>
          )}
        </Form>
      </Card>
    </Drawer>
  )
})

export default CompanyAddressForm
