import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { formItemLayout } from 'components/other/utility'
import { useSelector } from 'react-redux'
import { Button, Card, Drawer, Form, Input, InputNumber, Select, message } from 'antd'
import { getAPI } from 'services/jwt'
const { Option } = Select

const ItemPriceListForm = forwardRef((props, ref) => {
  const priceListEmpty = {
    price_list_id: -1,
    name: '',
    price: 0,
    discount: 0,
    tax: 0,
    price_with_tax: 0,
  }
  const [open, setOpen] = useState(false)
  const [priceListData, setPriceListData] = useState({ ...priceListEmpty })
  const [productId, setProductID] = useState(0)
  const [variantId, setVariantID] = useState(0)
  const [batchId, setBatchID] = useState(0)
  const formRef = createRef(null)
  const taxes = useSelector(state => state.document.tax_rates)

  const onPriceListFinish = async values => {
    const req = {
      product_id: productId,
      variant_id: variantId,
      batch_id: batchId,
      price_list: { ...values, tax: parseFloat(values.tax) },
    }

    var data = await getAPI('product', 'update_price_list', req)

    if (data.success) {
      message.success(data.message)
      setOpen(false)
      props.getPriceListData()
    }
  }
  const showDrawer = (data, productId, variantId, batch_id = 0) => {
    setProductID(productId)
    setVariantID(variantId)
    setBatchID(batch_id)
    setPriceListData({ ...data })
    setOpen(true)
  }
  useImperativeHandle(ref, () => ({
    showDrawer,
  }))

  return (
    <Drawer
      title="Add/Edit Price List"
      width={'50%'}
      closable={true}
      closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
      onClose={() => {
        setOpen(false)
        setPriceListData({ ...priceListEmpty })
      }}
      open={open}
      destroyOnClose={true}
      footer={
        <Button
          type="primary"
          onClick={() => formRef.current.submit()}
          className="font-weight-bold ml-2 arrow-transition"
        >
          {'Save'}
          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
        </Button>
      }
      extra={
        <Button
          type="primary"
          onClick={() => formRef.current.submit()}
          className="font-weight-bold ml-2 arrow-transition"
        >
          {'Save'}
          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
        </Button>
      }
    >
      <Card className="mb-4">
        <Form
          {...formItemLayout}
          labelAlign="left"
          ref={formRef}
          name="hsnForm"
          onFinish={onPriceListFinish}
          initialValues={priceListData}
        >
          <Form.Item
            name="price_list_id"
            defaultValue={priceListData.price_list_id}
            label="Price List ID"
            hidden
          >
            <Input placeholder="Price List ID" disabled />
          </Form.Item>
          <Form.Item name="name" label="Price List Name">
            <Input placeholder="Price List Name" disabled />
          </Form.Item>
          <Form.Item name="price" label="Price">
            <InputNumber
              min={0}
              placeholder="Price"
              addonAfter={'without Tax'}
              style={{ width: '100%' }}
              onChange={e => {
                formRef.current.setFieldsValue({
                  price_with_tax: (formRef.current.getFieldValue('tax') * e) / 100 + e,
                })
              }}
            />
          </Form.Item>
          <Form.Item name="price_with_tax" label="Price With Tax">
            <InputNumber
              min={0}
              placeholder="Price With Tax"
              addonAfter={'with Tax'}
              style={{ width: '100%' }}
              onChange={e => {
                formRef.current.setFieldsValue({
                  price: e / ((formRef.current.getFieldValue('tax') || 0) / 100 + 1),
                })
              }}
            />
          </Form.Item>
          <Form.Item name="tax" label="Tax">
            <Select
              showSearch
              className="font-weight-bold"
              placeholder="Select Tax"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={e => {
                formRef.current.setFieldsValue({
                  price_with_tax:
                    (formRef.current.getFieldValue('price') * e) / 100 +
                    formRef.current.getFieldValue('price'),
                })
              }}
            >
              {taxes.map((e, i) => (
                <Option key={i} value={e}>
                  {e}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="discount" label="Discount (%)">
            <InputNumber min={0} placeholder="Discount" style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
})
export default ItemPriceListForm
