import React, { Component } from 'react'
import { Button, Card, Checkbox, Descriptions, Image, Modal, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { connect } from 'react-redux'
import InfoComponent from 'components/badges/InfoComponent'

class Delete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      data: {},
      cancel_invoice: false,
      remarks: '',
      new_bank_id: 0,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = data => {
    this.setState({ flag: true, data: data })
  }
  getStatusClass = data => {
    switch (data) {
      case 'pending':
        return 'warning'
      case 'paid':
        return 'success'
      case 'cancelled':
        return 'danger'
      case 'partially paid':
        return 'partial'
      default:
        return 'danger'
    }
  }

  getSubStatusClass = data => {
    switch (data) {
      case 'completed':
        return 'warning'
      case 'active':
        return 'success'
      case 'error':
        return 'danger'
      case 'cancelled':
        return 'danger'
    }
  }
  getPaidClass = data => {
    switch (data.toLowerCase()) {
      case 'cash':
        return 'cash'
      case 'card':
        return 'card-payment'
      case 'upi':
        return 'upi'
      case 'emi':
        return 'emi'
      case 'net banking':
        return 'netbanking'
      case 'cheque':
        return 'cheque'
      case 'tds':
        return 'tds'
      case 'tcs':
        return 'tcs'
      default:
        return 'card'
    }
  }
  getEStatusClass = data => {
    switch (data) {
      case 'success':
        return 'success'
      case 'cancelled':
        return 'danger'
      default:
        return 'warning'
    }
  }
  changeRemarks = e => {
    this.setState({
      remarks: e.target.value,
    })
  }
  render() {
    return (
      <>
        {this.state.flag &&
          (this.props.document_type == 'invoice' ||
            this.props.document_type == 'sales_return' ||
            this.props.document_type == 'purchase' ||
            this.props.document_type == 'purchase_order' ||
            this.props.document_type == 'purchase_return' ||
            this.props.document_type == 'estimate' ||
            this.props.document_type == 'pro_forma_invoice' ||
            this.props.document_type == 'delivery_challan') && (
            <Modal
              title={
                <span>
                  <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>
                  <span className="text-gray-500">Are you sure want to cancel</span>{' '}
                  {this.state.data.serial_number}?
                </span>
              }
              open={this.state.flag}
              onCancel={() => this.setState({ flag: false })}
              width={640}
              closeIcon={<i className="fa-solid fa-xmark"></i>}
              wrapStyle={{ backdropFilter: 'blur(3px)' }}
              footer={
                <>
                  <Button key="back" onClick={() => this.setState({ flag: false })}>
                    Close
                  </Button>
                  <Button
                    className="font-weight-bold"
                    type="danger-3"
                    onClick={() => {
                      this.props.handleDelete(
                        this.state.data,
                        this.state.data.is_einvoice == 1,
                        this.state.remarks,
                      )
                      this.setState({ flag: false })
                    }}
                    // loading={this.state.loading}
                  >
                    Cancel {this.state.data.is_einvoice == 1 ? 'Both' : ''}
                  </Button>
                </>
              }
            >
              <div>
                {this.state.data.is_einvoice == 1 && (
                  <Card className="bg-danger">
                    <p className="text-danger">
                      E-Invoice will be cancelled if you cancel this document. You can access the
                      E-Invoice in the 'E-Invoices' section.
                    </p>
                    {/* <Checkbox
                      onChange={e => this.setState({ cancel_invoice: e.target.checked })}
                      checked={this.state.cancel_invoice}
                    >
                      Cancel E-Invoice Also
                    </Checkbox> */}
                  </Card>
                )}
                <span className="flex justify-center mb-4">
                  <Image
                    src="https://vx-erp-resources.s3.ap-south-1.amazonaws.com/danger.webp"
                    preview={false}
                    className=" p-0 "
                    style={{
                      padding: 0,
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px',
                    }}
                    height={200}
                  />
                </span>

                <Descriptions
                  size="small"
                  className="mt-4 mb-2"
                  layout="horizontal"
                  column={1}
                  bordered
                >
                  <Descriptions.Item label="Date">{this.state.data.invoice_date}</Descriptions.Item>
                  <Descriptions.Item
                    label={this.state.document_type ? `${this.state.document_type} ID` : 'ID'}
                  >
                    {this.state.data.serial_number}
                  </Descriptions.Item>
                  <Descriptions.Item label="Customer">
                    {this.state.data.customer.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Amount">
                    {this.state.data.total_amount}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <Button
                      type={this.getStatusClass(this.state.data.payment_status)}
                      size="small"
                      className="mb-0 font-weight-bold"
                    >
                      {this.state.data.payment_status}
                    </Button>
                  </Descriptions.Item>

                  <Descriptions.Item label="Remarks">
                    <TextArea rows={4} onChange={this.changeRemarks} />
                  </Descriptions.Item>
                </Descriptions>
                <p className="text-danger mt-2">This action cannot be reversed.</p>
              </div>
            </Modal>
          )}

        {this.state.flag && this.props.document_type == 'subscription' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are you sure want to
                cancel {this.state.data.serial_number}?
              </span>
            }
            open={this.state.flag}
            onCancel={() => this.setState({ flag: false })}
            width={640}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  onClick={() => {
                    this.props.handleDelete(this.state.data.new_hash_id)
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                >
                  Cancel
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Document Details"
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="Date">{this.state.data.start_time}</Descriptions.Item>
                <Descriptions.Item label={`${this.state.document_type} ID`}>
                  {this.state.data.sub_serial_number}
                </Descriptions.Item>
                <Descriptions.Item label="Customer">
                  {this.state.data.customer_name}
                </Descriptions.Item>
                <Descriptions.Item label="Amount">{this.state.data.total_amount}</Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Button
                    type={this.getSubStatusClass(this.state.data.status)}
                    size="small"
                    className="mb-0 font-weight-bold"
                  >
                    {this.state.data.status}
                  </Button>
                </Descriptions.Item>
              </Descriptions>
              <p className="text-danger mt-2">This action cannot be reversed.</p>
            </div>
          </Modal>
        )}

        {this.state.flag && this.props.document_type == 'invoice_burn' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                delete?
              </span>
            }
            open={this.state.flag}
            width={640}
            onCancel={() => this.setState({ flag: false })}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  onClick={() => {
                    this.props.handleDelete(this.state.data)
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                >
                  Delete
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Invoice Details"
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="Date">{this.state.data.invoice_date}</Descriptions.Item>
                <Descriptions.Item label={`${this.state.document_type} ID`}>
                  {this.state.data.serial_number}
                </Descriptions.Item>
                <Descriptions.Item label="Customer">
                  {this.state.data.customer.name}
                </Descriptions.Item>
                <Descriptions.Item label="Amount">{this.state.data.total_amount}</Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Button
                    type={this.getStatusClass(this.state.data.payment_status)}
                    size="small"
                    className="mb-0 font-weight-bold"
                  >
                    {this.state.data.payment_status}
                  </Button>
                </Descriptions.Item>
              </Descriptions>
              <p className="text-danger">This action cannot be reversed.</p>
            </div>
          </Modal>
        )}
        {this.state.flag && this.props.document_type == 'invoice_burn_all' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                delete?
              </span>
            }
            open={this.state.flag}
            width={640}
            onCancel={() => this.setState({ flag: false })}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  onClick={() => {
                    this.props.handleDelete(this.state.data)
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                >
                  Delete All
                </Button>
              </>
            }
          >
            <div>
              <p className="text-danger mt-5">All the deleted invoices are lost forever.</p>
            </div>
          </Modal>
        )}

        {this.state.flag && this.props.document_type == 'Product/Service' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                delete?
              </span>
            }
            open={this.state.flag}
            width={640}
            onCancel={() => this.setState({ flag: false })}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  onClick={() => {
                    this.props.handleDelete(this.state.data.product_id)
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                >
                  Delete
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Product Details"
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="Type">{this.state.data.product_type}</Descriptions.Item>
                <Descriptions.Item label="Name">{this.state.data.product_name}</Descriptions.Item>
                <Descriptions.Item label="Price">
                  {this.state.data.price_with_tax}
                </Descriptions.Item>
                <Descriptions.Item label="Quantity">{this.state.data.qty}</Descriptions.Item>
              </Descriptions>
              <ul className="text-danger">
                <li>This action cannot be reversed. </li>
                <li> Old bills won't be effected with this action</li>
                <li>
                  Profit/Loss will be based on the current purchase price of the product for the old
                  bills.
                </li>
                <li>
                  If another product created with the same name, it won't be considered same as the
                  deleted product.
                </li>
              </ul>
            </div>
          </Modal>
        )}
        {this.state.flag && this.props.document_type == 'product_category' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                delete?
              </span>
            }
            open={this.state.flag}
            width={640}
            onCancel={() => this.setState({ flag: false })}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  onClick={() => {
                    this.props.handleDelete(this.state.data.category_id)
                    this.setState({ flag: false })
                  }}
                >
                  Delete
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Category Details"
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="Category">
                  {this.state.data.category_name}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  {this.state.data.description}
                </Descriptions.Item>
                <Descriptions.Item label="Order">{this.state.data.order_num}</Descriptions.Item>
              </Descriptions>
              {/* <ul className="text-danger">
                <li>This action cannot be reversed. </li>
                <li> Old bills won't be effected with this action</li>
                <li>
                  Profit/Loss will be based on the current purchase price of the product for the old
                  bills.
                </li>
                <li>
                  If another product created with the same name, it won't be considered same as the
                  deleted product.
                </li>
              </ul> */}
              <p className="text-danger">
                Category will be removed from the all the products with this category.
              </p>
            </div>
          </Modal>
        )}
        {this.state.flag && this.props.document_type == 'product' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                delete?
              </span>
            }
            open={this.state.flag}
            width={640}
            onCancel={() => this.setState({ flag: false })}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  onClick={() => {
                    this.props.handleDelete(this.state.data.key)
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                >
                  Delete
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Product Details"
                bor
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="Name">{this.state.data.product_name}</Descriptions.Item>
                <Descriptions.Item label="Price">
                  {this.state.data.price_with_tax}
                </Descriptions.Item>
                <Descriptions.Item label="Quantity">{this.state.data.qty}</Descriptions.Item>
              </Descriptions>
              <p className="text-danger">This action cannot be reversed.</p>
            </div>
          </Modal>
        )}
        {this.state.flag &&
          (this.props.document_type == 'customer' || this.props.document_type == 'vendor') && (
            <Modal
              title={
                <span>
                  <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want
                  to delete?
                </span>
              }
              wrapStyle={{ backdropFilter: 'blur(3px)' }}
              open={this.state.flag}
              width={640}
              onCancel={() => this.setState({ flag: false })}
              closeIcon={<i className="fa-solid fa-close" />}
              footer={
                <>
                  <Button key="back" onClick={() => this.setState({ flag: false })}>
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    type="danger-3"
                    onClick={() => {
                      this.props.handleDelete(this.state.data.id)
                      this.setState({ flag: false })
                    }}
                    // loading={this.state.loading}
                  >
                    Delete
                  </Button>
                </>
              }
            >
              <div>
                <Descriptions
                  title={
                    this.props.document_type == 'customer' ? 'Customer Details' : 'Vendor Details'
                  }
                  className="mt-3 mb-3"
                  layout="horizontal"
                  column={1}
                  bordered
                >
                  <Descriptions.Item label="Name">{this.state.data.name}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{this.state.data.phone}</Descriptions.Item>
                  <Descriptions.Item label="Balance" className="">
                    {this.state.data.balance <= 0 ? (
                      <span className="text-danger">
                        <span className="pr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.state.data.balance}
                      </span>
                    ) : (
                      <span className="text-success">
                        <span className="pr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.state.data.balance}
                      </span>
                    )}
                  </Descriptions.Item>
                </Descriptions>
                {this.state.data.transactions.length > 0 && (
                  <div>
                    <p className="font-weight-bold">
                      The following documents are linked to this party.
                    </p>

                    <Descriptions
                      title="Transaction Details"
                      className="mt-3 mb-3"
                      layout="vertical"
                      column={3}
                      bordered
                    >
                      <Descriptions.Item label="Document">
                        {this.state.data.transactions.map((item, index) => (
                          <div key={index}>{item.doc_type}</div>
                        ))}
                      </Descriptions.Item>

                      <Descriptions.Item label="Count">
                        {this.state.data.transactions.map((item, index) => (
                          <div key={index}>{item.count}</div>
                        ))}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}

                <>
                  {this.state.data.linked_party != '' ? (
                    <p className="mt-5 font-weight-bold text-danger">
                      {'This ' +
                        this.props.document_type +
                        ' is linked with ' +
                        (this.props.document_type == 'customer' ? 'vendor ' : 'customer ' + ' ') +
                        this.state.data.linked_party +
                        '. Will be unlinked after deleting this !'}
                    </p>
                  ) : (
                    ''
                  )}
                </>

                <p className="text-danger mt-2">
                  Deleting this {this.props.document_type} will not delete the Transactions or
                  Payments. This cannot be undone. Still want to proceed?
                </p>

                {/* <p className="text-danger">This action cannot be reversed.</p> */}
              </div>
            </Modal>
          )}
        {this.state.flag && this.props.document_type == 'expense' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                delete?
              </span>
            }
            open={this.state.flag}
            width={640}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            onCancel={() => this.setState({ flag: false })}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  className="font-weight-bold"
                  onClick={() => {
                    this.props.handleDelete(
                      this.state.data.serial_number,
                      this.state.data.payment_status,
                    )
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                >
                  Delete
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Expense Details"
                className="mt-3 mb-2"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="ID">{this.state.data.serial_number}</Descriptions.Item>
                <Descriptions.Item label="Category">{this.state.data.category}</Descriptions.Item>
                <Descriptions.Item label="Date">{this.state.data.expense_date}</Descriptions.Item>
                <Descriptions.Item label="Payment Mode">
                  {this.state.data.payment_mode && (
                    <Button
                      type={this.getPaidClass(this.state.data.payment_mode)}
                      size="small"
                      className="mb-0 font-weight-bold"
                    >
                      {this.state.data.payment_mode}
                    </Button>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Amount" className="">
                  {this.state.data.total_amount}
                </Descriptions.Item>
              </Descriptions>
              <p className="text-danger">This action cannot be reversed.</p>
            </div>
          </Modal>
        )}
        {this.state.flag && this.props.document_type == 'payment' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                delete?
              </span>
            }
            open={this.state.flag}
            width={640}
            onCancel={() => this.setState({ flag: false })}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  className="font-weight-bold"
                  onClick={() => {
                    this.props.handleDelete(this.state.data.serial_number)
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                  disabled={this.state.data.reconciliation_status != 'pending'}
                >
                  Delete
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Payment Details"
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="Serial Number">
                  {this.state.data.serial_number}
                </Descriptions.Item>
                <Descriptions.Item label="Date">{this.state.data.payment_date}</Descriptions.Item>
                <Descriptions.Item label="Payment Mode">
                  {this.state.data.payment_mode && (
                    <Button
                      type={this.getPaidClass(this.state.data.payment_mode)}
                      size="small"
                      className="mb-0 font-weight-bold"
                    >
                      {this.state.data.payment_mode}
                    </Button>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Amount" className="">
                  {this.state.data.total_amount}
                </Descriptions.Item>
                {this.state.data.reconciliation_status != 'pending' && (
                  <>
                    <Descriptions.Item label="Reconciliation Status" className="">
                      {this.state.data.reconciliation_status}
                    </Descriptions.Item>
                    <Descriptions.Item label="Reconciled Amount" className="">
                      {this.state.data.reconciled_amount}
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
              <p className="text-danger">This action cannot be reversed.</p>

              {this.state.data.reconciliation_status != 'pending' && (
                <InfoComponent
                  description="This payment is reconciled with some payments. You can't delete this payment. Unlink the payments to delete."
                  type="error"
                />
              )}
            </div>
          </Modal>
        )}
        {this.state.flag && this.props.document_type == 'ewaybill' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                Cancel?
              </span>
            }
            open={this.state.flag}
            onCancel={() => this.setState({ flag: false })}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  className="font-weight-bold"
                  onClick={() => {
                    this.props.handleDelete(this.state.data.serial_number)
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                >
                  Cancel
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Invoice Details"
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="ID">{this.state.data.serial_number}</Descriptions.Item>
                <Descriptions.Item label="Invoice ID">
                  {this.state.data.invoice_serial_number}
                </Descriptions.Item>
                <Descriptions.Item label="Company">
                  {this.state.data.company_name}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Button
                    type={this.getEStatusClass(this.state.data.status)}
                    size="small"
                    className="mb-0 font-weight-bold"
                  >
                    {this.state.data.status}
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label="Amount" className="">
                  {this.state.data.total_amount}
                </Descriptions.Item>
              </Descriptions>
              <p className="text-danger">
                Note:
                <br />
                E-waybill can be cancelled within 24 hours from Part B entry. This action cannot be
                reversed.
              </p>
            </div>
          </Modal>
        )}
        {this.state.flag && this.props.document_type == 'einvoice' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                Cancel E-Invoice?
              </span>
            }
            open={this.state.flag}
            onCancel={() => this.setState({ flag: false })}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  className="font-weight-bold"
                  onClick={() => {
                    this.props.handleDelete(this.state.data, this.state.cancel_invoice)
                    this.setState({ flag: false })
                  }}
                >
                  Cancel
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Document Details"
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="IRN" span={2}>
                  {this.state.data.irn}
                </Descriptions.Item>
                <Descriptions.Item label="Serial Number">
                  {this.state.data.invoice_serial_number}
                </Descriptions.Item>
                <Descriptions.Item label="Company">
                  {this.state.data.company_name}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Button
                    type={this.getEStatusClass(this.state.data.status)}
                    size="small"
                    className="mb-0 font-weight-bold"
                  >
                    {this.state.data.status}
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label="Amount">{this.state.data.total_amount}</Descriptions.Item>
              </Descriptions>
              {this.state.data.status != 'pending' && (
                <>
                  {this.state.data.payment_status != 'cancelled' && (
                    <div>
                      <Checkbox
                        onChange={e => this.setState({ cancel_invoice: e.target.checked })}
                        checked={this.state.cancel_invoice}
                      >
                        Cancel Original Invoice {this.state.data.invoice_serial_number} Also
                      </Checkbox>
                    </div>
                  )}
                  <p className="text-danger pt-2">
                    Note:
                    <br />
                    E-Invoice can be cancelled only within 24 hours of creation.This action cannot
                    be reversed.
                    <br />
                    E-Invoice cannot be editable once created.
                    <br />
                    E-Invoice will not be cancelled on cancellation of original document in other
                    sections.
                  </p>
                </>
              )}
            </div>
          </Modal>
        )}
        {this.state.flag && this.props.document_type == 'bank_delete' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are you sure want to
                delete ?
              </span>
            }
            open={this.state.flag}
            onCancel={() =>
              this.setState({ flag: false }, () => {
                this.props?.closeModal()
              })
            }
            width={640}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            footer={
              <>
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({ flag: false, new_bank_id: 0 }, () => {
                      this.props?.closeModal()
                    })
                  }
                >
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  onClick={async () => {
                    await this.props.handleDelete(this.state.data, this.state.new_bank_id)
                    this.setState({ flag: this.props.deleteResponse.warning, new_bank_id: 0 })
                  }}
                  loading={this.state.loading}
                >
                  Delete
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Bank Details"
                className="mt-3 mb-3"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="Bank Name">{this.state.data.bank_name}</Descriptions.Item>
                <Descriptions.Item label="Account Number">
                  {this.state.data.bank_no}
                </Descriptions.Item>
                <Descriptions.Item label={this.props?.user?.selectedCompany?.labels.ifsc}>
                  {this.state.data.ifsc}
                </Descriptions.Item>
              </Descriptions>
              {this.props.deleteResponse.warning ? (
                <>
                  <p className="text-danger mt-2">
                    {this.props.deleteResponse.message}
                    <br />
                    Please select a bank to link your subscriptions.
                  </p>
                  <Select
                    placeholder="Select Bank"
                    allowClear
                    onChange={e =>
                      this.setState({
                        new_bank_id: e,
                      })
                    }
                    className="w-1/2 mt-2"
                  >
                    {this.props?.banks.map(bank => (
                      <Option
                        key={bank.id}
                        value={bank.id}
                        disabled={bank.id == this.state.data.id}
                      >
                        {bank.bank_name}
                      </Option>
                    ))}
                  </Select>
                </>
              ) : (
                <p className="text-danger mt-2">
                  Deleting the bank account from here will remove the bank details from all the
                  existing invoices and it is irreversible.
                  <br />
                  Deleted banks can't be used again this action is irreversible.
                </p>
              )}
            </div>
          </Modal>
        )}

        {this.state.flag && this.props.document_type == 'journal' && (
          <Modal
            title={
              <span>
                <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>Are You Sure Want to
                delete?
              </span>
            }
            open={this.state.flag}
            width={640}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            onCancel={() => this.setState({ flag: false })}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            footer={
              <>
                <Button key="back" onClick={() => this.setState({ flag: false })}>
                  Close
                </Button>
                <Button
                  key="submit"
                  type="danger-3"
                  className="font-weight-bold"
                  onClick={() => {
                    this.props.handleDelete(this.state.data.journal_id)
                    this.setState({ flag: false })
                  }}
                  // loading={this.state.loading}
                >
                  Delete
                </Button>
              </>
            }
          >
            <div>
              <Descriptions
                title="Journal Details"
                className="mt-3 mb-2"
                layout="horizontal"
                column={1}
                bordered
              >
                <Descriptions.Item label="ID">{this.state.data.journal_id}</Descriptions.Item>
                <Descriptions.Item label="Name">{this.state.data.name}</Descriptions.Item>
                <Descriptions.Item label="Date">{this.state.data.journal_date}</Descriptions.Item>
              </Descriptions>
              <p className="text-danger">This action cannot be reversed.</p>
            </div>
          </Modal>
        )}
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Delete)

export class EnhancedDelete extends React.Component {
  render() {
    return <Delete {...this.props} />
  }
}
