import { LoadingOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Modal,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tabs,
  Upload,
  message,
  Tooltip,
  Spin,
  Progress,
} from 'antd'
import BetaBadge from 'components/badges/beta'
import CustomerFrom from 'components/forms/customerForm'
import VendorFrom from 'components/forms/vendorform'
import InfoIcon from 'components/info/info_icon'
import LedgerView from 'components/modal/ledger'
import TutorialVideoModal from 'components/modal/tutorial'
import AuditHistory from 'components/other/Activity'
import PlayIcon from 'components/other/PlayIcon'
import PaymentModes from 'components/other/paymentModes'
import SectionHeader from 'components/other/sectionHeader'
import { formItemLayout, getBase64 } from 'components/other/utility'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import ExpenseItemTable from 'pages/expenses/expenseItemsTable'
import queryString from 'query-string'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import {
  get_all_doc_counts,
  get_payment_modes,
  get_product_options,
  set_all_doc_counts,
} from 'redux/document/actions'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
import { trackEvent } from 'utils/netcore'

const { TabPane } = Tabs

const { TextArea } = Input
dayjs.extend(utc)

const { Option } = Select
const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const { Dragger } = Upload

class ExpensesForm extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  tutorialRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      formFlag: false,
      categories: [],
      bank_id: '',
      payment_mode: '',
      selectedId: '',
      category: '',
      category_id: -1,
      type: '',
      loading: false,
      attachments: [],
      is_paid: true,
      selectedData: {
        bank_id: '',
        expense_date: dayjs(),
        payment_mode: '',
        total_amount: '',
        description: '',
        category: '',
        payment_date: dayjs(),
      },
      payment_status: 'pending',
      new_category_name: '',
      expense_items: [
        {
          id: 'new' + Math.random(),
          description: '',
          category: undefined,
          tax: 0,
          tax_amount: 0,
          net_amount: 0,
          total_amount: 0,
          category_id: -1,
        },
      ],
      with_tax: false,
      vendor_id: 0,
      is_cgst: false,
      party_id: 0,
      party_type: '',
      autocompleteData: [],
      partiesData: [],
      partyName: '',
      selectedPartyId: '',
      supplier_invoice_date: dayjs()
        .utcOffset(330)
        .format(this.dateFormat),
      supplier_invoice_serial_number: '',
      amount_type: 'total_amount',
      party_details: {},
      is_tds: 0,
      tds_selected_section: undefined,
      rcm: 0,
      tds_amount: 0,
      audit_history: [],
      expense_data: {},
      activeKey: 'expense_details',
      initialValuesObject: {},
      bank_details_not_available: false,
      progress: 1,
      gettingAIResponse: false,
    }
    this._onFinish = this._onFinish.bind(this)
    this.onFinish = _.throttle(this._onFinish, 2000)
  }

  async componentDidMount() {
    if (this.props?.payment_modes?.length == 0) {
      await this.props.dispatch(get_payment_modes())
    }

    var req = { document_type: 'invoice' }
    var data = await getAPI('v2/doc', 'get_company_details', req)
    if (data.success) {
      this.setState({
        companyDetails: data.company_details,
      })
    }
    this.props.onRef(this)
    if (!this.props.all_doc_counts) {
      this.props.dispatch(get_all_doc_counts())
    }
    if (this.props.taxes.length == 0) {
      this.props.dispatch(get_product_options())
    }

    if (
      this.props?.bank_details?.length == 0 ||
      (this.props?.bank_details?.length == 1 && this.props?.bank_details[0]?.id == 0)
    ) {
      this.setState({ bank_details_not_available: true })
    }
  }

  componentWillUnmount() {
    this.onFinish.cancel()
  }
  getCategory = id => {
    let items = this.state.categories.filter(item => item.category_id == id)
    let category = items.length > 0 ? items[0].category : ''
    return category
  }
  _onFinish = async values => {
    let category_id = this.state.category_id
    this.setState({ document_type: this.props.document_type })

    if (this.state.with_tax == 1 && this.state.selectedPartyId == '') {
      message.error('Please select party')
      this.setState({ loading: false })
      return
    }

    if (this.state.is_paid && !values.bank_id && values.payment_mode != 'Cash') {
      message.error('Please select bank')
      this.setState({ loading: false })
      return
    }

    if (this.state.is_paid && !values.payment_mode) {
      message.error('Please select payment mode')
      this.setState({ loading: false })
      return
    }

    // if (Array.isArray(values.category)) {
    //   if (category_id!=0) {
    //     values.category = this.getCategory(values.category[0])
    //   } else {
    //     values.category = ''
    //   }
    // } else {
    //   values.category = this.getCategory(values.category)
    // }
    values.category = this.getCategory(category_id)
    if (values.payment_mode == 'Cash') {
      values.bank_id = 0
    }
    if (this.state.is_tds && this.state.tds_selected_section == undefined) {
      message.error('Please select TDS section')
      this.setState({ loading: false })
      return
    }
    if (this.state.with_tax && this.state.expense_items.length > 0) {
      // console.log(this.state.expense_items)
      try {
        this.state.expense_items.forEach(element => {
          if (element.description == '') {
            throw new Error('Item name cannot be empty')
          }
        })
      } catch (error) {
        message.error(error.message)
        this.setState({ loading: false })
        return
      }
    }
    let expense_items = this.state.expense_items
    expense_items = expense_items.map(item => {
      item.tax = parseFloat(item.tax)
      item.tax_amount = parseFloat(item.tax_amount)
      item.total_amount = parseFloat(item.total_amount)
      item.net_amount = parseFloat(item.net_amount)
      if ('category' in item && item.category != undefined) {
        let category = this.state.categories.filter(category => category.category == item.category)

        item['category_id'] = category.length > 0 ? category[0].category_id : 0

        return item
      }
      return {
        ...item,
        category: '',
        category_id: 0,
      }
    })

    var reqData = {
      ...values,
      category_id: category_id,
      is_paid: this.state.is_paid,
      expense_date: dayjs(values.expense_date).format(this.dateFormat),
      payment_date: dayjs(values.payment_date).format(this.dateFormat),
      attachments: this.state.attachments,
      document_type: this.state.document_type,
      items: expense_items,
      with_tax: this.state.with_tax,
      is_tds: this.state.is_tds,
      tds_details: this.state.is_tds
        ? { ...this.state.tds_selected_section, tds_amount: this.state.tds_amount }
        : {},
      rcm: this.state.is_rcm,
      amount_type: this.state.amount_type,
      party_type: this.state.party_type,
      party_id: this.state.selectedPartyId,
      supplier_invoice_date: this.state.supplier_invoice_date,
      supplier_invoice_serial_number: this.state.supplier_invoice_serial_number,
      total_amount: this.state.with_tax ? this.getExpenseItemsTotalAmount() : values.total_amount,
      net_amount: this.state.with_tax ? this.getExpenseItemsNetAmount() : values.total_amount,
      tax_amount: this.state.with_tax ? this.getExpenseItemsTaxAmount() : 0,
    }
    var url = 'create'
    if (this.state.formFlag) {
      reqData = { serial_number: this.state.selectedId, ...reqData }
      url = 'edit'
    }
    const data = await getAPI('expenses', url, reqData)

    if (data) {
      this.setState({
        loading: false,
        visible: false,
        attachments: [],
        with_tax: false,
        partyName: '',
        selectedPartyId: 0,
        expense_items: [],
        is_tds: false,
        activeKey: 'expense_details',
        party_details: {},
      })
      const payload = {
        ...this.props.all_doc_counts,
        [this.state.document_type]: 1,
      }
      this.props.dispatch(set_all_doc_counts(payload))
      this.props?.onAPICalled(data, this.state.formFlag)
      message.success(data.message)
    } else {
      // this.setState({
      //   loading: false,
      //   with_tax: false,
      //   partyName: '',
      //   selectedPartyId: 0,
      //   expense_items: [],
      //   is_tds: false,
      //   party_details: {},
      // })
      this.setState({ loading: false })
    }
  }

  onAdd = async () => {
    var data = await getAPIData('expenses', 'get_details')
    var categories = []
    var party_type = ''
    if (this.props.document_type == 'expense') {
      party_type = 'vendor'
      categories = data.categories.filter(category => category.type == 'expense')
    } else if (this.props.document_type == 'indirect_income') {
      party_type = 'customer'
      categories = data.categories.filter(category => category.type == 'indirect_income')
    }
    if (data) {
      this.setState({
        document_type: this.props.document_type,
        party_type: party_type,
        // if bank details are not available, or only one (that too cash), then default to cash
        payment_mode: this.state.bank_details_not_available
          ? 'Cash'
          : this.props?.payment_modes?.length > 0
          ? this.props?.payment_modes[0].value
          : '',
        bank_id: this.props?.bank_details?.length > 0 ? this.props?.bank_details[0]?.id : undefined,
        categories,
        category: categories.length > 0 ? categories[0].category : '',
      })
    }
    let default_bank_id = this.props?.bank_details?.filter(item => item.is_default == 1)

    if (default_bank_id.length == 0 && this.props?.bank_details?.length > 1) {
      default_bank_id = this.props?.bank_details[1]?.id
    } else if (default_bank_id.length > 0) {
      default_bank_id = default_bank_id[0].id
    } else {
      default_bank_id = undefined
    }
    this.setState(
      {
        visible: true,
        formFlag: false,
        attachments: [],
        initialValuesObject: {
          amount_type: undefined,
          category: undefined,
          supplier_invoice_date: undefined,
          supplier_invoice_serial_number: undefined,
          with_tax: undefined,
          is_paid: true,
          bank_id: default_bank_id,
          total_amount: '',
          payment_mode:
            this.props?.payment_modes.length > 0 ? this.props?.payment_modes[0].value : '',
          description: '',
        },
      },
      () => {
        this.formRef.current.setFieldsValue({
          bank_id: default_bank_id,
          expense_date: dayjs(),
          payment_date: dayjs(),
          payment_mode: this.state.payment_mode,
          category: undefined,
          serial_number: '',
          total_amount: '',
          description: '',
          is_paid: true,
        })
        trackEvent({
          eventName: 'page_view',
          eventAttributes: {
            page_name: 'expense_form',
          },
        })
      },
    )
  }
  get_audit_history = async doc_count => {
    let query_params = queryString.stringify({
      document_type: this.props.document_type,
      doc_count: doc_count,
    })
    this.setState({ loading: true })
    const audit_history = await getAPIData('expenses', 'get_history?' + query_params)
    this.setState({
      loading: false,
      activeKey: 'comments_history',
      audit_history: audit_history.audit_history,
    })
  }

  onFill = async data => {
    this.setState(
      {
        initialValuesObject: {
          amount_type: data.amount_type,
          category: data.category,
          supplier_invoice_date: data.supplier_invoice_date,
          supplier_invoice_serial_number: data.supplier_invoice_serial_number,
          with_tax: data.with_tax,
          is_paid: true,
          bank_id: data.bank_id,
          total_amount: data.total_amount,
          payment_mode: data.payment_mode,
          description: data.description,
        },
        document_type: this.props.document_type,
        visible: true,
        formFlag: true,
        is_paid: false,
        selectedId: data.serial_number,
        payment_mode: data.payment_mode,
        attachments: data.attachments,
        with_tax: data.with_tax,
        rcm: data.rcm,
        selectedPartyId: data.with_tax ? data.party_id : 0,
        partyName: data.with_tax ? data.party_details.name : '',
        expense_items: data.items,
        payment_status: data.payment_status,
        party_details: data.party_details,
        tds_selected_section: data.is_tds ? data.tds_details[0] : {},
        tds_amount: data.is_tds ? data.tds_details[0]['tds_amount'] : 0,
        is_tds: data.is_tds,
        expense_data: data,
        category_id: data.category_id,
      },
      () => {
        this.generateTaxLabel()
        this.formRef.current.setFieldsValue({
          ...data,
          category: data.category == '' ? this.state.category : data.category,
          expense_date: dayjs(data.start_date, this.dateFormat),
          supplier_invoice_date:
            data.supplier_invoice_date != '00-00-0000' && data.with_tax == 1
              ? dayjs(data.supplier_invoice_date, this.dateFormat)
              : undefined,
        })
      },
    )

    data = await getAPIData('expenses', 'get_details')

    if (data) {
      var categories = []
      var party_type = ''
      if (this.props.document_type == 'expense') {
        party_type = 'vendor'
        categories = data.categories.filter(category => category.type == 'expense')
      } else if (this.props.document_type == 'indirect_income') {
        party_type = 'customer'
        categories = data.categories.filter(category => category.type == 'income')
      }
      this.setState({
        payment_mode:
          this.props?.payment_modes.length > 0 ? this.props?.payment_modes[0].value : '',
        bank_id: this.props?.bank_details?.length > 0 ? this.props?.bank_details[0]?.id : undefined,
        categories: categories,
        category: categories.length > 0 ? categories[0].category : '',
        party_type: party_type,
      })
    }
  }

  beforeUpload = file => {
    const isLt3M = file.size / 1024 / 1024 < 25
    if (!isLt3M) {
      message.error('File must smaller than 25MB!')
    }
    return isLt3M
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }

    const form = new FormData()
    form.append('file', file)

    const data = await getMultipartAPI('utils', 'attach_files', form)
    if (data.success) {
      onSuccess(null, file)
      this.setState({ attachments: [...this.state.attachments.slice(-2), ...data.attachments] })
      message.success(data.message)
    } else {
      message.error(data.message)
      onError()
    }
  }

  handleChange = ({ file, fileList }) => {
    if (file.status == 'removed') {
      const newFiles = this.state.attachments.filter(file1 => file1.uid !== file.uid)
      this.setState({ attachments: newFiles })
    }
  }
  updateCategories = categories => {
    this.setState({ categories })
  }
  updateExpenseItems = expense_items => {
    this.setState({ expense_items }, () => {
      if (this.state.is_tds) {
        this.onchangeTdsSection(this.state.tds_selected_section.tds_id)
      }
    })
  }
  getExpenseItemsTotalAmount = () => {
    let total_amount = 0
    this.state.expense_items.forEach(item => {
      total_amount += parseFloat(item.total_amount)
    })
    if (this.state.is_rcm) {
      total_amount = total_amount - this.getExpenseItemsTaxAmount()
    }
    return total_amount
  }
  getExpenseItemsNetAmount = () => {
    let net_amount = 0
    this.state.expense_items.forEach(item => {
      net_amount += parseFloat(item.net_amount)
    })
    return net_amount
  }
  getExpenseItemsTaxAmount = () => {
    let tax_amount = 0
    this.state.expense_items.forEach(item => {
      tax_amount += parseFloat(item.tax_amount)
    })
    return tax_amount
  }
  onchangeTdsSection = async tds_section => {
    // console.log(tds_section)
    if (this.state.is_tds) {
      const selected_section = await this.props.tds_sections.filter(
        section => section.tds_id == tds_section,
      )

      if (selected_section) {
        let tds_amount = this.getExpenseItemsNetAmount() * (selected_section[0].tax / 100)
        this.setState({ tds_amount, tds_selected_section: selected_section[0] })
      }
    }
  }

  onPartySelect = async (data, option) => {
    var req = {
      id: option.id,
    }
    var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)
    // console.log(data);
    if ('success' in data) {
      if (data.success) {
        let party_obj =
          this.state.party_type == 'vendor' ? data.vendor_details[0] : data.customer_details[0]
        this.setState({
          party_details: party_obj,
        })
        option = { ...party_obj, ...option }

        if (party_obj.is_tds == 1) {
          this.setState({ is_tds: true })
        }
        if (party_obj.tds_section_id != 0) {
          this.setState({ tds_selected_section: party_obj.tds_section_id })
        }
      }
    }

    this.setState(
      {
        selectedPartyId: option.id,
        partyName: option.name,
        partiesData: [...this.state.partiesData, option],
        autocompleteData: [],
      },
      () => this.generateTaxLabel(),
    )
  }

  getStatusClass = data => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }

  customerSearchAPI = async searchText => {
    const req = { query: searchText }
    const data = await getAPI(this.state.party_type, 'search', req)
    if (data) {
      var parties = this.state.party_type == 'customer' ? data.customers : data.vendors
      const allData = parties.map(value => {
        return {
          value: (
            <span>
              <span>{value.name + (value.phone != '' ? ' (' + value.phone + ')' : '')}</span>
              <span
                className="font-size-12 float-right font-weight-bold"
                style={{ color: this.getStatusClass(value.balance + value.opening_balance) }}
              >
                <span className="font-size-12 mr-1">
                  {this.props?.user?.selectedCompany?.currency_symbol}
                </span>
                {Math.round(Math.abs(value.balance + value.opening_balance)).toLocaleString(
                  'en-IN',
                  dotOptions,
                )}
              </span>
              <p className="font-size-12 mb-0">
                <span className="text-gray-500">{value.gstin}</span> {value.company_name}
                <span className="float-right text-gray-500">
                  {value.tags && value.tags != '' ? 'Tags: ' + value.tags : ''}
                </span>
              </p>
            </span>
          ),
          id: this.state.party_type == 'customer' ? value.customer_id : value.vendor_id,
          key: this.state.party_type == 'customer' ? value.customer_id : value.vendor_id,
          notes: value.name,
        }
      })
      this.setState({
        autocompleteData: [...allData],
      })
    }
  }

  onCustomerSearch = async searchText => {
    if (this.timeout) clearTimeout(this.timeout)
    this.setState({ selectedPartyId: '', partyName: searchText })
    this.timeout = setTimeout(() => {
      this.customerSearchAPI(searchText)
    }, 700)
  }

  onSupplierInvoiceDateChange = (date, string) => {
    this.setState({ supplier_invoice_date: string })
  }

  generateTaxLabel() {
    try {
      const billing_address = this.state.companyDetails?.billing_address?.filter(
        x => x.is_delete == 0,
      )
      const party_state =
        this.state.party_type == 'customer'
          ? this.state.party_details?.billing_address[0]?.state
          : this.state.party_details?.billing?.state

      if (billing_address.length == 0 || party_state == undefined) {
        this.setState({
          is_cgst: true,
        })
      } else if (party_state != billing_address[0].state) {
        this.setState({
          is_cgst: false,
        })
      } else {
        this.setState({
          is_cgst: true,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  handleAddNewCategory = async (category_name = '') => {
    let new_category_name = category_name == '' ? this.state.new_category_name : category_name

    let checkExistingCategory = this.state.categories.filter(
      item => item.category.toLowerCase() == new_category_name.toLowerCase(),
    )
    if (checkExistingCategory.length > 0) {
      message.error('Category already exists')
      this.setState({ new_category_name: '' })
      return
    }

    var data = await getAPI('expenses', 'add_category', {
      category: new_category_name,
      type: this.state.document_type,
    })
    if (data) {
      this.setState(
        {
          categories: [
            {
              category: data.category,
              category_id: data.category_id,
              type: this.state.document_type,
            },
            ...this.state.categories.filter(x => x.category_id !== 0),
          ],
          category_id: data.category_id,
          expense_items: this.state.expense_items.map(item => {
            if (item.category == new_category_name) item.category_id = data.category_id
            return item
          }),
        },
        () => {
          if (category_name == '') {
            this.formRef.current.setFieldsValue({
              category: this.state.new_category_name,
            })
            this.setState({
              new_category_name: '',
            })
          }
        },
      )
      message.success('Category added successfully')
    }

    // this.setState({
    //   categories: [...this.state.categories, { category: this.state.new_category_name }],
    // })
  }

  onClose = () => {
    let currentData = this.formRef?.current?.getFieldsValue()

    let isValuesChanged = false
    for (let key in currentData) {
      if (['expense_date', 'supplier_invoice_date'].includes(key)) {
        continue
      }
      if (
        this.state.initialValuesObject[key] !== undefined &&
        currentData[key] !== this.state.initialValuesObject[key]
      ) {
        isValuesChanged = true
        break
      }
    }

    if (isValuesChanged) {
      Modal.confirm({
        title: 'Do you want to exit?',
        content: 'You will lose your unsaved data.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Confirm',
        onOk: () => {
          this.setState({
            visible: false,
            with_tax: 0,
            partyName: '',
            selectedData: 0,
            expense_items: [],
            is_tds: false,
            party_details: {},
            selectedPartyId: 0,
            activeKey: 'expense_details',
          })
        },
        onCancel: () => {},
      })
    } else {
      this.setState({
        visible: false,
        with_tax: 0,
        partyName: '',
        selectedData: 0,
        expense_items: [],
        is_tds: false,
        party_details: {},
        selectedPartyId: 0,
        activeKey: 'expense_details',
      })
    }
  }

  async uploadFile() {
    this.setState({
      loading: true,
      gettingAIResponse: true,
      responseText: 'Uploading file',
      progress: 0,
    })

    const textToShow = {
      1: 'Uploading file',
      2: 'Extracting data',
      3: 'Generating response',
    }
    let count = 1
    const progressInterval = setInterval(() => {
      this.setState({
        responseText: textToShow[count] ?? textToShow[3],
        progress: this.state.progress + 30,
      })
      count++
    }, 1000)

    const formData = new FormData()
    formData.append('file', this.state.file)
    const data = await getMultipartAPI('ai', 'parse_expense', formData)
    clearInterval(progressInterval)
    if (data) {
      if (data.success) {
        this.setState(
          {
            responseText: 'Data retrieved',
            progress: 100,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  selectedData: {
                    ...this.state.selectedData,
                    total_amount: data.data.expense_amount ?? dayjs(),
                    category: data.data.category,
                    expense_date: dayjs(data.data.date, this.dateFormat) ?? dayjs(),
                    description: data.data.notes ?? '',
                    supplier_invoice_date: dayjs(data.data.date, this.dateFormat) ?? dayjs(),
                    supplier_invoice_serial_number: data.data.invoice_no,
                  },
                  expense_items: (data.data.items ?? []).map((item, index) => ({
                    ...item,
                    id: 'new' + Math.random(),
                    key: index,
                    total_amount: item.price ?? 0,
                    net_amount: item.price ?? 0,
                    tax_amount: item.tax_amount ?? 0,
                    description: item.name,
                  })),
                  with_tax: (data.data.items ?? []).length > 0,
                  partyName: data.data.vendor_name,
                  selectedPartyId: data.data.vendor_id,
                  category_id: data.data.category_id,
                  gettingAIResponse: false,
                  responseText: '',
                  attachments: [...this.state.attachments, ...(data.file_list ?? [])],
                },
                () => {
                  this.formRef.current.setFieldsValue(this.state.selectedData)
                  if (
                    this.state.selectedPartyId == '' &&
                    this.state.partyName != '' &&
                    this.state.with_tax
                  ) {
                    if (this.state.party_type == 'customer') {
                      this.customer.onAdd(this.state.partyName)
                    } else {
                      this.vendor.onAdd(this.state.partyName)
                    }
                  }
                },
              )
            }, 1000)
          },
        )
      }
    } else {
      this.setState({
        responseText: 'Something went wrong',
        progress: 100,
        loading: false,
        gettingAIResponse: false,
      })
    }

    this.setState({
      loading: false,
    })
  }

  render() {
    const amount_types = [
      {
        key: '1',
        label: 'Total Amount',
        value: 'total_amount',
      },
      {
        key: '2',
        label: 'Net Amount',
        value: 'net_amount',
      },
    ]

    const customLoader = (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Spin size="small" />
        <div style={{ whiteSpace: 'nowrap' }} className="font-size-14 mt-6">
          {this.state.responseText}
        </div>
      </div>
    )

    return (
      <Drawer
        title={
          <>
            <div className="flex items-center">
              <div className="mr-auto">
                <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                  {this.state.document_type == 'expense' ? 'Expenses' : 'Indirect Income'}
                </span>
              </div>
            </div>
          </>
        }
        width={'60%'}
        height={'100%'}
        placement={isMobile ? 'top' : 'right'}
        closable={true}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        destroyOnClose
        onClose={this.onClose}
        open={this.state.visible}
        footer={
          this.state.activeKey == 'expense_details' && (
            <Button
              onClick={() => this.setState({ loading: true }, () => this.formRef.current.submit())}
              type="primary"
              loading={this.state.loading}
            >
              {this.state.document_type == 'expense'
                ? this.state.formFlag
                  ? 'Update Expense'
                  : 'Add Expense'
                : this.state.formFlag
                ? 'Update Income'
                : 'Add Income'}
            </Button>
          )
        }
        extra={
          this.state.activeKey == 'expense_details' && (
            <Button
              onClick={() => this.setState({ loading: true }, () => this.formRef.current.submit())}
              type="primary"
              loading={this.state.loading}
            >
              {this.state.document_type == 'expense'
                ? this.state.formFlag
                  ? 'Update Expense'
                  : 'Add Expense'
                : this.state.formFlag
                ? 'Update Income'
                : 'Add Income'}
            </Button>
          )
        }
      >
        {/*<Card bordered={false} size="drawer" className="border-radius-small mb-4">*/}
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="expense_details"
          activeKey={this.state.activeKey}
          onChange={activeKey => {
            this.setState({ activeKey }, () => {
              if (activeKey == 'expense_details') {
                this.onFill(this.state.expense_data)
              } else {
                this.get_audit_history(this.state.expense_data.doc_count)
              }
            })
          }}
        >
          <TabPane tab={<span>Basic Details </span>} key="expense_details">
            <Spin spinning={this.state.gettingAIResponse} indicator={customLoader}>
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef}
                name="ExpensesForm"
                onFinish={this.onFinish}
                onFinishFailed={() => this.setState({ loading: false })}
                initialValues={{
                  ...this.state.selectedData,
                }}
              >
                <Row gutter={18}>
                  <Col span={24}>
                    {!this.state.formFlag && (
                      <Form.Item
                        name="with_tax"
                        label={
                          <>
                            Create Expense With Tax <BetaBadge />
                          </>
                        }
                        hidden={this.props.document_type != 'expense'}
                      >
                        <Switch
                          onChange={value => this.setState({ with_tax: value })}
                          checked={this.state.with_tax}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                        />
                      </Form.Item>
                    )}
                  </Col>

                  <Col span={24}>
                    <SectionHeader
                      title="Basic Details"
                      right={true}
                      rightText={
                        <div>
                          {this.props.document_type == 'expense' && (
                            <Tooltip
                              title={
                                'Upload expense image (PNG, JPEG, max size: 25MB) to auto fill the data in the form.'
                              }
                            >
                              <Upload
                                customRequest={({ onsuccess, onError, file }) =>
                                  this.setState({ file: file }, async () => this.uploadFile())
                                }
                                beforeUpload={this.beforeUpload}
                                multiple={false}
                                accept="image/png, image/jpeg"
                                showUploadList={false}
                              >
                                <div className="cursor-pointer">
                                  <i class="fa-duotone text-primary fa-solid fa-sparkles mr-2"></i>
                                  <span className="font-weight-medium text-primary">
                                    Upload Expense with AI
                                  </span>
                                </div>
                              </Upload>
                            </Tooltip>
                          )}
                        </div>
                      }
                    />
                    <Card bordered={false} size="drawer" className="border-radius-small mb-4">
                      <Row gutter="18">
                        <Col span={24}>
                          <Form.Item
                            name="total_amount"
                            label={
                              this.state.document_type == 'expense'
                                ? 'Expense Amount'
                                : 'Income Amount'
                            }
                            rules={[
                              { required: !this.state.with_tax, message: 'Fill Pay Amount' },
                              {
                                type: 'number',
                                min: 0.00001,
                                message: 'Amount should be greater than 0',
                              },
                            ]}
                            hidden={this.state.with_tax}
                          >
                            <InputNumber
                              placeholder="Enter amount"
                              className="w-full font-weight-bold"
                              precision={2}
                              size="large"
                              prefix={
                                <span className="text-gray-500">
                                  {this.props?.user?.selectedCompany?.currency_symbol}
                                </span>
                              }
                              suffix={
                                <>
                                  <span></span>
                                </>
                              }
                            />
                          </Form.Item>
                        </Col>
                        {this.state.with_tax == 1 && (
                          <Col span={12}>
                            <Form.Item>
                              <div>
                                <h6 className="">
                                  <span className="mr-1">
                                    Select{' '}
                                    {this.state.party_type == 'customer' ? 'Customer' : 'Vendor'}
                                  </span>
                                  <span className="mr-3">
                                    <InfoIcon
                                      text={
                                        <span>
                                          <span>
                                            Search your existing customers using the search box
                                            below.
                                          </span>
                                          <br />
                                          <span>
                                            Please click on{' '}
                                            <strong>Add New {this.state.party_type}</strong> to add
                                            a new customer.
                                          </span>
                                        </span>
                                      }
                                    />
                                  </span>
                                  <Button
                                    type="text"
                                    onClick={() =>
                                      this.state.party_type == 'customer'
                                        ? this.customer.onAdd(this.state.partyName)
                                        : this.vendor.onAdd(this.state.partyName)
                                    }
                                    className="font-weight-bold xsmall hidden sm:inline"
                                  >
                                    Add New{' '}
                                    {this.state.party_type == 'customer' ? 'Customer' : 'Vendor'}?
                                  </Button>
                                </h6>
                                <span>
                                  <AutoComplete
                                    options={this.state.autocompleteData}
                                    className="mr-2 pb-2 font-weight-medium"
                                    autoFocus
                                    style={{ width: '100%' }}
                                    popupMatchSelectWidth={isMobile ? '100%' : 640}
                                    dropdownMenuStyle={{ top: 9 }}
                                    disabled={this.state.payment_status != 'pending'}
                                    dropdownRender={menu => (
                                      <>
                                        {menu}
                                        <div className="px-1 py-1 mt-1">
                                          <Button
                                            block
                                            size="large"
                                            type="primary-light"
                                            className="font-weight-bolder"
                                            onClick={() =>
                                              this.state.party_type == 'customer'
                                                ? this.customer.onAdd(this.state.partyName)
                                                : this.vendor.onAdd(this.state.partyName)
                                            }
                                          >
                                            <i className="fa-solid fa-circle-plus mr-2"></i>
                                            Add New
                                            {this.state.party_type == 'customer'
                                              ? ' Customer'
                                              : ' Vendor'}
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                    onSelect={this.onPartySelect}
                                    onSearch={this.onCustomerSearch}
                                    value={this.state.partyName}
                                    placeholder={`Search existing ${
                                      this.state.party_type == 'customer' ? 'Customers' : 'Vendors'
                                    }, Company Name, ${
                                      this.props?.user?.selectedCompany?.labels?.gstin
                                    }, tags...`}
                                    ref={this.inputEl}
                                    prefix={
                                      <i className="fa-solid fa-magnifying-glass text-gray-300"></i>
                                    }
                                  ></AutoComplete>
                                  {this.state.selectedPartyId != 0 &&
                                    this.state.payment_status == 'pending' && (
                                      <div>
                                        <a
                                          className="text-primary ml-1"
                                          onClick={() =>
                                            this.setState({
                                              selectedPartyId: '',
                                              partyName: '',
                                            })
                                          }
                                        >
                                          Change Party
                                        </a>
                                      </div>
                                    )}
                                </span>
                              </div>
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={this.state.with_tax ? 12 : 24}>
                          <Form.Item
                            name="expense_date"
                            label={
                              this.state.document_type == 'expense' ? 'Expense Date' : 'Income Date'
                            }
                          >
                            <DatePicker
                              allowClear={false}
                              className="w-full"
                              format={this.dateFormat}
                              style={{
                                width: 'auto',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Supplier Invoice Date"
                            name={'supplier_invoice_date'}
                            hidden={!this.state.with_tax}
                          >
                            <DatePicker
                              style={{
                                borderRadius: '5px',
                                cursor: 'pointer',
                                width: '100%',
                              }}
                              allowClear={false}
                              format={this.dateFormat}
                              value={dayjs(this.state.supplier_invoice_date, this.dateFormat)}
                              onChange={this.onSupplierInvoiceDateChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Supplier Invoice Serial No"
                            name={'supplier_invoice_serial_number'}
                            hidden={!this.state.with_tax}
                          >
                            <TextArea
                              rows={1}
                              type="text"
                              className="pr-4 mr-2"
                              placeholder="(Optional)"
                              name="supplier_invoice_serial_number"
                              style={{ width: '100%', borderRadius: '5px' }}
                              onChange={e =>
                                this.setState({
                                  supplier_invoice_serial_number: e.target.value,
                                })
                              }
                              value={this.state.supplier_invoice_serial_number}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label={'Amount Type'}
                            name={'amount_type'}
                            hidden={!this.state.with_tax}
                          >
                            <Select
                              value={this.state.amount_type}
                              onChange={e => this.setState({ amount_type: e })}
                              defaultValue="total_amount"
                            >
                              {amount_types.map((type, index) => (
                                <Option key={index} value={type.value}>
                                  {' '}
                                  {type.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="category"
                            label={
                              <>
                                Category
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => this.tutorialRef.current.showModal()}
                                >
                                  <PlayIcon />
                                </span>
                              </>
                            }
                            hidden={this.state.with_tax}
                          >
                            <Select
                              maxTagTextLength={40}
                              placeholder="Select Category"
                              filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              showSearch
                              dropdownRender={menu => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: '8px 0',
                                    }}
                                  />
                                  <div className="flex pb-2 pl-2 ">
                                    <Input
                                      placeholder="Can't find your category? Add new category here"
                                      className="w-full mr-3"
                                      value={this.state.new_category_name}
                                      onChange={e =>
                                        this.setState({ new_category_name: e.target.value })
                                      }
                                    />
                                    <Button
                                      type="primary-light"
                                      onClick={() => this.handleAddNewCategory()}
                                    >
                                      <i className="fa-solid fa-plus mr-2"></i>
                                      Add Category
                                    </Button>
                                  </div>
                                </>
                              )}
                              options={this.state.categories.map(item => ({
                                label: item.category,
                                value: item.category_id,
                              }))}
                              onChange={e => this.setState({ category_id: e })}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item name="description" label="Notes">
                            <TextArea placeholder="Notes" />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Upload
                            customRequest={this.customRequest}
                            onChange={this.handleChange}
                            beforeUpload={this.beforeUpload}
                            fileList={this.state.attachments}
                            maxCount={3}
                            multiple
                          >
                            <Button
                              className="mt-2 mb-2"
                              icon={<i className="fa-solid fa-arrow-up-from-bracket"></i>}
                              disabled={this.state.attachments.length < 3 ? false : true}
                            >
                              Attach Files (Max: 3)
                            </Button>
                          </Upload>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  {this.state.with_tax == 1 && (
                    <>
                      <Col span={24}>
                        <SectionHeader title="Item Details" />
                        {/*<Card className="bg-gray-50">*/}
                        <ExpenseItemTable
                          expense_items={this.state.expense_items}
                          updateCategories={this.updateCategories}
                          updateExpenseItems={this.updateExpenseItems}
                          categories={this.state.categories}
                          taxes={this.props.taxes}
                          with_tax={this.state.with_tax}
                          is_cgst={this.state.is_cgst}
                          party_id={this.state.selectedPartyId}
                          amount_type={this.state.amount_type}
                          is_rcm={this.state.is_rcm}
                          getExpenseItemsNetAmount={this.getExpenseItemsNetAmount}
                          getExpenseItemsTaxAmount={this.getExpenseItemsTaxAmount}
                          getExpenseItemsTotalAmount={this.getExpenseItemsTotalAmount}
                          selectedPartyId={this.state.selectedPartyId}
                          handleAddNewCategory={this.handleAddNewCategory}
                          companyDetails={this.state.companyDetails}
                        />
                        {/*</Card>*/}
                      </Col>

                      <Col span={24} className="mt-3">
                        <SectionHeader title={'Totals'} />
                        <Card
                          size="medium"
                          bordered={false}
                          className="bg-card-indigo border-radius-small mb-4"
                        >
                          <div className="flex items-center justify-start">
                            <span className="mb-3">
                              <Switch
                                className="mr-2"
                                size="small"
                                checked={this.state.is_rcm}
                                onChange={e => {
                                  this.setState({ is_rcm: e })
                                }}
                              />
                              <span className="text-gray-700">
                                Reverse Charge Mechanism applicable?{' '}
                              </span>
                            </span>
                          </div>

                          <div className="flex items-center justify-start">
                            <span className="mb-3">
                              <Switch
                                className="mr-2"
                                size="small"
                                checked={this.state.is_tds}
                                onChange={e => {
                                  this.setState({ is_tds: e })
                                  if (!e) {
                                    this.setState({
                                      tds_amount: 0,
                                      tds_selected_section: undefined,
                                    })
                                  }
                                }}
                                disabled={this.state.payment_status != 'pending'}
                              />
                              <span className="text-gray-700">TDS applicable ?</span>
                            </span>
                          </div>

                          {this.state.is_tds == 1 && this.state.with_tax == 1 && (
                            <Input.Group>
                              <Row
                                gutter={{
                                  sm: 4,
                                  md: 6,
                                }}
                                className="mb-3"
                              >
                                <Col span={16}>
                                  <Select
                                    // disabled={this.state.payment_status != 'pending'}
                                    showSearch
                                    className="text-left"
                                    placeholder="Select TDS Section"
                                    optionFilterProp="children"
                                    style={{ width: '100%', whiteSpace: 'nowrap' }}
                                    value={this.state.tds_selected_section?.tds_id}
                                    filterOption={(input, option) =>
                                      (
                                        option.props.children.props.children[0].props.children +
                                        ' ' +
                                        option.props.children.props.children[1].props.children +
                                        ' ' +
                                        option.props.children.props.children[2].props.children
                                      )
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={e => this.onchangeTdsSection(e)}
                                  >
                                    {this.props?.tds_sections?.map((item, i) => (
                                      <Option key={i} value={item.tds_id}>
                                        <div style={{ whiteSpace: 'normal' }}>
                                          <span className="text-gray-700 font-weight-bold mr-1">
                                            {item.tax + '%'}
                                          </span>
                                          <span className="mr-2 font-weight-bold text-g ray-700">
                                            {item.section}
                                          </span>
                                          <span className="text-gray-500">{item.name}</span>
                                        </div>
                                      </Option>
                                    ))}
                                  </Select>
                                </Col>
                                <Col span={8}>
                                  <Input
                                    disabled={true}
                                    placeholder={'TDS amount'}
                                    type={'number'}
                                    value={this.state.tds_amount}
                                  />
                                </Col>
                              </Row>
                            </Input.Group>
                          )}
                        </Card>
                      </Col>
                    </>
                  )}

                  {!this.state.formFlag && (
                    <Col span={24}>
                      <SectionHeader title="Payments" />
                      <Card bordered={false} className="w-full mb-3 border-radius-small">
                        <Col span={24}>
                          <Form.Item name="is_paid" label={<>Mark as Paid</>}>
                            <Switch
                              onChange={value => this.setState({ is_paid: value })}
                              checked={this.state.is_paid}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          {this.state.is_paid && !this.state.formFlag && (
                            <Form.Item name="payment_mode" label="Payment Type">
                              <PaymentModes
                                data={this.props?.payment_modes?.filter(
                                  item => item.value != 'TDS',
                                )}
                                value={this.state.payment_mode}
                                onChange={e => this.setState({ payment_mode: e.target.value })}
                                disabled={this.state.bank_details_not_available}
                                onFinishAddBank={async () => {
                                  await this.props.dispatch(get_payment_modes())
                                  this.setState({ bank_details_not_available: false })
                                }}
                              />
                            </Form.Item>
                          )}
                        </Col>

                        <Col span={24}>
                          {this.state.is_paid && !this.state.formFlag && (
                            <Form.Item name="payment_date" label={'Payment Date'}>
                              <DatePicker
                                allowClear={false}
                                className="w-50"
                                format={this.dateFormat}
                                style={{
                                  width: 'auto',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                              />
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={24}>
                          {this.state.payment_mode != 'Cash' &&
                            this.state.payment_mode != 'TDS' &&
                            this.state.is_paid &&
                            !this.state.formFlag && (
                              <Form.Item
                                name="bank_id"
                                label="Select Bank"
                                // rules={[{ required: true, message: 'Please select bank' }]}
                              >
                                <Select
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={e => {
                                    this.setState({
                                      bank_id: e,
                                      payment_mode: e == 'Cash' ? 'Cash' : this.state.payment_mode,
                                    })
                                  }}
                                >
                                  {this.props?.bank_details?.map(
                                    (item, i) =>
                                      item.bank_name != 'Cash' && (
                                        <Option key={i} value={item.id}>
                                          {item.bank_name == 'Cash'
                                            ? item.bank_name
                                            : item.bank_name + ' (' + item.bank_no + ')'}
                                        </Option>
                                      ),
                                  )}
                                </Select>
                              </Form.Item>
                            )}
                        </Col>
                      </Card>
                    </Col>
                  )}
                </Row>
              </Form>
            </Spin>
            {/* )} */}
          </TabPane>
          {this.state.formFlag && this.props.permissions.history == 1 && (
            <TabPane
              tab={
                <span>
                  Activity
                  <BetaBadge />
                </span>
              }
              key="comments_history"
            >
              <Card>
                {this.state.loading ? (
                  <p className="w-full flex justify-center my-5">
                    <LoadingOutlined style={{ fontSize: 35 }} className="text-primary" spin />
                  </p>
                ) : (
                  <AuditHistory auditHistory={this.state.audit_history} />
                )}
              </Card>
            </TabPane>
          )}
        </Tabs>

        {/*</Card>*/}
        <TutorialVideoModal ref={this.tutorialRef} video_name={'create_expense_category'} />
        <LedgerView
          onRef={ref => (this.ledger = ref)}
          apiName={this.state.party_type}
          onAPICalled={() => {}}
          callData={() => {}}
        />
        <CustomerFrom
          onRef={ref => (this.customer = ref)}
          onAPICalled={(id, name, customerDetails) => {
            let index = this.state.partiesData.findIndex((item, index) => item.id == id)
            let partiesData = this.state.partiesData
            if (index != -1) {
              partiesData[index].value = name
              partiesData[index].notes = name
            } else {
              partiesData.push({
                value: name,
                id: id,
                notes: name,
                phone: '',
              })
            }
            this.setState({
              partyName: name,
              selectedPartyId: id,
              partiesData: partiesData,
              party_details: customerDetails.customer_details[0],
            })
          }}
        />
        <VendorFrom
          onRef={ref => (this.vendor = ref)}
          onAPICalled={(id, name) => {
            let index = this.state.partiesData.findIndex((item, index) => item.id == id)
            let partiesData = this.state.partiesData
            if (index != -1) {
              partiesData[index].value = name
              partiesData[index].notes = name
            } else {
              partiesData.push({ value: name, id: id, notes: name, phone: '' })
            }

            this.setState({
              partyName: name,
              selectedPartyId: id,
              partiesData: partiesData,
            })
          }}
        />
      </Drawer>
    )
  }
}

const mapStateToProps = state => {
  return {
    tds_sections: state.document.tdsSections,
    permissions: state.permissions.permission,
    all_doc_counts: state.document.all_doc_counts,
    taxes: state.document.tax_rates,
    user: state.user,
    payment_modes: state.document.paymentModes,
    bank_details: state.document.bankDetails,
  }
}
export default connect(mapStateToProps)(ExpensesForm)
