import { Button, Card, Layout, Modal } from 'antd'
import classNames from 'classnames'
import Tutorial from 'components/Tutorial'
import InfoComponent from 'components/badges/InfoComponent'
import Footer from 'components/cleanui/layout/Footer'
import SupportChat from 'components/cleanui/layout/SupportChat'
import TopBar from 'components/cleanui/layout/TopBar'
import FeatureBar from 'components/cleanui/layout/FeatureBar'
import { GetVersion, fetchUserUpdates } from 'pages/firebasejs'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import store from 'store'
import { motion } from 'framer-motion'

const isWindowContext = typeof window !== 'undefined'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: true,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: true,
  isGrayTopbar: settings.isGrayTopbar,
})

export function FeatureLayout({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
}) {
  const [userUpdates, setUserUpdates] = useState([])
  const [visible, setVisible] = useState(false)
  const [versionVisible, setVersionVisible] = useState(false)

  useEffect(() => {
    getUserUpdates()
    getCurrentVersion()
  }, [])

  async function getUserUpdates() {
    // var data1 = await getAPIData('utils', 'user_updates')
    // setUserUpdates(data1.user_updates)
    try {
      var data1 = await fetchUserUpdates()
      if (data1 && data1.length > 0) {
        let updates = data1.filter(item => item.text != '' && item.show == true)
        setUserUpdates(updates)
        setVisible(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getCurrentVersion() {
    const data = await GetVersion()
    if (data.success) {
      if (store.get('version') != data.version) {
        store.set('version', data.version)
        setVersionVisible(true)
      }
    }
  }

  if (isWindowContext) {
    if (!document.cookie.includes('show_notifications')) {
      var expires = new Date(Date.now() + 86400 * 1000).toUTCString()
      document.cookie = 'show_notifications=true; expires=' + expires + ';path=/;'
    }

    if (document.cookie.includes('show_notifications') && visible) {
      try {
        //get the cookie value
        var show_notifications = document.cookie
          .split('; ')
          .find(row => row.startsWith('show_notifications'))
          .split('=')[1]
        if (show_notifications == 'false') {
          setVisible(false)
        }
      } catch (e) {
        console.log(e)
      }
    }

    //call only if notification_called cookie is not set
    if (!document.cookie.includes('notification_called')) {
      //expire cookie in 3hrs
      expires = new Date(Date.now() + 3 * 60 * 60 * 1000).toUTCString()
      document.cookie = 'notification_called=true;  expires=' + expires + ';path=/;'
      getUserUpdates()
    }

    if (!document.cookie.includes('version_called')) {
      var expires = new Date(Date.now() + 900 * 1000).toUTCString()
      document.cookie = 'version_called=true; expires=' + expires + ';path=/;'
      getCurrentVersion()
    }
  }

  return (
    // <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
    //   <Layout.Header
    //     className={classNames('cui__layout__header', {
    //       cui__layout__fixedHeader: isTopbarFixed,
    //       cui__layout__headerGray: isGrayTopbar,
    //     })}
    //   >
    //     {/* <TopBar /> */}
    // <motion.div
    //   initial={{ x: '100vw' }} // Start off-screen to the right
    //   animate={{ x: 0 }} // Slide in to its normal position
    //   transition={{ type: 'spring', stiffness: 400, damping: 50 }} // Smooth spring transition
    //   className=""
    // >
    <>
      <div>{children ?? <></>}</div>
      {/* <Layout.Footer>
        <Footer />
      </Layout.Footer> */}
    </>
    // </motion.div>
    // </Layout.Header>
    // <Layout
    //   className={classNames({
    //     cui__layout__contentMaxWidth: isContentMaxWidth,
    //     cui__layout__appMaxWidth: isAppMaxWidth,
    //     cui__layout__grayBackground: isGrayBackground,
    //     cui__layout__squaredBorders: isSquaredBorders,
    //     cui__layout__cardsShadow: isCardShadow,
    //     cui__layout__borderless: isBorderless,
    //   })}
    // >
    //   <Tutorial />

    //   <Layout className="">
    //     {userUpdates.length > 0 && visible && (
    //       <div className="px-4 pr-1 mt-3 m-0 w-full">
    //         <InfoComponent
    //           description={
    //             <a href={userUpdates[0].link} target="_blank">
    //               {userUpdates[0].text}
    //             </a>
    //           }
    //           type="info"
    //           learnMore="link"
    //           learnMoreText=""
    //           closable={true}
    //           onClose={() => {
    //             var expires = new Date(Date.now() + 3600 * 1000).toUTCString()
    //             document.cookie = 'show_notifications=false; expires=' + expires + ';path=/;'
    //             setVisible(false)
    //           }}
    //         />
    //       </div>
    //     )}
    //     <Modal
    //       title={
    //         <span>
    //           <i className="fa-solid fa-exclamation-circle mr-1"></i>
    //           New Update Available
    //         </span>
    //       }
    //       open={versionVisible}
    //       footer={null}
    //       closable={false}
    //       maskClosable={false}
    //     >
    //       <div className="flex justify-between items-center">
    //         <span className="font-size-16">New Update available. Please Refresh the page</span>
    //         <span>
    //           <Button
    //             type="action"
    //             size="large"
    //             onClick={() => {
    //               window.location.reload(true)
    //             }}
    //           >
    //             Refresh
    //           </Button>
    //         </span>
    //       </div>
    //     </Modal>

    //     <Layout.Content>
    //       {/*<Button
    //         type="secondary"
    //         className="border-radius-small bg-white border-0 "
    //         onClick={() => history.push('/list/sales')}
    //         style={{ left: 0, marginBottom: '10px' }}
    //       >
    //         <i className="fa fa-arrow-left fa-lg mr-2"></i>
    //         {!isMobile ? 'Back to Home' : ''}
    //       </Button>*/}
    //       {/*<Card size="layout" className="border-radius-small height-90vh">*/}
    //       <div className="cui__utils__content">{children}</div>
    //       {/*</Card>*/}

    //       {store.get('paid') == 0 && <SupportChat />}
    //     </Layout.Content>
    //     <Layout.Footer>
    //       <Footer />
    //     </Layout.Footer>
    //   </Layout>
    // </Layout>
    // </div>
  )
}

export default withRouter(connect(mapStateToProps)(FeatureLayout))
