import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Table,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { getPriceWithTax } from 'components/other/calculation'
import { formItemLayout } from 'components/other/utility'
import * as dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import InventoryManager from 'managers/inventory'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { get_inventory_categories } from 'redux/warehouses/actions'
import { getAPI } from 'services/jwt'
dayjs.extend(utc)
dayjs.extend(isBetween)

const { Option } = Select

export default class EditInventoryTimeline extends Component {
  formRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  dateTimeFormat = 'YYYY-MM-DD hh:mm a'

  constructor(props) {
    super(props)
    this.state = {
      isFirstTime: false,
      addFlag: false,
      expiry_date: dayjs(),
      visible: false,
      selectedData: { id: 0 },
      record_time: dayjs(),
      bom_products: [],
      is_bom: false,
      quantity: 0,
      loading: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  onFinish = async values => {
    this.setState({ loading: true })

    if (values.category?.length > 0) {
      values.category = values.category[0]
    }

    var reqData = {
      ...values,
      id: this.state.selectedData.id,
      created_date: this.state.record_time,
      is_bom: this.state.is_bom,
      bom_products: this.state.bom_products,
    }

    const data = await getAPI('inventory', 'update_inventory_record', reqData)

    if (data) {
      this.setState({ visible: false })
      this.props.onAPICalled()
    }
    this.setState({ loading: false })
  }

  onFill = (data, flag) => {
    const getCategories = async () => {
      if (
        this.props.inventory_categories == undefined ||
        this.props.inventory_categories?.length == 0
      ) {
        await this.props.dispatch(get_inventory_categories())
      }
    }

    getCategories()

    this.setState({ past_dates_allowed: data.serial_number == '' ? true : false })

    this.setState(
      {
        selectedData: data,
        visible: true,
        record_time: dayjs(data.record_time).format(this.dateFormat),
        bom_products: data.bom_items?.map(item => ({
          ...item,
          qty: (item.qty * item.conversion_rate) / data.qty,
        })),
        is_bom: data.is_bom,
        quantity: data.qty,
      },
      async () => {
        this.formRef.current.setFieldsValue({
          ...data,
          created_date: dayjs(data.record_time),
        })

        if (this.state.selectedData.has_bom == 1 && this.state.selectedData.is_bom == 0) {
          let products = await InventoryManager.getBOM(
            this.state.selectedData.product_id,
            this.state.selectedData.variant_id,
          )
          this.setState({
            bom_products: products,
          })
        }
      },
    )
  }

  onChange = (value, item, type) => {
    const index = this.state.bom_products.findIndex(product => product.id == item.id)
    if (index > -1) {
      let dataSource = [...this.state.bom_products]
      if (type == 'qty') {
        dataSource[index].qty = value
      } else if (type == 'unit_id') {
        let prev_conversion_rate = dataSource[index]['conversion_rate']
        dataSource[index]['unit_id'] = value
        if (dataSource[index]['unit_id'] == 0 || dataSource[index]['unit_id'] == null) {
          dataSource[index]['conversion_rate'] = 1
        } else {
          dataSource[index]['conversion_rate'] = dataSource[index]['units']
            ? dataSource[index]['units'].find(item => item.unit_id == value)?.conversion_rate
            : 1
        }
        dataSource[index]['price'] =
          (dataSource[index]['price'] * prev_conversion_rate) / dataSource[index]['conversion_rate']
        dataSource[index]['unit_price'] = dataSource[index]['price']
        dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])

        dataSource[index]['unit'] =
          dataSource[index]['unit'] == '' || dataSource[index]['unit'] == null
            ? ''
            : dataSource[index]['units']
            ? dataSource[index]['units'].filter(
                item => item.unit_id == dataSource[index]['unit_id'],
              )?.[0].alternative_unit
            : dataSource[index]['unit']
      }

      this.setState({
        bom_products: dataSource,
      })
    }
  }

  render() {
    const selectUnits = data => {
      return (
        <span className="select-borderless">
          <Select
            suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
            placeholder="Unit"
            optionFilterProp="children"
            style={{ maxWidth: '90px' }}
            dropdownStyle={{ minWidth: '300px' }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={data.units?.filter(item => item.unit_id == data?.unit_id)?.[0].unit_id}
            value={data?.unit_id == 0 || data?.unit_id == null ? 0 : data?.unit_id}
            onChange={value => {
              this.onChange(value, data, 'unit_id')
            }}
            // disabled={}
          >
            {data.units?.map((e, i) => (
              <Option key={i} value={e.unit_id}>
                {e.alternative_unit}{' '}
                {data.unit != '' && e.primary_unit == undefined
                  ? ` - (1 ${data.units?.[0].alternative_unit} = ${e.conversion_rate} ${e.alternative_unit})`
                  : ''}
              </Option>
            ))}
          </Select>
        </span>
      )
    }

    let bom_columns = [
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'product_name',
        width: '20%',
        render: (text, record) => (
          <span>
            {record.product_name}{' '}
            {record.variant_name == '' || record.variant_name == null
              ? ''
              : ' ' + record.variant_name}
          </span>
        ),
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        width: '20%',
        render: (text, record) => {
          return (
            <InputNumber
              min={0}
              defaultValue={record.qty}
              value={record.qty}
              className="w-full"
              onChange={value => this.onChange(value, record, 'qty')}
              addonAfter={record.has_alternative_units == 0 ? record.unit : selectUnits(record)}
            />
          )
        },
      },
      {
        title: 'Total Quantity',
        dataIndex: '',
        key: 'total_quantity',
        width: '20%',
        render: (text, record) => (
          <span>
            {record.qty * parseFloat(this.state.quantity)}
            {record.unit != '' && record.unit != null && record.unit != undefined
              ? ` ${record.unit}`
              : ''}
          </span>
        ),
      },
    ]
    return (
      <Drawer
        title={'Edit Inventory'}
        width={isMobile ? '100%' : '45%'}
        height={isMobile ? '90%' : '100%'}
        placement={isMobile ? 'top' : 'right'}
        closable={true}
        closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
        onClose={() => this.setState({ visible: false })}
        open={this.state.visible}
        footer={
          <Button
            onClick={() => this.formRef.current.submit()}
            type="primary"
            loading={this.state.loading}
          >
            <span>
              <i className="fa-solid fa-edit mr-2"></i>
            </span>
            Save Changes
          </Button>
        }
      >
        <Form
          {...formItemLayout}
          labelAlign="left"
          ref={this.formRef}
          name="InventoryForm"
          onFinish={this.onFinish}
          initialValues={{
            ...this.state.selectedData,
            expiry_date: dayjs(),
          }}
        >
          <Card bordered={false} size="drawer" className="border-radius-small mb-4">
            <Form.Item name="qty" label="Quantity">
              <Input value={this.state.quantity} disabled />
            </Form.Item>
            {this.state.selectedData?.document_type &&
              this.state.selectedData?.document_type == 'manual' && (
                <Form.Item
                  name="category"
                  label="Select Category"
                  extra={
                    'Choose the category or type your own cateogry that describes the reason for this stock ' +
                    (this.state.addFlag ? 'in' : 'out') +
                    '.'
                  }
                >
                  <Select
                    mode="tags"
                    maxTagCount={1}
                    showSearch
                    allowClear
                    placeholder="Select category or type your own"
                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                    filterOption={(input, option) =>
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={e => {
                      this.formRef.current.setFieldsValue({
                        category: e == undefined || e?.length == 0 ? '' : [e[e.length - 1]],
                      })
                    }}
                  >
                    {[
                      ...(this.state.addFlag
                        ? this.props.stock_in_categories
                        : this.props.stock_out_categories),
                      ...this.props.inventory_categories?.filter(
                        item =>
                          item !== '' &&
                          !this.props.stock_in_categories?.includes(item) &&
                          !this.props.stock_out_categories?.includes(item),
                      ),
                    ].map((item, index) => (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            <Form.Item name="remarks" label="Remarks">
              <TextArea />
            </Form.Item>
            <Form.Item name="created_date" label="Record Time">
              <DatePicker
                disabled={this.state.selectedData.document_type != 'manual'}
                autoFocus
                allowClear={false}
                format={this.dateFormat}
                disabledDate={
                  this.state.past_dates_allowed
                    ? ''
                    : current => {
                        return dayjs().add(-1, 'days') >= current
                      }
                }
                use12Hours={true}
                onChange={(date, dateString) => {
                  this.setState({
                    record_time: dateString,
                  })
                }}
                value={this.state.record_time}
                className="mb-2 w-full"
              />
            </Form.Item>
          </Card>
          {((this.state.selectedData.has_bom == 1 && this.state.selectedData.is_bom == 0) ||
            this.state.selectedData.is_bom == 1) && (
            <Card className="mb-3">
              <Row gutter={16}>
                <Col span={24}>
                  <span className="mr-5 font-weight-bold">
                    {this.state.addFlag ? 'Stock Out Raw Material' : 'Stock In Raw Material'}
                  </span>
                  <Switch
                    style={
                      this.state.is_bom
                        ? { backgroundColor: '#15be53' }
                        : { backgroundColor: '#e11900' }
                    }
                    checked={this.state.is_bom}
                    checkedChildren={'Yes'}
                    unCheckedChildren={'No'}
                    onChange={checked => {
                      this.setState({
                        is_bom: checked,
                      })
                    }}
                  />
                </Col>

                {this.state.is_bom ? (
                  <Col span={24} className="mt-3">
                    <h6 className="mb-1">
                      Raw Material for{' '}
                      <span className="font-weight-bold">
                        {this.state.selectedData.product_name}{' '}
                        {this.state.selectedData.variant_name}
                      </span>
                    </h6>
                    <Table
                      columns={bom_columns}
                      dataSource={this.state.bom_products}
                      pagination={false}
                      rowKey="id"
                    />
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </Card>
          )}
        </Form>
      </Drawer>
    )
  }
}

const mapStateToProps = state => {
  return {
    inventory_categories: state.warehouse.inventory_categories ?? [],
    stock_in_categories: state.warehouse.stock_in_categories ?? [],
    stock_out_categories: state.warehouse.stock_out_categories ?? [],
  }
}

export const EnhancedEditInventoryTimeline = connect(mapStateToProps)(EditInventoryTimeline)
