import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from 'antd'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import store from 'store'

const { Option } = Select

export default class AlternativeUnitsForm extends Component {
  formRef = React.createRef()
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      states: [],
      alternativeUnitsData: '',
      visible: false,
      loading: false,
      units: [],
      primary_unit: '',
      show_reverse: false,
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }
  onAdd = (units, primary_unit) => {
    //remove primary unit from units
    const unitsWithoutPrimary = units.filter(unit => unit.code !== primary_unit)
    this.setState({ units: unitsWithoutPrimary, primary_unit, visible: true })
  }

  onFill = async (data, units, primary_unit) => {
    const unitsWithoutPrimary = units.filter(unit => unit.code !== primary_unit)
    data = data.filter(
      item => item.alternative_unit !== primary_unit && item.alternative_unit != '',
    )
    this.setState(
      {
        alternativeUnitsData: {
          alternative_units: [
            ...data.map(item => {
              if (item.alternative_unit == primary_unit) {
                return {
                  ...item,
                  show_reverse: false,
                  conversion_rate: item.conversion_rate,
                }
              }
            }),
          ],
        },
        units: unitsWithoutPrimary,
        primary_unit,
        visible: true,
      },
      () => {
        this.formRef.current.setFieldsValue({
          alternative_units: [
            ...data.map(item => {
              return {
                ...item,
                show_reverse: false,
                conversion_rate: item.conversion_rate,
              }
            }),
          ],
        })
      },
    )
  }

  onFinishAlternativeUnits = async values => {
    let alternative_units = values.alternative_units.map(item => {
      return {
        ...item,
        conversion_rate:
          item.show_reverse == true ? 1 / item.conversion_rate : item.conversion_rate,
      }
    })

    this.props.onAPICalled(alternative_units)
    this.setState({ visible: false })
    message.success('Click on Add/Update to Save Changes')
  }

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const formItemLayout = {
      labelCol: {
        sm: { span: 8 },
      },
      wrapperCol: {
        sm: { span: 16 },
      },
    }

    return (
      <div>
        <Drawer
          title={
            <>
              <div className="flex items-center">
                <div className="mr-auto">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    {'Alternative Units'}
                  </span>
                </div>
              </div>
            </>
          }
          extra={
            <Space>
              <Button
                onClick={() => this.formRef.current.submit()}
                type="primary arrow-transition"
                className="mr-2 arrow-transition"
                loading={this.state.loading}
              >
                Save
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </Space>
          }
          placement="right"
          width={isMobile ? '90%' : '50%'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          closable={() => this.setState({ visible: false, alternativeUnitsData: '' })}
          onClose={() => this.setState({ visible: false, alternativeUnitsData: '' })}
          visible={this.state.visible}
          maskClosable={false}
          destroyOnClose={true}
          footer={
            <>
              <Button
                onClick={() => this.formRef.current.submit()}
                type="primary arrow-transition"
                className="mr-2 arrow-transition"
                loading={this.state.loading}
              >
                Save
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>

              <Button onClick={() => this.setState({ visible: false })} type="danger">
                Close
              </Button>
            </>
          }
        >
          <Card className="py-3 px-3" style={{ overflowX: 'auto' }}>
            <span className="mb-1">
              Primary unit cannot be changed if alternative units are there. For OTH unit, it can be
              changed.
              {/*<Link
                            to={{
                              pathname: 'https://einvoice1.gst.gov.in/Others/MasterCodes',
                            }}
                            target="_blank"
                            className="font-weight-bold"
                          >
                            GST approved UQC codes.
                          </Link>*/}
            </span>

            <Form
              labelAlign="left"
              ref={this.formRef}
              {...formItemLayout}
              name="alternativeUnitsForm"
              onFinish={this.onFinishAlternativeUnits}
              onFinishFailed={() => message.error('Check All Details Again')}
              initialValues={{ ...this.state.alternativeUnitsData }}
            >
              <Form.List name="alternative_units">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={16} style={{ marginBottom: '16px' }}>
                        <Col span={8}>
                          <Space key={key} style={{ marginBottom: 8 }} align="baseline">
                            <Popconfirm
                              title={
                                <span style={{ color: 'red' }}>
                                  This cannot be reversed. Are you sure to delete? The data linked
                                  to the products will also be deleted."
                                </span>
                              }
                              onConfirm={() => {
                                remove(name)
                              }}
                            >
                              <MinusCircleOutlined />
                            </Popconfirm>
                            <Form.Item
                              {...restField}
                              name={[name, 'alternative_unit']}
                              fieldKey={[key, 'alternative_unit']}
                              rules={[{ required: true, message: 'Select Alternative Unit' }]}
                              style={{ width: '500px' }}
                            >
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select Unit"
                                showArrow={false}
                                notFoundContent={null}
                                style={{ width: '100%' }}
                                defaultActiveFirstOption={false}
                                optionLabelProp="label"
                                onChange={value => {
                                  this.setState({ test: value })
                                }}
                                filterOption={(input, option) =>
                                  // option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  {
                                    return option.label.toLowerCase().includes(input.toLowerCase())
                                  }
                                }
                              >
                                {this.state.units.map(d => (
                                  <Option key={d.id} value={d.code} label={d.code + ' ' + d.name}>
                                    <div style={{ whiteSpace: 'normal' }}>
                                      <span className="mr-2 font-weight-bold">{d.code}</span>
                                      {d.name}
                                    </div>
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'id']}
                            fieldKey={[key, 'id']}
                            label="Id"
                            hidden
                          >
                            <Input placeholder="Id" />
                          </Form.Item>
                        </Col>

                        {this.formRef.current &&
                          this.formRef.current.getFieldValue('alternative_units')?.[name]
                            ?.alternative_unit != null && (
                            <Row gutter={0}>
                              <Col span={20}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'conversion_rate']}
                                  label="Conversion Rate"
                                  colon={false}
                                  labelAlign="left"
                                  labelCol={{ flex: '0 0 auto' }}
                                  rules={[
                                    { required: true, message: 'Enter Conversion Rate' },
                                    //check for duplicate conversion rate with other units
                                    ({ getFieldValue }) => ({
                                      validator(rule, value) {
                                        if (
                                          !value ||
                                          getFieldValue('alternative_units').filter(
                                            d =>
                                              d.conversion_rate == value &&
                                              d.alternative_unit ==
                                                getFieldValue('alternative_units')?.[name]?.[
                                                  'alternative_unit'
                                                ],
                                          ).length > 1
                                        ) {
                                          return Promise.reject(
                                            'Conversion Rate cannot be same as other units',
                                          )
                                        }
                                        return Promise.resolve()
                                      },
                                    }),
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Conversion Rate"
                                    addonBefore={
                                      //show based on switch
                                      this.formRef.current &&
                                      this.formRef.current.getFieldValue('alternative_units')?.[
                                        name
                                      ]?.show_reverse == true
                                        ? `1 ${this.formRef.current
                                            .getFieldValue('alternative_units')
                                            ?.[name]?.alternative_unit.toUpperCase()} =`
                                        : `1 ${this.state.primary_unit.toUpperCase()} =`
                                    }
                                    style={{ width: '100%' }}
                                    addonAfter={
                                      //show based on switch
                                      this.formRef.current &&
                                      this.formRef.current.getFieldValue('alternative_units')?.[
                                        name
                                      ]?.show_reverse == true
                                        ? `${this.state.primary_unit.toUpperCase()}`
                                        : `${this.formRef.current

                                            .getFieldValue('alternative_units')
                                            ?.[name]?.alternative_unit.toUpperCase()}`
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              {/* show switch to  toggle take input as 1 unit = x primary unit */}
                              <Col span={4}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'show_reverse']}
                                  label=""
                                  valuePropName="checked"
                                >
                                  <Tooltip
                                    title={
                                      'Click to toggle between 1 primary unit = x unit to 1 alternative unit = x primary unit'
                                    }
                                    placement="bottom"
                                  >
                                    <span
                                      onClick={() => {
                                        //calculate and set the conversion rate based on the switch value
                                        this.formRef.current.setFieldsValue({
                                          alternative_units: [
                                            ...this.formRef.current
                                              .getFieldValue('alternative_units')
                                              .map((item, index) => {
                                                if (index == name) {
                                                  return {
                                                    ...item,
                                                    show_reverse: !item.show_reverse,
                                                    conversion_rate:
                                                      item.conversion_rate == undefined
                                                        ? item.conversion_rate
                                                        : 1 / item.conversion_rate == Infinity
                                                        ? 1
                                                        : !item.show_reverse
                                                        ? (1 / item.conversion_rate).toFixed(2)
                                                        : 1 / item.conversion_rate,
                                                  }
                                                } else {
                                                  return item
                                                }
                                              }),
                                          ],
                                        })
                                      }}
                                      style={{ cursor: 'pointer' }}
                                      className={`border  p-2 rounded ${
                                        this.formRef.current &&
                                        this.formRef.current.getFieldValue('alternative_units')?.[
                                          name
                                        ]?.show_reverse == true
                                          ? 'bg-lpurple'
                                          : 'bg-white text-gray-600 '
                                      }`}
                                    >
                                      <i className="fa-solid fa-arrow-right-arrow-left font-size-18"></i>
                                    </span>
                                  </Tooltip>
                                </Form.Item>
                              </Col>
                            </Row>
                          )}
                      </Row>
                    ))}

                    <Button
                      type="dashed"
                      style={{ borderColor: 'blue' }}
                      className="w-full"
                      onClick={() =>
                        this.setState(
                          {
                            show_reverse: false,
                          },
                          () =>
                            store.get('paid') == 0
                              ? this.subscriptionPlanRef.current.openModal('alternative_units')
                              : add(),
                        )
                      }
                      block
                      icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
                    >
                      <span className="font-weight-bold" style={{ color: 'blue' }}>
                        Add Another Alternative Unit
                      </span>
                    </Button>
                  </>
                )}
              </Form.List>
            </Form>
          </Card>
          <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
          <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
        </Drawer>
      </div>
    )
  }
}

export class EnhancedAlternativeUnits extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <AlternativeUnitsForm {...this.props} />
  }
}
