import { Button, Checkbox, Tooltip, message } from 'antd'
import { Modal } from 'antd'
import FunctionalMailForm from 'components/forms/mailForm'
import { Loader } from 'components/loaders'
import { HIDE_PRICES } from 'components/new_invoice/utils'
import dayjs from 'dayjs'
import { history } from 'index'
import { MyDocument } from 'pages/doc/view'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { downloadAPI, getAPI } from 'services/jwt'
import store from 'store'

interface DocumentHandler {
  OpenDocument: (
    document_type: string,
    new_hash_id: string,
    dc_flag: boolean,
  ) => Promise<DocumentViewResult | undefined>
}

type Transaction = {
  key: number
  amount_paid: number
  amount_pending: number
  bank_id: number
  company: {
    company_name: string
    country_code: string
    einvoice: number
    email: string
    ewaybill: number
    invoice_footer: string
    mobile: string
    organization_name: string
    pos_footer: string
    show_email_footer: number
    show_sms_footer: number
    upi: string
    whatsapp_line1: string
    whatsapp_line2: string
  }
  company_id: number
  conversion_factor: number
  convert_count: number
  currency: string
  customer: {
    balance: number
    billing_address_1: string
    billing_address_2: string
    billing_city: string
    billing_pincode: string
    billing_state: string
    cc_emails: string
    company_id: number
    company_name: string
    credit_limit: number
    customer_id: number
    dial_code: string
    diff_balance: number
    discount: number
    email: string
    export_customer: number
    gst: string | null
    gstin: string
    hash_id: string
    id: number
    is_balance_updated: number
    is_delete: number
    is_same: boolean | null
    is_tcs: number
    is_tds: number
    login_with: string
    name: string
    notes: string
    opening_balance: number
    pan: string
    phone: string
    price_list_id: number
    profile_image: string
    record_time: string
    shipping_address_1: string
    shipping_address_2: string
    shipping_city: string
    shipping_notes: string
    shipping_pincode: string
    shipping_state: string
    shopify_party_id: string
    tcs_section_id: number
    tds_section_id: number
    updated_balance: number
    updated_time: string
    user_id: number
    vendor_id: number
  }
  customer_id: number
  doc_count: number
  document_title: string
  document_type: string
  due_date: string
  emails_status: number
  exclusive_notes: string
  has_ewaybill: number
  id: number
  invoice_date: string
  invoice_time: string
  invoice_type: string
  is_created_by_recurring: number
  is_einvoice: number
  is_export: number
  is_multi_currency: number
  net_amount: number
  new_hash_id: string
  notes: string
  packaging_charges: number
  payment_status: string
  payments: any[]
  phone: string
  reason: string
  recurring: number
  recurring_details: Record<string, any>
  reference: string
  reseller_details: any[]
  reseller_id: number
  serial_number: string
  signed_doc_url: string
  status: string
  sub_serial_number: string
  supplier_invoice_date: string
  supplier_invoice_serial_number: string
  tax_amount: number
  total_amount: number
  total_discount: number
  transport_charges: number
  user_id: number
  user_name: string
  invoice_settings: Record<string, any>
  eway_bill: Record<string, any>
}

type DocumentViewResult = {
  invoice_details: any
  success: any
}

type RequestPayload = {
  document_type: string
  new_hash_id: string
  is_pdf: boolean
}

type ApiResponse = {
  invoice_details: Transaction
  success: boolean
  edit: boolean
}

export const DocumentView = forwardRef<DocumentHandler, {}>((props, ref) => {
  const emailRef = useRef<any | null>()
  const DEFAULT_STATE = {
    loading: true,
    open: false,
    challanFlag: false,
    document_title: 'Invoice',
    type: ['ORIGINAL FOR RECIPIENT'],
    optionCheckbox: [] as any,
    flag: false,
    menu_name: 'sales',
    document_type: '',
    invoice: {} as Transaction,
    myKey: 0,
    edit: false,
  }
  const user = useSelector((state: any) => state.user)
  const permissions = useSelector((state: any) => state.permissions.permission)
  const [state, setState] = useState<any>({
    ...DEFAULT_STATE,
  })

  const get_menu_and_title = (document_type: string) => {
    var menu_name = 'sales'
    var document_title = 'Invoice'
    if (document_type == 'invoice') {
      document_title = 'Invoice'
      menu_name = 'sales'
    } else if (document_type == 'purchase') {
      document_title = 'Purchase'
      menu_name = 'purchases'
    } else if (document_type == 'purchase_order') {
      document_title = 'Purchase Order'
      menu_name = 'purchase_orders'
    } else if (document_type == 'estimate') {
      document_title = 'Estimate'
      menu_name = 'estimates'
    } else if (document_type == 'sales_return') {
      document_title = 'Sales Return / Credit Note'
      menu_name = 'sales_returns'
    } else if (document_type == 'delivery_challan') {
      document_title = 'Delivery Challan'
      menu_name = 'delivery_challans'
    } else if (document_type == 'purchase_return') {
      document_title = 'Purchase Return / Debit Note'
      menu_name = 'purchase_returns'
    } else if (document_type == 'pro_forma_invoice') {
      document_title = 'Pro Forma Invoice'
      menu_name = 'pro_forma_invoices'
    } else if (document_type == 'subscription') {
      document_title = 'Subscription'
      menu_name = 'subscriptions'
    }
    return { menu_name, document_title }
  }

  useImperativeHandle(ref, () => ({
    OpenDocument,
  }))

  const OpenDocument = async (
    document_type: string,
    new_hash_id: string,
    dc_flag: boolean,
  ): Promise<DocumentViewResult | undefined> => {
    // setState({
    //   ...state,
    //   open: true,
    // })
    const req: RequestPayload = {
      document_type,
      new_hash_id,
      is_pdf: true,
    }

    const data: ApiResponse = await getAPI('v2/doc', 'get_invoice', req)

    if (data.success) {
      var options = [
        {
          label: 'Customer',
          value: data.invoice_details.invoice_settings.labels.original_for_recipient,
        },
        {
          label: 'Transport',
          value: data.invoice_details.invoice_settings.labels.duplicate_for_transporter,
        },
        {
          label: 'Supplier',
          value: data.invoice_details.invoice_settings.labels.triplicate_for_supplier,
        },
      ]
      if (user?.selectedCompany?.country_code == 'IN') {
        // not showing this option for countries other than IN
        if (document_type != 'purchase_order') {
          options.push({ label: 'Delivery Challan', value: 'Delivery Challan' })
        }
      }

      if (document_type == 'purchase_order') {
        options.push({ label: HIDE_PRICES, value: HIDE_PRICES })
      }

      var type
      if (dc_flag) {
        type = data.invoice_details.invoice_settings.dc_default_invoice_types.split(',')
      } else {
        type = data.invoice_details.invoice_settings.sales_default_invoice_types.split(',')
      }
      type = type.map((label: any) => {
        const option = options.find(option => option.label === label)
        return option ? option.value : label
      })

      let menu_and_title = get_menu_and_title(document_type)

      setState({
        ...state,
        invoice: data.invoice_details,
        invoiceDataCopy: data.invoice_details,
        challanFlag: dc_flag,
        edit: data.edit,
        type: type,
        open: true,
        optionCheckbox: options,
        document_type: document_type,
        menu_name: menu_and_title.menu_name,
        document_title: menu_and_title.document_title,
        loading: false,
        show_both_currencies: data.invoice_details.invoice_settings.show_both_currencies == 1,
      })
    }

    return {
      invoice_details: data.invoice_details,
      success: data.success,
    }
  }

  const download_eway_bill_document = async () => {
    const { document_type, serial_number, new_hash_id, eway_bill } = state.invoice

    const req = {
      invoice_serial_number: serial_number,
      document_type: document_type,
      new_hash_id: new_hash_id,
      serial_number: eway_bill[0]['serial_number'],
    }

    const data = await downloadAPI('ewaybills', 'get_ewaybill_document', req)
  }
  const OnClose = () => {
    console.log('onclose triggered')
    setState({
      ...DEFAULT_STATE,
    })
  }

  // console.log(state.open)

  return (
    <div>
      <Modal
        title={<span className="text-gray-700">{state.document_title} Preview</span>}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        open={state.open}
        style={{ top: 18 }}
        width={1080}
        bodyStyle={{ height: '100vh' }}
        onOk={() => OnClose()}
        onCancel={() => OnClose()}
        footer={null}
        destroyOnClose={true}
      >
        <>
          {state.loading ? (
            <div>
              <Loader text={'Fetching Document Please wait...'} />
            </div>
          ) : (
            <>
              <Checkbox.Group
                onChange={e => {
                  const originalReceipt =
                    state?.invoice?.invoice_settings?.labels?.original_for_recipient
                  if (!state.type.includes(HIDE_PRICES) && e?.includes(HIDE_PRICES)) {
                    setState({
                      ...state,
                      type: [...e?.filter(item => item != originalReceipt)],
                      myKey: Math.random(),
                    })
                  } else if (
                    !state.type.includes(originalReceipt) &&
                    e?.includes(originalReceipt)
                  ) {
                    setState({
                      ...state,
                      type: [...e?.filter(item => item != HIDE_PRICES)],
                      myKey: Math.random(),
                    })
                  } else {
                    setState({
                      ...state,
                      type: [...e],
                      myKey: Math.random(),
                    })
                  }
                }}
                options={state.optionCheckbox}
                value={state.type}
                className="mb-3"
              ></Checkbox.Group>

              {state.invoice.is_export || state.invoice.is_multi_currency ? (
                <Checkbox
                  checked={state.show_both_currencies}
                  onChange={async e => {
                    let invoiceData = { ...state.invoiceDataCopy }
                    invoiceData.invoice_settings.show_both_currencies = e.target.checked
                    var req = {
                      amount: invoiceData.total_amount,
                      currency_code: invoiceData.export_details[0].currency_code,
                      conversion_factor: invoiceData.export_details[0].conversion_factor,
                    }
                    if (e.target.checked) {
                      req = {
                        amount: invoiceData.total_amount,
                        currency_code: user?.selectedCompany?.currency_code,
                        conversion_factor: 1,
                      }
                    }
                    var data = await getAPI('utils', 'amount_in_words', req)
                    if (data.success) {
                      invoiceData.total_amount_in_words = data.text
                    }

                    // let invoiceData = state.invoice
                    // invoiceData.invoice_settings.show_both_currencies = e.target.checked
                    setState({
                      ...state,
                      show_both_currencies: e.target.checked,
                      invoice: invoiceData,
                    })
                  }}
                  className="ml-2"
                >
                  Show in {user?.selectedCompany?.currency_code}
                </Checkbox>
              ) : (
                <></>
              )}

              <Button
                type="whatsapp"
                size="small"
                className="mr-2 float-right font-weight-bold"
                style={{ height: '24px', borderRadius: '8px' }}
                onClick={event => {
                  window.open(
                    'https://api.whatsapp.com/send?' +
                      (state.invoice.customer.phone != null &&
                      state.invoice.customer.phone.length == 10
                        ? 'phone=91' + state.invoice.customer.phone
                        : '') +
                      '&text=Hello' +
                      (state.invoice.customer.name != ''
                        ? '%20*' + encodeURIComponent(state.invoice.customer.name) + '*'
                        : '') +
                      '%2C%20%0A%0A' +
                      encodeURIComponent(state.invoice.company.whatsapp_line1) +
                      '%20%0A%0APlease%20find%20your%20' +
                      state.document_title +
                      '%20' +
                      state.invoice.serial_number +
                      '%20here%2C%20%0Ahttps://swipe.pe/n/view/' +
                      state.menu_name +
                      '/' +
                      state.invoice.new_hash_id +
                      '%0A%0A' +
                      encodeURIComponent(state.invoice.company.whatsapp_line2) +
                      '%0A%0AThanks%0A*' +
                      encodeURIComponent(state.invoice.company.organization_name) +
                      '*%0A*' +
                      state.invoice.company.mobile +
                      '*' +
                      (store.get('paid') == 0
                        ? '%0A%0ASent%20using%20*Swipe%3A%20Simple%20Invoicing%2C%20Billing%2C%20Payments*%20(getswipe.in)'
                        : ''),
                  )
                }}
              >
                <i className="fa fa-whatsapp fa-lg mr-1" /> Whatsapp
              </Button>

              <Tooltip title="Email">
                <Button
                  type="email"
                  size="small"
                  style={{ height: '24px', borderRadius: '8px' }}
                  className="mr-2 float-right"
                  target="_blank"
                  onClick={event => {
                    console.log(emailRef.current)
                    emailRef.current.onShow(state.invoice)
                  }}
                >
                  <i className="fa fa-envelope-o mr-1" /> Email
                </Button>
              </Tooltip>

              {state.invoice.eway_bill.length > 0 && (
                <Button
                  type="pos"
                  className="float-right mr-2 font-weight-bold"
                  size="small"
                  onClick={() => download_eway_bill_document()}
                >
                  <i className="fa-solid fa-arrow-down mr-2" />
                  E-way Bill
                </Button>
              )}
              {state.invoice.is_einvoice == 0 && state.edit && (
                <Button
                  type="warning"
                  size="small"
                  style={{ height: '24px', borderRadius: '8px' }}
                  className="mr-2 float-right"
                  onClick={event => {
                    if (state.invoice.signed_doc_url != '') {
                      message.error('You can not edit this document as it is digitally signed')
                      return
                    }
                    if (permissions.edit_doc_in_n_days == 1) {
                      let diff_days = dayjs().diff(
                        dayjs(state.invoice.invoice_time, 'DD MMM YY, hh:mm A'),
                      )
                      diff_days = diff_days / 86400000
                      if (permissions.edit_doc_days >= diff_days) {
                        history.push(
                          '/edit/' + state.document_type + '/' + state.invoice.new_hash_id,
                        )
                      } else {
                        message.error(
                          'You can not edit this document as it is older than ' +
                            permissions.edit_doc_days +
                            ' days',
                        )
                      }
                    } else {
                      history.push('/edit/' + state.document_type + '/' + state.invoice.new_hash_id)
                    }
                  }}
                >
                  <span>
                    <i className="fa-regular fa-pen-to-square fa-fw mr-1" />
                    <span>
                      <FormattedMessage id="button.edit" />
                    </span>
                  </span>
                </Button>
              )}
              {(state.invoice.is_export == 1 || permissions.packing_list == 1) && (
                <Tooltip title="Create Packing list">
                  <Button
                    size="small"
                    style={{ height: '24px', borderRadius: '8px' }}
                    className="mr-2 float-right"
                    target="_blank"
                    onClick={event => {
                      event.stopPropagation()
                      window.open(
                        '/create/packingList/' +
                          state.document_type +
                          '/' +
                          state.invoice.new_hash_id,
                        '_self',
                      )
                    }}
                  >
                    <i className="fa-solid fa-boxes-packing mr-2 text-primary-light fa-sm" />
                    Packing list
                  </Button>
                </Tooltip>
              )}
              <MyDocument
                data={state.invoice}
                menuName={state.menu_name}
                type={state.type}
                myKey={state.myKey}
                isPublic={false}
                companyDetails={state.invoice.company}
              />

              <FunctionalMailForm
                onRef={() => {}}
                ref={emailRef}
                document_type={state.document_type}
                menuName={state.menu_name}
              />
            </>
          )}
        </>
      </Modal>
    </div>
  )
})

export default DocumentView
